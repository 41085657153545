<template>
  <div>
    <div v-if="!loadingSolicitacao">
      <accordion-mensagens
        v-model="accordionMensagens"
        :mensagens="mensagens"
      />
      <accordion-anexos
        v-model="accordionAnexos"
        :anexos="anexos"
        :solicitacao-id="solicitacaoId"
        @setLoadingAnexos="setLoadingAnexos"
      />
      <CtExpanded v-model="accordionContent" title="Conteúdo da solicitação">
        <template #content>
          <div>
            <div class="row">
              <div class="mt-5 mt-md-0 mt-ml-0 col-12 col-md-4">
                <ct-label text="Data do Pagamento" />
                <div class="lg:mt-1">
                  {{ formatDayPtBr(adiantamento.dtPagamento) }}
                </div>
              </div>
              <div class="mt-5 mt-md-0 mt-ml-0 col-12 col-md-4">
                <ct-label text="Valor Req." />
                <div class="lg:mt-1">
                  {{ formatMoney(adiantamento.valorRequerido) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </CtExpanded>
    </div>
    <SkeletonAccordionSolicitacao v-else />
  </div>
</template>
<script>
import CtLabel from "@/components/fields/input/CtLabel";
import { ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import CtExpanded from "@/components/CtExpanded/CtExpanded.vue";
import AccordionMensagens from "./AccordionMensagens.vue";
import AccordionAnexos from "./AccordionAnexos.vue";
import SkeletonAccordionSolicitacao from "../SkeletonAccordionSolicitacao.vue";
import { formatMoney } from "@/composables/useFormatMoney";
import { formatDayPtBr } from "@/composables/useFormatDate";
/*
import {
  getDatasFerias,
  getPeriodosAquisitivos,
} from "@/src/state/graphql/ferias/tempoRealFerias";
*/
import { getSolicitacao } from "@/state/graphql/solicitacoes";
export default {
  components: {
    CtLabel,
    AccordionMensagens,
    AccordionAnexos,
    CtExpanded,
    SkeletonAccordionSolicitacao,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    solicitacaoId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const adiantamento = ref({
      dtPagamento: "2024-01-07",
      valorRequerido: 800,
      valorIrrf: 0,
      liquido: 800,
    });

    const mensagens = ref([]);
    const anexos = ref([]);

    const showConcuirSolicitacao = ref(false);
    const tipoConclusao = ref("APROVAR");
    const status = ref({
      displayName: "Reprovada",
      value: "REPROVADA",
    });

    const accordionContent = ref(!!props.modelValue);
    const accordionMensagens = ref(!!props.modelValue);
    const accordionAnexos = ref(!!props.modelValue);

    const {
      loading: loadingSolicitacao,
      onResult: onResultSolicitacao,
      refetch: onRefetchSolicitacao,
    } = useQuery(
      getSolicitacao,
      () => ({
        solicitacaoId: props.solicitacaoId,
      }),
      { fetchPolicy: "network-only" }
    );

    onResultSolicitacao((result) => {
      if (!result.loading) {
        if (result.data.solicitacao?.objeto?.adiantamento) {
          const apiSolicitacaoAdiantamento =
            result.data.solicitacao.objeto.adiantamento;
          adiantamento.value.valorRequerido = apiSolicitacaoAdiantamento.valor;
          adiantamento.value.dtPagamento = result.data.solicitacao.dtCriacao;
          mensagens.value = result.data.solicitacao.mensagens;
          status.value = result.data.solicitacao.status;
          anexos.value = result.data.solicitacao.anexos.map((item) => ({
            name: item,
            isLoading: false,
          }));
        }
      }
    });

    const setLoadingAnexos = (indexAnexo, newValue) => {
      anexos.value[indexAnexo].isLoading = newValue;
    };

    return {
      formatDayPtBr,
      formatMoney,
      adiantamento,
      loadingSolicitacao,
      mensagens,
      showConcuirSolicitacao,
      tipoConclusao,
      status,
      onRefetchSolicitacao,
      anexos,
      accordionContent,
      setLoadingAnexos,
      accordionMensagens,
      accordionAnexos,
    };
  },
};
</script>
