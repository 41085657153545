<template>
  <!-- Begin page -->
  <div>
    <!-- <div v-if="$auth0.isLoading">Carregando</div> -->
    <div id="layout-wrapper" v-if="isAuthenticated && !isLoading">
      <header
        id="page-topbar"
        :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor};`"
      >
        <div class="navbar-header">
          <div class="d-flex">
            <!-- LOGO -->
            <div v-if="empregadorConfig">
              <div class="navbar-brand-box" v-if="empregadorConfig.urlLogo">
                <router-link :to="{ name: 'dashboard' }" class="logo">
                  <span class="logo-sm">
                    <img :src="empregadorConfig.urlLogo" alt="" />
                  </span>
                  <span class="logo-lg">
                    <img :src="empregadorConfig.urlLogo" alt="" />
                  </span>
                </router-link>
              </div>
              <div v-else>
                <b class="flex" :style="`color: ${getTextMenuColor};`">{{
                  nomeEmpregador
                }}</b>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <!--
          <div class="dropdown d-inline-block">
            <button
              type="button"
              class="btn header-item noti-icon position-relative"
              id="page-header-notifications-dropdown"
              :style="`color: ${getTextMenuColor};`"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-bell icon-lg"
              >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
              </svg>
              <span
                class="badge rounded-pill"
                :style="`color: ${getBGMenuColor}; background-color: ${getTextMenuColor};`"
                >5</span
              >
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-notifications-dropdown"
            >
              <div class="p-3">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="m-0">Notificações</h6>
                  </div>
                  <div class="col-auto">
                    <a
                      href="#!"
                      class="small text-reset text-decoration-underline"
                    >
                      Não Lidas (3)</a
                    >
                  </div>
                </div>
              </div>
              <div data-simplebar style="max-height: 230px">
                <a href="#!" class="text-reset notification-item">
                  <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                      <img
                        src="assets/images/users/avatar-3.jpg"
                        class="rounded-circle avatar-sm"
                        alt="user-pic"
                      />
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="mb-1">James Lemire</h6>
                      <div class="font-size-13 text-muted">
                        <p class="mb-1">
                          Demonstrativo do Mês de Setembro gerado.
                        </p>
                        <p class="mb-0">
                          <i class="mdi mdi-clock-outline"></i>
                          <span>1 hora atrás</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>

                <a href="#!" class="text-reset notification-item">
                  <div class="d-flex">
                    <div class="flex-shrink-0 avatar-sm me-3">
                      <span
                        class="avatar-title bg-success rounded-circle font-size-16"
                      >
                        <i class="bx bx-badge-check"></i>
                      </span>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="mb-1">Solicitação</h6>
                      <div class="font-size-13 text-muted">
                        <p class="mb-1">Solicitação aberta sobre tal tal</p>
                        <p class="mb-0">
                          <i class="mdi mdi-clock-outline"></i>
                          <span>3 min atrás</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="p-2 border-top d-grid">
                <a
                  class="btn btn-sm btn-link font-size-14 text-center"
                  href="javascript:void(0)"
                >
                  <i class="mdi mdi-arrow-right-circle me-1"></i>
                  <span>Ver Mais..</span>
                </a>
              </div>
            </div>
          </div>
          -->

            <!-- <div class="dropdown d-inline-block">
            <button
              type="button"
              class="btn header-item right-bar-toggle me-2"
              :style="`color: ${getTextMenuColor};`"
            >
              <i data-feather="settings" class="icon-lg"></i>
            </button>
          </div> -->
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="header-item"
                @click="openModalLogout"
                title="Sair da Conta"
                :style="`color: ${getTextMenuColor};`"
              >
                <i class="mdi mdi-24px mdi-account-arrow-right"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <router-link class="dropdown-item" to="/trabalhador">
                  <i
                    class="mdi mdi-face-profile mdi-24px align-middle me-1"
                  ></i>
                  Perfil
                </router-link>

                <div class="dropdown-divider"></div>
                <button class="dropdown-item" @click="logout">
                  <i class="mdi mdi-logout font-size-16 align-middle me-1"></i>
                  Sair
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!--
    <div class="topnav">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg topnav-menu">
          <div class="collapse navbar-collapse" id="topnav-menu-content">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                -->
      <!-- data-bs-toggle="collapse"
                data-bs-target="#topnav-menu-content" -->
      <!-- :style="`color: ${getTextMenuColor};`" -->
      <!--
                <router-link
                  class="nav-lk dropdown-toggle arrow-none"
                  :to="{ name: 'dashboard' }"
                  id="topnav-dashboard"
                  role="button"
                >
                  <i data-feather="home"></i
                  ><span data-key="t-dashboard">Início</span>
                </router-link>
              </li>

              <li class="nav-item dropdown">
                <router-link
                  class="nav-lk dropdown-toggle arrow-none"
                  to="/solicitacoes"
                  role="button"
                >
                  <i data-feather="file-plus"></i><span>Solicitações</span>
                </router-link>
              </li>

              <li class="nav-item dropdown">
                <router-link
                  class="nav-lk dropdown-toggle arrow-none"
                  to="/demonstrativos"
                  role="button"
                >
                  <i data-feather="file-text"></i><span>Demonstrativos</span>
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <PageTitle :title="getRouteTitle()" />
            <div class="row">
              <div class="col-lg-3">
                <trabalhador-card
                  :trabalhador="trabalhador"
                  :on-refetch-trabalhador="getCurrentTrabalhador"
                />
              </div>
              <div class="col-lg-9">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              {{ new Date().getFullYear() }} © Calculato.
            </div>
            <div class="col-sm-6">
              <div class="text-sm-end d-none d-sm-block">
                Todos os direitos reservados.
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div
        id="modalLogout"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
        data-bs-scroll="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Sair da Conta</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">Você deseja sair desta conta?</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary waves-effect"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor}; color: ${getTextMenuColor};`"
                class="btn btn-primary waves-effect waves-light"
                @click="logout"
              >
                Sair
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import {
  getBGMenuColor,
  getTextMenuColor,
  setBGMenuColor,
  setColorPrimaria,
  getColorPrimaria,
  setColorSecondary,
  getColorSecondary,
  setTextMenuColor,
  setNomeEmpregador,
  setUrlLogoEmpregador,
  removeLocalStorage,
} from "@/state/modules/themeColor.store";
import { removeCookie } from "@/utils/cookies";
import TrabalhadorCard from "./components/TrabalhadorCard";
import PageTitle from "@/components/Title/PageTitle.vue";
import {
  fetchCurrentTrabalhador,
  getters,
  mutations,
} from "@/state/modules/trabalhador.store";
import { computed, ref } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { getEmpregadorConfig } from "@/state/graphql/trabalhador";
import { hexToRgb } from "@/composables/useConvertTypeColor";
// eslint-disable-next-line no-unused-vars
import { getCookie, saveCookie } from "@/utils/cookies";

export default {
  components: {
    TrabalhadorCard,
    PageTitle,
  },
  setup() {
    const isLoading = ref(true);

    function initFeathericon() {
      feather.replace();
    }

    // function initComponents() {
    //   // popover
    //   var popoverTriggerList = [].slice.call(
    //     document.querySelectorAll('[data-bs-toggle="popover"]')
    //   );
    //   var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    //     return new bootstrap.Popover(popoverTriggerEl);
    //   });
    // }

    function init() {
      initFeathericon();
      // initComponents();
      Waves.init();
    }

    const logoUrl = computed(() => {
      // return require("@/assets/images/logo-white.svg");
      // return "https://calculato-test-folha-gateway-addonss-avatarbucket-1urs90yp3pxqn.s3.us-west-2.amazonaws.com/96c102cf-ccdd-477c-ad43-28bbe62e197e/empregador-logo/b9a14491-9c0b-4f54-86fc-3bb50bc55e0e/1652107889976.png";
      return "https://calculato.com.br/assets/img/logo/logo.svg";
    });

    const getCurrentTrabalhador = async () => {
      const resp = await fetchCurrentTrabalhador();
      if (resp) {
        await mutations.setCurrentTrabalhador(resp);
        await mutations.addValidatingTrabalhador();
      }
    };

    const trabalhador = computed(() => getters.currentTrabalhador.value);

    const {
      result: resultEmpregadorConfig,
      load: loadEmpregadorConfig,
      onResult: onResultEmpregadorConfig,
    } = useLazyQuery(
      getEmpregadorConfig,
      () => ({
        idEmpregador: trabalhador.value?.idEmpregador,
      }),
      { fetchPolicy: "network-only" }
    );

    onResultEmpregadorConfig((resp) => {
      if (resp.data.trabalhadorAppEmpregadorConfig) {
        const newColorBGMenu =
          resp.data.trabalhadorAppEmpregadorConfig.corMenuFundo;
        const newColorTextMenu =
          resp.data.trabalhadorAppEmpregadorConfig.corMenuTexto;
        const newColorPrimaria = hexToRgb(
          resp.data.trabalhadorAppEmpregadorConfig.corPrimaria
        );
        const newColorSecondary = hexToRgb(
          resp.data.trabalhadorAppEmpregadorConfig.corSecundaria
        );
        setBGMenuColor(newColorBGMenu);
        setUrlLogoEmpregador(resp.data.trabalhadorAppEmpregadorConfig.urlLogo);
        setNomeEmpregador(resp.data.empregadorNome);
        setTextMenuColor(newColorTextMenu);
        if (newColorPrimaria !== null) {
          setColorPrimaria(newColorPrimaria);
          // 0, 92, 255
          document.documentElement.style.setProperty(
            "--bs-primary-rgb",
            getColorPrimaria.value
          );
        }
        if (newColorSecondary !== null) {
          setColorSecondary(newColorSecondary);
          document.documentElement.style.setProperty(
            "--bs-auxiliary-rgb",
            getColorSecondary.value
          );
        }
      }
    });

    const empregadorConfig = computed(() => {
      if (resultEmpregadorConfig.value) {
        return resultEmpregadorConfig.value.trabalhadorAppEmpregadorConfig;
      }
      return null;
    });
    const nomeEmpregador = computed(() => {
      if (resultEmpregadorConfig.value) {
        return resultEmpregadorConfig.value.empregadorNome;
      }
      return null;
    });

    return {
      getBGMenuColor,
      loadEmpregadorConfig,
      logoUrl,
      // user: auth0.user,
      // isAuthenticated: auth0.isAuthenticated,
      getTextMenuColor,
      init,
      trabalhador,
      empregadorConfig,
      isLoading,
      nomeEmpregador,
      getCurrentTrabalhador,
    };
  },
  methods: {
    getRouteTitle() {
      if (this.$route.name === "dashboard") {
        return "Dashboard";
      } else if (this.$route.name === "solicitacoes") {
        return "Solicitações";
      } else if (this.$route.name === "nova-solicitacao") {
        return "Nova Solicitação";
      } else if (this.$route.name === "demonstrativos") {
        return "Demonstrativos";
      } else if (this.$route.name === "documentos") {
        return "Documentos";
      } else if (this.$route.name === "trabalhador") {
        return "Informações do Trabalhador";
      }
    },
    openModalLogout() {
      $("#modalLogout").modal("show");
    },
    logout() {
      removeCookie("authToken");
      removeLocalStorage();
      return this.$auth0.logout({ returnTo: window.location.origin });
    },
  },
  computed: {
    isAuthenticated() {
      return this.$auth0.isAuthenticated;
    },
  },
  async mounted() {
    saveCookie(
      "authToken",
      await this.$auth0.getAccessTokenSilently({
        cacheMode: "on",
      })
    );
    if (getCookie("authToken")) {
      setTimeout(async () => {
        this.init();
        await this.getCurrentTrabalhador();
        await this.loadEmpregadorConfig();
        this.isLoading = false;
      }, 150);
    } else {
      setTimeout(async () => {
        init();
        await this.getCurrentTrabalhador();
        await this.loadEmpregadorConfig();
        this.isLoading = false;
      }, 800);
    }
  },
};
</script>

<style lang="scss" scoped></style>
