<template>
  <div>
    <div class="row">
      <div class="col-12">
        <!-- Criar Nova Solicitação -->
        <div class="mb-6 col-12">
          <label class="form-label">Mensagem*</label>
          <textarea
            :class="
              v$.form.mensagem.$error
                ? 'form-control is-invalid'
                : 'form-control'
            "
            placeholder="Mensagem"
            v-model="form.mensagem"
            @blur="v$.form.mensagem.$touch"
          >
          </textarea>
          <div v-show="v$.form.mensagem.$error" class="error-text">
            O Campo <b>Mensagem</b> é de preenchimento obrigatório.
          </div>
        </div>

        <!-- <div v-if="form.tipo === 'OUTRO'">Outros</div> -->
        <FormAnexos @receiveFile="setAnexos" />

        <div class="mt-7 w-100 d-flex justify-content-end">
          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light"
            @click="saveSolicitacao"
          >
            <i
              class="bx bx-loader bx-spin font-size-16 align-middle me-2"
              v-if="saving"
            ></i>
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
// import { formatDayPtBr } from "@/composables/useFormatDate";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useMutation } from "@vue/apollo-composable";
import {
  getSolicitacaoAnexoUrl,
  //mutationEditSolicitacao,
  mutationRespostaSolicitacao,
} from "@/state/graphql/solicitacoes";
import Toast from "@/components/Toast/Toast.vue";
import { useToast, TYPE } from "vue-toastification";
import { errorMsg } from "@/state/modules/error.store";
import FormAnexos from "./forms/FormAnexos.vue";
import { apolloClient } from "@/state/client";
import { formatDay } from "@/composables/useFormatDate";

export default {
  name: "ContentModalRespondeSolicitacao",
  components: {
    FormAnexos,
  },
  props: {
    idSolicitacao: {
      type: String,
      required: true,
    },
    infoPessoal: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const saving = ref(false);
    const toast = useToast();
    const form = reactive({
      tipo: null,
      mensagem: null,
      anexos: [],
      origem: "TRABALHADOR",
    });

    const anexos = ref([]);

    const setAnexos = (files) => {
      anexos.value = [...anexos.value, ...files];
    };

    let tiposSolicitacoes = [
      // {
      //   displayName: "Informações pessoais",
      //   value: "INFO_PESSOAL",
      // },
      // {
      //   displayName: "Alteração de informações pessoais",
      //   value: "ALTERACAO_INFO_PESSOAL",
      // },
      {
        displayName: "Férias",
        value: "FERIAS",
      },
      {
        displayName: "Adiantamento",
        value: "ADIANTAMENTO",
      },
      {
        displayName: "Falta",
        value: "FALTA",
      },
      {
        displayName: "Outros",
        value: "OUTRO",
      },
    ];

    const errorToast = (title, text) => {
      toast(
        {
          component: Toast,
          props: {
            title: title,
            text: text,
          },
        },
        { type: TYPE.ERROR }
      );
    };
    const successToast = (title, msg) => {
      toast(
        {
          component: Toast,
          props: {
            title: title ? title : "Solicitação criada com sucesso!",
            text: msg
              ? msg
              : "O Solicitação foi enviada para o seu Gestor, acompanhe o status na listagem de solicitações.",
          },
        },
        { type: TYPE.SUCCESS }
      );
    };

    async function putToS3(fileObject, presignedUrl) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": fileObject.type,
        },
        body: fileObject,
      };
      const response = await fetch(presignedUrl, requestOptions);
      return await response;
    }
    const currentUrlUpload = ref(null);
    const isUploading = ref(false);

    /*const { mutate: mutateEditSolitacao } = useMutation(
      mutationEditSolicitacao,
      { context: { clientName: "endpoint1" } }
    );*/

    const uploadAnexo = async (file) => {
      isUploading.value = true;
      try {
        const resp = await apolloClient.query({
          query: getSolicitacaoAnexoUrl,
          variables: {
            solicitacaoAnexoUrlId: props.idSolicitacao,
            nomeArquivo: file.name,
            tipo: "UPLOAD", // UPLOAD | DOWNLOAD
          },
          context: { clientName: "endpoint1" },
          fetchPolicy: "network-only",
        });
        currentUrlUpload.value = resp.data.solicitacaoAnexoUrl;
        const urlApi = `${currentUrlUpload.value}`;
        await putToS3(file, urlApi);
        /* await mutateEditSolitacao({
          editSolicitacaoId: props.idSolicitacao,
          nomeArquivo: file.name,
        }); */
      } catch {
        errorToast("Não foi possível subir o arquivo", errorMsg.value);
      } finally {
        isUploading.value = false;
      }
    };

    const { mutate: respostaSolicitacao } = useMutation(
      mutationRespostaSolicitacao,
      () => ({
        update: (_, resp) => {
          if (resp.data) {
            // loadSolicitacaoAnexoUrl()
            if (anexos.value.length) {
              anexos.value.forEach(async (anexo) => {
                await uploadAnexo(anexo.file);
              });
            }
            /*
            if (props.tipoConclusao === "APROVAR") {
              emit("onApprove");
            } else {
              emit("onReprove");
              root.$ctoast.success({
                useHtml: true,
                title: "Solicitação reprovada com sucesso!",
                message:
                  "A Solicitação foi reprovada e o trabalhador será notificado.",
                timeOut: 10000,
              });
            }
            */
          }
        },
      })
    );

    return {
      form,
      saving,
      toast,
      respostaSolicitacao,
      v$: useVuelidate(),
      successToast,
      setAnexos,
      errorToast,
      errorMsg,
      uploadAnexo,
      anexos,
      tiposSolicitacoes,
    };
  },
  validations() {
    return {
      form: {
        mensagem: {
          required,
        },
      },
    };
  },
  methods: {
    clearForm() {
      Object.assign(this.form, {
        tipo: null,
        mensagem: null,
        anexos: [],
        origem: "TRABALHADOR",
      });
    },
    async saveSolicitacao() {
      this.saving = true;
      try {
        let validateForm = await this.v$.form.$validate();

        if (validateForm) {
          let anexos = [];
          if (this.anexos.length) {
            anexos = this.anexos.map((anexo) => anexo.name);
          }
          let newRespostaSolicitacao = null;
          if (this.infoPessoal) {
            newRespostaSolicitacao = {
              mensagemResposta: this.form.mensagem,
              anexos: anexos,
              objeto: {
                infoPessoal: {
                  ...this.infoPessoal,
                  cnhUf: this.infoPessoal.cnhUf
                    ? this.infoPessoal.cnhUf.value
                    : null,
                  ctpsUf: this.infoPessoal.ctpsUf
                    ? this.infoPessoal.ctpsUf.value
                    : null,
                  endPais: this.infoPessoal.endPais.value,
                  estadoCivil: this.infoPessoal.estadoCivil.value,
                  grauInstrucao: this.infoPessoal.grauInstrucao.value,
                  indImigrante: this.infoPessoal.indImigrante
                    ? this.infoPessoal.indImigrante.value
                    : null,
                  nacionalidade: this.infoPessoal.nacionalidade.value,
                  paisNascimento: this.infoPessoal.paisNascimento.value,
                  cnhVencimento: this.infoPessoal.cnhVencimento
                    ? formatDay(this.infoPessoal.cnhVencimento)
                    : null,
                  codImigrante: this.infoPessoal.codImigrante
                    ? this.infoPessoal.codImigrante.value
                    : null,
                  raca: this.infoPessoal.raca.value,
                  sexo: this.infoPessoal.sexo.value,
                },
              },
              status: "AGUARDANDO_VALIDACAO",
            };
          } else {
            newRespostaSolicitacao = {
              mensagemResposta: this.form.mensagem,
              anexos: anexos,
              status: "AGUARDANDO_VALIDACAO",
              objeto: {},
            };
          }
          await this.respostaSolicitacao({
            respondeSolicitacaoId: this.idSolicitacao,
            newRespostaSolicitacao,
          });
          this.successToast(
            "Dados enviados com sucesso!",
            "As informações pessoais foram enviadas para o gestor e ele será notificado"
          );
          this.clearForm();
          this.$emit(
            "onRefetchSolicitacao",
            this.anexos.map((anexo) => ({ name: anexo.name, isLoading: false }))
          );
        } else {
          this.errorToast(
            "Não foi possível salvar!",
            "Preencha corretamente todos os campos!"
          );
        }
      } catch (error) {
        this.errorToast(
          "Não foi possível salvar!",
          this.errorMsg
            ? this.errorMsg
            : "Ocorreu algo inesperado, tente novamente mais tarde!"
        );
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="postcss"></style>
