import { reactive, computed } from "vue";

export const state = reactive({
  errorMessage:
    "Não conseguimos terminar a operação, por favor tente mais tarde.",
  developerMessage: null,
});

export function setError(error) {
  state.errorMessage = error;
}

export function setDeveloperMessage(developerError) {
  state.developerMessage = developerError;
}

// export const useRouterCustom = () => {
//   const router = useRouter();

//   const goToTrabalhadorNotFound = () => {
//     router.push("/trabalhador-nao-registrado");
//   };
//   return {
//     goToTrabalhadorNotFound,
//   };
// };

export const errorMsg = computed(() => state.errorMessage);
export const errorDevMsg = computed(() => state.developerMessage);

/**  --- How To Use ---
import { errorMsg } from '@state/modules/error.store'
setup() { return { errorMsg } }
*/
