import App from "./App.vue";
import { createApp, h } from "vue";
import { createRouter } from "./router";
import { createAuth0 } from "@auth0/auth0-vue";
import { apolloClient } from "./state/client";
import { DefaultApolloClient } from "@vue/apollo-composable";
import Toast, { POSITION } from "vue-toastification";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

// import {
//   getColorPrimaria,
//   getColorSecondary,
// } from "./state/modules/themeColor.store";
// import { saveCookie } from "@/utils/cookies";

const app = createApp({
  mounted() {
    // document.documentElement.style.setProperty(
    //   "--bs-primary-rgb",
    //   getColorPrimaria.value
    // );
    // document.documentElement.style.setProperty(
    //   "--bs-auxiliary-rgb",
    //   getColorSecondary.value
    // );
  },

  render: () => h(App),
});
app.use(createRouter(app));

app.use(
  createAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
      scope: "profile email scope1",
      redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
    },
  })
);
app.provide(DefaultApolloClient, apolloClient);
app.use(Toast, {
  hideProgressBar: true,
  position: POSITION.BOTTOM_RIGHT,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  timeout: 2500,
  closeOnClick: false,
  draggable: false,
});
app.use(FloatingVue);
// app.directive("tooltip", vTooltip);
app.mount("#app");
