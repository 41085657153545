<template>
  <div>
    <div v-if="isLoading"></div>

    <div class="notFoundWorker-page" v-else>
      <header
        id="page-topbar"
        :style="`background-color: #005cff; border-color: #005cff;`"
      >
        <div class="navbar-header">
          <div class="d-flex"></div>
          <div class="d-flex">
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="header-item"
                title="Sair da Conta"
                :style="`color: ${getTextMenuColor};`"
                @click="openModalLogout"
              >
                <i class="mdi mdi-24px mdi-account-arrow-right"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <router-link class="dropdown-item" to="/trabalhador">
                  <i
                    class="mdi mdi-face-profile mdi-24px align-middle me-1"
                  ></i>
                  Perfil
                </router-link>

                <div class="dropdown-divider"></div>

                <button class="dropdown-item" @click="onLogout">
                  <i class="mdi mdi-logout font-size-16 align-middle me-1"></i>
                  Sair
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="container">
        <div class="row">
          <div class="header">
            <p>
              Não possui registro na Calculato, por favor entre em contato com o
              seu gestor.
            </p>
            <button
              :style="`background-color: #005cff; border-color: #005cff; color: #ffffff;`"
              class="btn btn-primary waves-effect waves-light mt-2"
              @click="onLogout"
            >
              Ir para Tela de Login
            </button>
          </div>
        </div>
      </div>

      <div
        id="modalLogout"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
        data-bs-scroll="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Sair da Conta</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">Você deseja sair desta conta?</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary waves-effect"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor}; color: ${getTextMenuColor};`"
                class="btn btn-primary waves-effect waves-light"
                @click="onLogout"
              >
                Sair
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import {
  getBGMenuColor,
  getTextMenuColor,
  removeLocalStorage,
} from "@/state/modules/themeColor.store";
import { removeCookie } from "@/utils/cookies";
// import { useAuth0 } from "@auth0/auth0-vue";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  setup() {
    const route = useRoute();

    return { query: route.query, getBGMenuColor, getTextMenuColor };
  },
  methods: {
    openModalLogout() {
      $("#modalLogout").modal("show");
    },
    onLogout() {
      removeLocalStorage();
      removeCookie("authToken");
      this.$auth0.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 950);
  },
};
</script>

<style lang="scss">
.notFoundWorker-page .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-bottom: 50px;
  font-size: 1.2rem;
  padding-top: 7rem;
}
</style>
