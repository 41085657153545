<template>
  <div class="accordion">
    <div class="mt-4">
      <div class="accordion-header" id="headingOne">
        <div
          class="expanded-button pointer fw-medium d-flex align-items-center"
          @click="toggleCollapse"
        >
          <i
            class="fas fa-chevron-right rotate-effect"
            :class="{ rotate: localVisible }"
          ></i>
          <span class="ms-2">{{ title }}</span>
        </div>
      </div>
      <div ref="refCollapse" class="accordion-collapse collapse">
        <div class="accordion-body">
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Collapse from "bootstrap/js/dist/collapse";

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localVisible: this.modelValue,
    };
  },
  setup(props) {
    const refCollapse = ref(null);
    const instance = ref();

    onMounted(() => {
      instance.value = new Collapse(refCollapse.value, {
        toggle: false,
      });
      if (props.modelValue) {
        instance.value?.show();
      } else {
        instance.value?.hide();
      }
    });

    return {
      refCollapse,
      instance,
    };
  },
  watch: {
    modelValue(nextValue) {
      this.localVisible = nextValue;
      this.$emit("update:modelValue", nextValue);
      this.$emit("change", nextValue);
    },
  },
  methods: {
    toggleCollapse() {
      if (this.localVisible) {
        this.instance?.hide();
        this.localVisible = false;
        this.$emit("update:modelValue", this.localVisible);
        this.$emit("change", this.localVisible);
      } else {
        this.localVisible = true;
        this.instance?.show();
        this.$emit("update:modelValue", this.localVisible);
        this.$emit("change", this.localVisible);
      }
    },
  },
};
</script>

<style lang="scss">
.expanded-button::after {
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-position: center;
}

.rotate {
  transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
}
.rotate-effect {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.pointer {
  cursor: pointer;
}
</style>
