<template>
  <div>
    <div v-if="!loadingSolicitacao">
      <accordion-mensagens
        v-model="accordionMensagens"
        :mensagens="mensagens"
      />
      <accordion-anexos
        v-model="accordionAnexos"
        :anexos="anexos"
        :solicitacao-id="solicitacaoId"
        @setLoadingAnexos="setLoadingAnexos"
      />
      <div
        v-if="status.value === 'PENDENTE'"
        class="col-12 d-flex justify-content-end"
      >
        <button
          type="button"
          class="btn btn-outline-primary waves-effect waves-light"
          data-bs-toggle="modal"
          data-bs-target="#modalRespondeSolicitcao"
        >
          Responder Solicitação
        </button>
      </div>
    </div>
    <SkeletonAccordionSolicitacao v-else />
    <div class="grid-col-6">
      <div
        id="modalRespondeSolicitcao"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        data-bs-scroll="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myModalLabel">
                Responder Solicitação
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ContentModalRespondeSolicitacao
                :id-solicitacao="solicitacaoId"
                @onRefetchSolicitacao="onRefetchSolicitacao"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import AccordionMensagens from "./AccordionMensagens.vue";
import AccordionAnexos from "./AccordionAnexos.vue";
import SkeletonAccordionSolicitacao from "../SkeletonAccordionSolicitacao.vue";
import { formatDayPtBr } from "@/composables/useFormatDate";
/*
import {
  getDatasFerias,
  getPeriodosAquisitivos,
} from "@/src/state/graphql/ferias/tempoRealFerias";
*/
import { getSolicitacao } from "@/state/graphql/solicitacoes";
import ContentModalRespondeSolicitacao from "../ContentModalRespondeSolicitacao.vue";
export default {
  components: {
    AccordionMensagens,
    AccordionAnexos,
    SkeletonAccordionSolicitacao,
    ContentModalRespondeSolicitacao,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    solicitacaoId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const mensagens = ref([]);
    const anexos = ref([]);

    const showConcuirSolicitacao = ref(false);
    const tipoConclusao = ref("APROVAR");
    const status = ref({
      displayName: "Reprovada",
      value: "REPROVADA",
    });

    const accordionContent = ref(!!props.modelValue);
    const accordionMensagens = ref(!!props.modelValue);
    const accordionAnexos = ref(!!props.modelValue);
    const requestApproved = ref(false);

    const {
      loading: loadingSolicitacao,
      onResult: onResultSolicitacao,
      refetch: refetchSolicitacao,
    } = useQuery(
      getSolicitacao,
      () => ({
        solicitacaoId: props.solicitacaoId,
      }),
      { fetchPolicy: "network-only" }
    );

    onResultSolicitacao((result) => {
      if (!result.loading) {
        if (result.data.solicitacao) {
          mensagens.value = result.data.solicitacao.mensagens;
          status.value = result.data.solicitacao.status;
          emit("setStatusSolicitacao", props.solicitacaoId, status.value);
          if (!requestApproved.value) {
            anexos.value = result.data.solicitacao.anexos.map((item) => ({
              name: item,
              isLoading: false,
            }));
          }
        }
      }
    });

    const openModalConluirSolicitacao = (newTipo) => {
      showConcuirSolicitacao.value = true;
      tipoConclusao.value = newTipo;
    };

    const setLoadingAnexos = (indexAnexo, newValue) => {
      anexos.value[indexAnexo].isLoading = newValue;
    };

    return {
      formatDayPtBr,
      loadingSolicitacao,
      mensagens,
      showConcuirSolicitacao,
      openModalConluirSolicitacao,
      tipoConclusao,
      status,
      refetchSolicitacao,
      anexos,
      requestApproved,
      accordionContent,
      accordionMensagens,
      accordionAnexos,
      setLoadingAnexos,
    };
  },
  methods: {
    onRefetchSolicitacao(newAnexos) {
      this.requestApproved = true;
      this.refetchSolicitacao();
      this.anexos = [...this.anexos, ...newAnexos];
      $("#modalRespondeSolicitcao").modal("hide");
      setTimeout(() => {
        this.requestApproved = false;
      }, 300);
    },
  },
};
</script>
