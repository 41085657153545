import { gql } from "@apollo/client/core";

export const getDemonstrativosDisplay = gql`
  query getDemonstrativosDisplay($cursor: String) {
    folhaTrabalhadorDisplayPage(cursor: $cursor) {
      data {
        idFolha
        idTrabalhador
        dtPagamento
        periodo
        funcaoDescricao
        liquido
        salario
        undSalario {
          displayName
          value
        }
        categoria {
          displayName
          value
        }
      }
      cursor
      total
    }
  }
`;

export const getDemonstrativoFolha = gql`
  query Folha($folhaId: ID!) {
    folha(id: $folhaId) {
      id
      idTrabalhador
      tipoCategoria {
        displayName
        value
      }
      periodo
      periodoComplementar
      dtAcordoComplementar
      tipoAcordoComplementar {
        displayName
        value
      }
      iniReferencia
      fimReferencia
      bruto
      descontos
      liquido
      baseInss
      baseInssExcd
      percInss
      inss
      baseFgts
      percFgts
      fgts
      baseIrrf
      deduIrrf
      percIrrf
      irrf
      dtPagamento
      tipoFolha {
        displayName
        value
      }
      indVisualizado
      eventosFolha {
        id
        idFolha
        rubricaCodigo
        rubricaDescricao
        indBaseInss
        indBaseFgts
        indBaseIrrf
        baseDsr
        fatorMultiplicador
        natureza {
          displayName
          value
        }
        referencia
        tipoReferencia {
          displayName
          value
        }
        descReferencia
        origem {
          displayName
          value
        }
        valor
        indMediaFerias
        indMedia13
        indMediaAviso
      }
    }
  }
`;

export const getDemosAdiantamentosDisplay = gql`
  query AdiantamentoTrabalhadorDisplayPage($cursor: String) {
    adiantamentoTrabalhadorDisplayPage(cursor: $cursor) {
      cursor
      data {
        dtPagamento
        idAdiantamento
        liquido
        valorIrrf
        valorRequerido
      }
      total
    }
  }
`;

export const getDemosFeriasDisplay = gql`
  query FeriasTrabalhadorDisplayPage($cursor: String) {
    feriasTrabalhadorDisplayPage(cursor: $cursor) {
      cursor
      data {
        dtFim
        dtInicio
        funcaoDescricao
        idFeriasGozo
        liquido
        qntDias
        salario
        undSalario {
          displayName
          value
        }
      }
      total
    }
  }
`;

export const getDemonstrativoFerias = gql`
  query FeriasGozo($idFerias: ID!) {
    feriasGozo(idFerias: $idFerias) {
      id
      idTrabalhador
      undSalario {
        displayName
        value
      }
      salario
      descricaoFuncao
      idPeriodoAq
      qntDias
      diasAbono
      dtPagamento
      dtInicio
      dtFim
      dtRetorno
      bruto
      descontos
      liquido
      baseInss
      baseInssExcd
      percInss
      inss
      baseIrrf
      deduIrrf
      percIrrf
      irrf
      eventos {
        id
        idFeriasGozo
        rubricaCodigo
        rubricaDescricao
        indBaseInss
        indBaseFgts
        indBaseIrrf
        fatorMultiplicador
        natureza {
          displayName
          value
        }
        referencia
        tipoReferencia {
          displayName
          value
        }
        descReferencia
        origem {
          displayName
          value
        }
        valor
      }
    }
  }
`;

export const getDemosDecimoTerceiroDisplay = gql`
  query DecimoTerceiroTrabalhadorDisplayPage($cursor: String) {
    decimoTerceiroTrabalhadorDisplayPage(cursor: $cursor) {
      cursor
      data {
        ano
        idPrimeiraParcela
        idSegundaParcela
        idTrabalhador
        liquidoPrimeiraParcela
        liquidoSegundaParcela
      }
      total
    }
  }
`;

export const getDemonstrativoDecimoTerceiro = gql`
  query DecimoTerceiro($decimoTerceiroId: ID!) {
    decimoTerceiro(id: $decimoTerceiroId) {
      ano
      baseFgts
      baseInss
      baseInssExcd
      baseIrrf
      bruto
      deduIrrf
      descontos
      dtPagamento
      eventosDecimoTerceiro {
        descReferencia
        fatorMultiplicador
        id
        idDecimoTerceiro
        indBaseFgts
        indBaseInss
        indBaseIrrf
        natureza {
          displayName
          value
        }
        origem {
          displayName
          value
        }
        referencia
        rubricaCodigo
        rubricaDescricao
        tipoReferencia {
          displayName
          value
        }
        valor
      }
      fgts
      id
      idTrabalhador
      indDisponibilizado
      indVisualizado
      inss
      irrf
      liquido
      parcela
      percFgts
      percInss
      percIrrf
    }
  }
`;

export const getDemosRescisaoDisplay = gql`
  query ResumoRescisaoDisplay {
    resumoRescisaoDisplay {
      infoRescisaoOriginal {
        dtAviso
        dtPagamento
        dtProjecaoAviso
        dtRescisao
        fgts13
        fgtsAviso
        id
        idFolha
        idTrabalhador
        indDisponibilizado
        inscricaoSucessor
        inss13Aliquota
        inss13Base
        inss13BaseExcd
        inss13Valor
        irrf13Aliquota
        irrf13Base
        irrf13Deducao
        irrf13Valor
        liquido
        motivo {
          displayName
          value
        }
        multaFgts
        nrProcessoTrabalhista
        percMultaFgts
        percPensaoAlimenticia
        percPensaoAlimenticiaSaqueFgts
        qntDiasAvisoIndenizado
        qntDiasAvisoTrabalhado
        saldoFgts
        saqueFgts
        tipoAviso {
          displayName
          value
        }
      }
      resumoInfoRescisaoComplementar {
        dtPagamento
        idInfoRescisao
      }
    }
  }
`;

export const getDemonstrativoRescisao = gql`
  query RescisaoCompleta($idRescisao: ID!) {
    rescisaoCompleta(idRescisao: $idRescisao) {
      folha {
        id
        idTrabalhador
        tipoCategoria {
          displayName
          value
        }
        periodo
        periodoComplementar
        dtAcordoComplementar
        tipoAcordoComplementar {
          displayName
          value
        }
        iniReferencia
        fimReferencia
        bruto
        descontos
        liquido
        baseInss
        baseInssExcd
        percInss
        inss
        baseFgts
        percFgts
        fgts
        baseIrrf
        deduIrrf
        percIrrf
        irrf
        dtPagamento
        tipoFolha {
          displayName
          value
        }
        indVisualizado
        eventosFolha {
          id
          idFolha
          rubricaCodigo
          rubricaDescricao
          indBaseInss
          indBaseFgts
          indBaseIrrf
          baseDsr
          fatorMultiplicador
          natureza {
            displayName
            value
          }
          referencia
          tipoReferencia {
            displayName
            value
          }
          descReferencia
          origem {
            displayName
            value
          }
          valor
          indMediaFerias
          indMedia13
          indMediaAviso
        }
      }
      infoRescisao {
        id
        idTrabalhador
        idFolha
        motivo {
          displayName
          value
        }
        inscricaoSucessor
        dtRescisao
        dtPagamento
        liquido
        dtAviso
        tipoAviso {
          displayName
          value
        }
        dtProjecaoAviso
        qntDiasAvisoTrabalhado
        qntDiasAvisoIndenizado
        nrProcessoTrabalhista
        percPensaoAlimenticia
        percPensaoAlimenticiaSaqueFgts
        saldoFgts
        multaFgts
        percMultaFgts
        saqueFgts
        inss13Base
        inss13BaseExcd
        inss13Aliquota
        inss13Valor
        irrf13Base
        irrf13Deducao
        irrf13Aliquota
        irrf13Valor
        fgts13
        fgtsAviso
        indDisponibilizado
      }
    }
  }
`;

export const getStatusProcessamento = gql`
  query getStatusProcessamento($id: ID!) {
    statusProcessamento(id: $id) {
      tipo
      status
      empregador
      referencia
      userMessage
      developerMessage
    }
  }
`;

export const mutationCreateReciboFolha = gql`
  mutation CreateReciboFolha($idEmpregador: ID!, $periodo: Int!) {
    createReciboFolha(
      idEmpregador: $idEmpregador
      reciboFolhaRequest: { periodo: $periodo }
    )
  }
`;

export const mutationCreateReciboAdiantamento = gql`
  mutation CreateReciboAdiantamento($idEmpregador: ID!, $periodo: String!) {
    createReciboAdiantamento(
      idEmpregador: $idEmpregador
      reciboAdiantamentoRequest: { dtPagamento: $periodo }
    )
  }
`;

export const mutationCreateReciboFerias = gql`
  mutation CreateReciboFerias($idEmpregador: ID!, $periodo: String!) {
    createReciboFerias(
      idEmpregador: $idEmpregador
      reciboFeriasRequest: { dtInicio: $periodo }
    )
  }
`;

export const mutationCreateReciboDecimoTerceiro = gql`
  mutation CreateReciboDecimoTerceiro(
    $idEmpregador: ID!
    $ano: Int!
    $parcela: String!
  ) {
    createReciboDecimoTerceiro(
      idEmpregador: $idEmpregador
      reciboDecimoTerceiroRequest: { ano: $ano, parcela: $parcela }
    )
  }
`;

export const mutationCreateReciboRescisao = gql`
  mutation CreateTermoRescisao($idEmpregador: ID!, $periodo: String!) {
    createTermoRescisao(
      idEmpregador: $idEmpregador
      termoRescisaoRequest: { dtRescisao: $periodo }
    )
  }
`;

export const getInfoCalculo = gql`
  query Query($idEvento: ID!) {
    infoCalculo(idEvento: $idEvento)
  }
`;
