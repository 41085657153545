import { gql } from "@apollo/client/core";
// import { debounce } from "throttle-debounce";
// import { ref } from "vue";
// import { apolloClient } from "@/state/client.js";

export const getOptionsPaises = gql`
  query getPaises($search: String!) {
    paises(search: $search) {
      codigo
      nome
    }
  }
`;

export const getDataOptions = gql`
  query getDataOptions {
    dataOptions {
      indicativoImigrante {
        displayName
        value
      }
      condicaoImigrante {
        value
        displayName
      }
      estadoCivil {
        value
        displayName
      }
      grauInstrucao {
        value
        displayName
      }
      raca {
        value
        displayName
      }
      sexo {
        value
        displayName
      }
      tipoDependente {
        value
        displayName
      }
      unidadeFederativa {
        value
        displayName
      }
    }
  }
`;

export const getStatusInfoSocial = gql`
  query getStatusInfoPessoal {
    infoPessoal {
      status
    }
  }
`;

export const getInfoPessoal = gql`
  query getInfoPessoal {
    infoPessoal {
      # idTrabalhador
      # status
      sexo {
        displayName
        value
      }
      cnh
      cnhCategoria
      cnhVencimento
      codImigrante {
        displayName
        value
      }
      defAuditiva
      defCota
      defFisica
      endNumero
      endMunicipio
      endMunicipioNome
      endLogradouro
      endComplemento
      endCep
      endBairro
      dtAlteracao
      defVisual
      defReabilitado
      defMental
      defIntelectual
      dtCadastro
      endPais {
        codigo
        nome
      }
      endUf {
        displayName
        value
      }
      estadoCivil {
        displayName
        value
      }
      grauInstrucao {
        displayName
        value
      }
      raca {
        displayName
        value
      }
      paisNascimento {
        codigo
        nome
      }
      nomeSocial
      nacionalidade {
        codigo
        nome
      }
      indImigrante {
        value
        displayName
      }
      dependentes {
        id
        cpf
        depIncapaz
        depPenAli {
          displayName
          value
        }
        penAliValor
        penAliPercentual
        dtAlteracao
        dtCadastro
        dtFimIrrf
        dtFimSalFamilia
        dtNascimento
        nome
        sexo {
          displayName
          value
        }
        tipoDependente {
          displayName
          value
        }
      }
      nomeMae
      cnhUf {
        displayName
        value
      }
      nis
      numeroReservista
      tituloEleitor
      ctpsNumero
      ctpsSerie
      ctpsUf {
        displayName
        value
      }
    }
  }
`;

export const mutationAddInfoPessoal = gql`
  mutation mutationAddInfoPessoal($newInfoPessoal: NewInfoPessoal!) {
    addInfoPessoal(newInfoPessoal: $newInfoPessoal)
  }
`;

export const createInfoPessoal = gql`
  mutation mutationCreateInfoPessoal(
    $idTrabalhador: ID!
    $newInfoPessoal: NewInfoPessoal!
  ) {
    createInfoPessoal(
      idTrabalhador: $idTrabalhador
      newInfoPessoal: $newInfoPessoal
    ) {
      idTrabalhador
    }
  }
`;

export const getUploadDocumentoUrl = gql`
  query uploadDocumentoUrl($nomeArquivo: String!) {
    uploadDocumentoUrl(nomeArquivo: $nomeArquivo)
  }
`;

export const getTrabalhadorDocumentoUrl = gql`
  query getTrabalhadorDocumentoUrl($trabalhadorDocumentoUrlId: ID!) {
    trabalhadorDocumentoUrl(id: $trabalhadorDocumentoUrlId)
  }
`;

export const mutationDeleteTrabalhadorDocumento = gql`
  mutation deleteTrabalhadorDocumento($deleteTrabalhadorDocumentoId: ID!) {
    deleteTrabalhadorDocumento(id: $deleteTrabalhadorDocumentoId)
  }
`;

export const getDocumentos = gql`
  query getDocumentos {
    documentos {
      nome
      id
      grupoDocumento {
        displayName
        value
      }
      dtAlteracao
      idTrabalhador
      tamanho
      tipoArquivo
      visualizacoes
    }
  }
`;

export const mutationCreateTrabalhadorDocumento = gql`
  mutation createTrabalhadorDocumento(
    $newTrabalhadorDocumento: NewTrabalhadorDocumento!
  ) {
    createTrabalhadorDocumento(
      newTrabalhadorDocumento: $newTrabalhadorDocumento
    )
  }
`;

export const getEmpregadorConfig = gql`
  query TrabalhadorAppEmpregadorConfig($idEmpregador: ID!) {
    trabalhadorAppEmpregadorConfig(idEmpregador: $idEmpregador) {
      id
      idEmpregador
      corMenuFundo
      corMenuTexto
      corPrimaria
      corSecundaria
      urlLogo
    }
    empregadorNome(idEmpregador: $idEmpregador)
  }
`;

export const getUrlFotoUpload = gql`
  query getUrlFotoUpload($imageType: String!) {
    trabalhadorFotoUploadUrl(imageType: $imageType)
  }
`;

export const mutationDeleteTrabalhadorFoto = gql`
  mutation mutationDeleteTrabalhadorFoto {
    deleteTrabalhadorFoto
  }
`;
