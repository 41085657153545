<template>
  <div v-if="demonstrativosDisplay" class="flex flex-column w-100">
    <div class="border-bottom border-2">
      <p class="fs-5 text-dark pb-1">Resumo da Rescisão</p>
      <div class="d-flex flex-row w-100 mb-3 flex-wrap">
        <div class="d-flex flex-column col-5 me-4 mb-5">
          <span href="#" class="fs-6 text-dark mb-1"> Causa </span>
          <span href="#" class="fw-normal">
            {{ demonstrativosDisplay.infoRescisao.motivo.displayName }}
          </span>
        </div>
        <div class="d-flex flex-column col-2 me-3 mb-5">
          <span href="#" class="fs-6 text-dark mb-1"> Num.Processo </span>
          <span href="#" class="fw-normal">
            {{
              demonstrativosDisplay.infoRescisao.nrProcessoTrabalhista !== null
                ? demonstrativosDisplay.infoRescisao.nrProcessoTrabalhista
                : "Sem Informação"
            }}
          </span>
        </div>
        <div class="d-flex flex-column col-2 me-3 mb-5">
          <span href="#" class="fs-6 text-dark mb-1">
            Qnt. Dias Aviso Idenizado
          </span>
          <span href="#" class="fw-normal">
            {{ demonstrativosDisplay.infoRescisao.qntDiasAvisoIndenizado }}
          </span>
        </div>
        <div class="d-flex flex-column col-2 me-5 mb-5">
          <span href="#" class="fs-6 text-dark mb-1"> Data da Rescisão </span>
          <span href="#" class="fw-normal">
            {{ formatDayPtBr(demonstrativosDisplay.infoRescisao.dtRescisao) }}
          </span>
        </div>
        <div class="d-flex flex-column col-2 mb-5">
          <span href="#" class="fs-6 text-dark mb-1"> Data Aviso </span>
          <span href="#" class="fw-normal">
            {{ formatDayPtBr(demonstrativosDisplay.infoRescisao.dtAviso) }}
          </span>
        </div>
        <div class="d-flex flex-column col-3 mb-5">
          <span href="#" class="fs-6 text-dark mb-1"> Data de Pagamento </span>
          <span href="#" class="fw-normal">
            {{ formatDayPtBr(demonstrativosDisplay.infoRescisao.dtPagamento) }}
          </span>
        </div>
        <div class="d-flex flex-column col-2 mb-5">
          <span href="#" class="fs-6 text-dark mb-1"> Tipo Aviso </span>
          <span href="#" class="fw-normal">
            {{ demonstrativosDisplay.infoRescisao.tipoAviso.displayName }}
          </span>
        </div>
        <div class="d-flex flex-column col-2 mb-5">
          <span href="#" class="fs-6 text-dark mb-1"> Saldo FGTS </span>
          <span href="#" class="fw-normal">
            {{ formatMoney(demonstrativosDisplay.infoRescisao.saldoFgts) }}
          </span>
        </div>
      </div>
    </div>
    <div id="content-demonstrativo">
      <p class="fs-5 text-dark pt-4">Demonstrativo</p>
      <table class="table table-hover mb-0">
        <thead>
          <tr>
            <th scope="col">EVENTO</th>
            <th scope="col">REF.</th>
            <th scope="col"><p class="text-end my-0">PROVENTOS</p></th>
            <th scope="col text-end"><p class="text-end my-0">DESCONTOS</p></th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(tr, indexTr) in demonstrativosDisplay.folha.eventosFolha"
            :key="indexTr"
          >
            <th scope="row">
              <dropdown-quadro
                class="w-100"
                :fixed="tr.isFixed"
                position="bottom"
                @onEnterDropdown="getTooltip(tr.id, indexTr)"
              >
                <template #trigger>
                  <div
                    class="d-flex align-items-center justify-content-start rubrica-content"
                  >
                    <span class="cursor-default me-1">{{
                      tr.rubricaDescricao
                    }}</span>
                    <div
                      class="circle-demonstrativo-rosa ms-1"
                      v-if="tr.indBaseInss"
                    >
                      &nbsp;
                    </div>
                    <div
                      class="circle-demonstrativo-amarelo ms-1"
                      v-if="tr.indBaseIrrf"
                    >
                      &nbsp;
                    </div>
                    <div
                      class="circle-demonstrativo-verde ms-1"
                      v-if="tr.indBaseFgts"
                    >
                      &nbsp;
                    </div>
                  </div>
                </template>
                <div class="conteudo-dropdown">
                  <div v-if="tr.isLoading" class="d-block mx-auto my-2">
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div v-else>
                    <button
                      class="d-block mx-auto mb-2 btn btn-soft-light waves-effect waves-light"
                      @click="pinDropdown(indexTr)"
                    >
                      <img
                        v-if="tr.isFixed"
                        srcset="@/assets/images/unpin.png"
                        alt="pino_50x50"
                        loading="lazy"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        srcset="@/assets/images/pin.png"
                        alt="pino_50x50"
                        loading="lazy"
                        width="20"
                        height="20"
                      />
                    </button>

                    <p class="mt-2 fw-normal" v-html="tr.tooltipMsg" />
                  </div>
                </div>
              </dropdown-quadro>
            </th>
            <td style="max-width: 200px">{{ tr.descReferencia }}</td>
            <td class="text-end">
              {{
                tr.natureza.value === "PROVENTO" ? formatMoney(tr.valor) : ""
              }}
            </td>
            <td class="text-end">
              {{
                tr.natureza.value === "DESCONTO" ? formatMoney(tr.valor) : ""
              }}
            </td>
            <td class="fw-bolder">&nbsp;</td>
          </tr>
          <tr class="table-light">
            <th scope="row" class="fw-bolder">Total</th>
            <td></td>
            <td class="fw-bolder text-end">
              {{ formatMoney(demonstrativosDisplay.folha.bruto) }}
            </td>
            <td class="fw-bolder text-end">
              {{ formatMoney(demonstrativosDisplay.folha.descontos) }}
            </td>
            <td class="fw-bolder">&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <div
        class="d-flex px-3 flex-row col-md-12 justify-content-between border-bottom py-3"
      >
        <span class="fw-bolder">
          Valor do FGTS: {{ formatMoney(demonstrativosDisplay.folha.fgts) }}
        </span>
        <span class="fw-bolder text-primary">
          Líquido a receber:
          {{ formatMoney(demonstrativosDisplay.folha.liquido) }}
        </span>
      </div>
      <div class="d-flex justify-content-center pt-3">
        <div class="d-flex justify-content-between">
          <p>Base de Cálculo:</p>
          <div class="d-flex justify-content-start ms-10">
            <div class="pe-2">
              <p class="mt-1 circle-demonstrativo-rosa">&nbsp;</p>
            </div>
            <div>
              <b class="text-gray-700 pr-2">INSS:</b>
              <span>{{
                formatMoney(demonstrativosDisplay.folha.baseInss)
              }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-start ms-10">
            <div class="pe-2">
              <p class="mt-1 circle-demonstrativo-amarelo">&nbsp;</p>
            </div>
            <div>
              <b class="text-gray-700 pe-2">IRRF</b>
              <span>{{
                formatMoney(demonstrativosDisplay.folha.baseIrrf)
              }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-start ms-10">
            <div class="pe-2">
              <p class="mt-1 circle-demonstrativo-verde">&nbsp;</p>
            </div>
            <div>
              <b class="text-gray-700 pe-2">FGTS</b>
              <span>{{
                formatMoney(demonstrativosDisplay.folha.baseFgts)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top border-1 pt-3">
      <p class="fs-5 text-dark pb-1">Mais Informações</p>
      <div class="d-flex w-100 flex-row pb-20">
        <div class="d-flex flex-column pe-10" style="width: 33%">
          <div class="pb-3">
            <b class="text-dark">FGTS</b>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Multa</p>
            <p>
              {{ formatMoney(demonstrativosDisplay.infoRescisao.multaFgts) }}
            </p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Saque</p>
            <p>
              {{ formatMoney(demonstrativosDisplay.infoRescisao.saqueFgts) }}
            </p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Aviso</p>
            <p>
              {{ formatMoney(demonstrativosDisplay.infoRescisao.fgtsAviso) }}
            </p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>13° Salário</p>
            <p>{{ formatMoney(demonstrativosDisplay.infoRescisao.fgts13) }}</p>
          </div>
        </div>
        <div class="d-flex flex-column pe-10" style="width: 33%">
          <div class="pb-3">
            <b class="text-dark">INSS 13° Salário</b>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Base</p>
            <p>
              {{ formatMoney(demonstrativosDisplay.infoRescisao.inss13Base) }}
            </p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Base Excedente</p>
            <p>
              {{
                formatMoney(demonstrativosDisplay.infoRescisao.inss13BaseExcd)
              }}
            </p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Alíquota</p>
            <p>{{ demonstrativosDisplay.infoRescisao.inss13Aliquota }}%</p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Valor</p>
            <p>
              {{ formatMoney(demonstrativosDisplay.infoRescisao.inss13Valor) }}
            </p>
          </div>
        </div>
        <div class="d-flex flex-column" style="width: 33%">
          <div class="pb-3">
            <b class="text-dark">IRRF 13° Salário</b>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Base</p>
            <p>
              {{ formatMoney(demonstrativosDisplay.infoRescisao.irrf13Base) }}
            </p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Dedução</p>
            <p>
              {{
                formatMoney(demonstrativosDisplay.infoRescisao.irrf13Deducao)
              }}
            </p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Alíquota</p>
            <p>{{ demonstrativosDisplay.infoRescisao.irrf13Aliquota }}%</p>
          </div>
          <div class="d-flex justify-content-between flex-row">
            <p>Valor</p>
            <p>
              {{ formatMoney(demonstrativosDisplay.infoRescisao.irrf13Valor) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex flex-column w-100 align-items-center justify-center">
      <div class="spinner-border text-primary font-size-10 m-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { formatDayPtBr } from "@/composables/useFormatDate";
import { formatMoney } from "@/composables/useFormatMoney";
import MarkdownIt from "markdown-it";
import DropdownQuadro from "./DropdownQuadro";
import { useLazyQuery } from "@vue/apollo-composable";
import {
  getDemonstrativoRescisao,
  getInfoCalculo,
} from "@/state/graphql/demonstrativos";
import { apolloClient } from "@/state/client";

export default {
  name: "RescisaoDisplayView",
  components: {
    DropdownQuadro,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    idRescisao: {
      type: String,
    },
  },
  setup(props) {
    /*
    const demonstrativosDisplay = ref({
      folha: {
        id: "6ad96898-d94b-43e5-84f4-41937514edee",
        idTrabalhador: "11b67d89-2c7d-4c04-a643-24ed379eb60f",
        tipoCategoria: {
          displayName: "CLT",
          value: "CLT",
        },
        periodo: 202211,
        periodoComplementar: null,
        dtAcordoComplementar: null,
        tipoAcordoComplementar: null,
        iniReferencia: "2022-11-01",
        fimReferencia: "2022-11-29",
        bruto: 86666.66,
        descontos: 9504.24,
        liquido: 77162.42,
        baseInss: 7087.22,
        baseInssExcd: 21912.78,
        percInss: 11.69,
        inss: 828.39,
        baseFgts: 29000,
        percFgts: 8,
        fgts: 2320,
        baseIrrf: 27971.61,
        deduIrrf: 636.13,
        percIrrf: 22.5,
        irrf: 5657.48,
        dtPagamento: "2022-12-08",
        tipoFolha: {
          displayName: "Rescisão",
          value: "RESCISAO",
        },
        indVisualizado: false,
        eventosFolha: [
          {
            id: "d5dc9330-d862-4693-b019-dfa213c2b360",
            idFolha: "6ad96898-d94b-43e5-84f4-41937514edee",
            rubricaCodigo: 137,
            rubricaDescricao: "Adiantamento de Salário",
            indBaseInss: false,
            indBaseFgts: false,
            indBaseIrrf: true,
            baseDsr: false,
            fatorMultiplicador: 1,
            natureza: {
              displayName: "Desconto",
              value: "DESCONTO",
            },
            descReferencia: null,
            tipoReferencia: {
              displayName: "Valor",
              value: "VALOR",
            },
            descReferencia: null,
            origem: {
              displayName: "Automático",
              value: "AUTOMATICO",
            },
            valor: 200,
            indMediaFerias: false,
            indMedia13: false,
            indMediaAviso: false,
            isFixed: false,
            tooltipMsg: null,
          },
        ],
      },
      infoRescisao: {
        dtRescisao: "2022-11-28",
        dtAviso: "2022-11-28",
        dtPagamento: "2022-11-28",
        tipoAviso: {
          displayName: "Indenizado",
          Value: "Teste",
        },
        saldoFgts: 888,
        liquido: 999,
        motivo: {
          displayName: "Rescisão sem justa causa, por iniciativa do empregador",
          Value: "Teste",
        },
        nrProcessoTrabalhista: null,
        qntDiasAvisoIndenizado: 25,
      },
      visibleOriginal: false,
      visibleComplementar: false,
    });
    */

    const demonstrativosDisplay = ref(null);

    const {
      onResult: onResultRescisao,
      load: loadRescisao,
      loading: loadingRescisao,
    } = useLazyQuery(
      getDemonstrativoRescisao,
      { idRescisao: props.idRescisao },
      { fetchPolicy: "network-only" }
    );

    onResultRescisao((result) => {
      let apiRescisao = result.data.rescisaoCompleta;
      demonstrativosDisplay.value = {
        ...apiRescisao,
        folha: {
          ...apiRescisao.folha,
          eventosFolha: apiRescisao.folha.eventosFolha.map((evfl) => {
            return Object.assign({
              ...evfl,
              edit: false,
              isFixed: false,
              isLoading: false,
              tooltipMsg: null,
            });
          }),
        },
      };
    });

    function pinDropdown(indextr) {
      demonstrativosDisplay.value.folha.eventosFolha[indextr].isFixed =
        !demonstrativosDisplay.value.folha.eventosFolha[indextr].isFixed;
    }

    return {
      formatDayPtBr,
      formatMoney,
      demonstrativosDisplay,
      pinDropdown,
      loadRescisao,
      loadingRescisao,
    };
  },
  watch: {
    modelValue(nextValue) {
      this.$emit("update:modelValue", nextValue);
      if (nextValue && this.demonstrativosDisplay === null) this.loadRescisao();
    },
  },
  methods: {
    async getTooltip(idItem, indextr) {
      if (!this.demonstrativosDisplay.folha.eventosFolha[indextr].isFixed) {
        this.demonstrativosDisplay.folha.eventosFolha[indextr].isLoading = true;
        const resp = await apolloClient.query({
          query: getInfoCalculo,
          variables: { idEvento: idItem },
          fetchPolicy: "network-only",
        });
        const convert = new MarkdownIt();
        if (resp.data.infoCalculo !== null) {
          this.demonstrativosDisplay.folha.eventosFolha[indextr].tooltipMsg =
            await Promise.resolve(
              String(convert.render(resp.data.infoCalculo))
                .replace(/\n+$/, "")
                .replace(/(?:\r\n|\r|\n)/g, "<br>")
            );
        } else {
          this.demonstrativosDisplay.folha.eventosFolha[indextr].tooltipMsg =
            "Sem Informação";
        }
        this.demonstrativosDisplay.folha.eventosFolha[
          indextr
        ].isLoading = false;
      }
      // this.folhaDados.eventosFolha[indextr].isFixed

      // setTimeout(() => {
      // }, 300)
    },
  },
};
</script>

<style lang="postcss">
#content-demonstrativo .circle-demonstrativo-rosa {
  width: 8px;
  height: 8px;
  background-color: #d911a1;
  border-radius: 8px;
}
#content-demonstrativo .circle-demonstrativo-amarelo {
  width: 8px;
  height: 8px;
  background-color: #e6d437;
  border-radius: 8px;
}
#content-demonstrativo .circle-demonstrativo-verde {
  width: 8px;
  height: 8px;
  background-color: #0babab;
  border-radius: 8px;
}

.rubrica-content {
  max-width: 250px;
}

@media (min-width: 992px) {
  .rubrica-content {
    max-width: 350px;
  }
}
</style>
