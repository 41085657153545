<template>
  <div>
    <div v-if="!loadingSolicitacao && !loadingDataOptions">
      <accordion-mensagens
        v-model="accordionMensagens"
        :mensagens="mensagens"
      />
      <accordion-anexos
        v-model="accordionAnexos"
        :anexos="anexos"
        :solicitacao-id="solicitacaoId"
        @setLoadingAnexos="setLoadingAnexos"
      />
      <CtExpanded v-model="accordionContent" title="Conteúdo da solicitação">
        <template #content>
          <div>
            <div class="row">
              <div>
                <SectionTitle title="Nascimento" class="mb-6" />
              </div>

              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">País Nascimento*</label>
                <Multiselect
                  :classes="{
                    container: v$.infoTrabalhador.paisNascimento.$error
                      ? 'multiselect input-is-invalid'
                      : 'multiselect',
                  }"
                  v-model="infoTrabalhador.paisNascimento"
                  :filterResults="false"
                  :resolveOnLoad="false"
                  noOptionsText="A Lista esta Vazia"
                  noResultsText="Nenhum resultado encontrado"
                  :searchable="true"
                  :minChars="3"
                  trackBy="value"
                  :object="true"
                  label="nome"
                  :can-clear="false"
                  :clearOnSearch="true"
                  mode="single"
                  :disabled="workerIsApproved"
                  :delay="300"
                  :resolve-on-load="false"
                  placeholder="Selecione o País de Nacionalidade"
                  @close="v$.infoTrabalhador.paisNascimento.$touch"
                  :canDeselect="false"
                  :closeOnSelect="true"
                  :options="
                    async (query) => {
                      return await getPaises(query);
                    }
                  "
                />

                <div
                  v-show="v$.infoTrabalhador.paisNascimento.$error"
                  class="error-text"
                >
                  O Campo <b>País Nascimento</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Nacionalidade*</label>
                <Multiselect
                  :classes="{
                    container: v$.infoTrabalhador.nacionalidade.$error
                      ? 'multiselect input-is-invalid'
                      : 'multiselect',
                  }"
                  v-model="infoTrabalhador.nacionalidade"
                  :filterResults="false"
                  :resolveOnLoad="false"
                  noOptionsText="A Lista esta Vazia"
                  noResultsText="Nenhum resultado encontrado"
                  :searchable="true"
                  trackBy="value"
                  :object="true"
                  label="nome"
                  :minChars="3"
                  :delay="300"
                  :canClear="false"
                  :closeOnSelect="true"
                  :disabled="workerIsApproved"
                  placeholder="Selecione o País de Nacionalidade"
                  @close="v$.infoTrabalhador.nacionalidade.$touch"
                  :canDeselect="false"
                  :options="
                    async (query) => {
                      return await getPaises(query);
                    }
                  "
                />
                <div
                  v-show="v$.infoTrabalhador.nacionalidade.$error"
                  class="error-text"
                >
                  O campo <b>Nacionalidade</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div
                class="mb-6 col-12 col-md-6 col-xxl-4"
                v-if="
                  infoTrabalhador.paisNascimento.value !== 105 ||
                  infoTrabalhador.nacionalidade.value !== 105
                "
              >
                <label class="form-label">Imigrante*</label>
                <Multiselect
                  :classes="{
                    container: v$.infoTrabalhador.indImigrante.$error
                      ? 'multiselect input-is-invalid'
                      : 'multiselect',
                  }"
                  @close="v$.infoTrabalhador.indImigrante.$touch"
                  v-model="infoTrabalhador.indImigrante"
                  :filterResults="false"
                  :resolveOnLoad="false"
                  noOptionsText="A Lista esta Vazia"
                  noResultsText="Nenhum resultado encontrado"
                  :searchable="true"
                  :minChars="3"
                  :disabled="workerIsApproved"
                  :delay="300"
                  :canClear="false"
                  :closeOnSelect="true"
                  :canDeselect="false"
                  trackBy="value"
                  :object="true"
                  label="displayName"
                  placeholder="Selecione uma Opção"
                  :options="dataOptions.dataOptions.indicativoImigrante"
                />
                <div
                  v-show="v$.infoTrabalhador.indImigrante.$error"
                  class="error-text"
                >
                  O campo <b>Imigrante</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div
                v-if="infoTrabalhador.indImigrante.value !== 'NAO_IMIGRANTE'"
                class="mb-6 col-12 col-md-6 col-xxl-4"
              >
                <label class="form-label">Condição Imigrante</label>
                <Multiselect
                  :classes="{ container: 'multiselect' }"
                  v-model="infoTrabalhador.codImigrante"
                  :filterResults="false"
                  :resolveOnLoad="false"
                  :disabled="workerIsApproved"
                  noOptionsText="A Lista esta Vazia"
                  noResultsText="Nenhum resultado encontrado"
                  :closeOnSelect="true"
                  :canClear="false"
                  trackBy="value"
                  :object="true"
                  label="displayName"
                  placeholder="Selecione uma Opção"
                  :options="codImigrantes"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Raça*</label>
                <Multiselect
                  :classes="{
                    container: v$.infoTrabalhador.raca.$error
                      ? 'multiselect input-is-invalid'
                      : 'multiselect',
                  }"
                  v-model="infoTrabalhador.raca"
                  :closeOnSelect="true"
                  trackBy="value"
                  :disabled="workerIsApproved"
                  @close="v$.infoTrabalhador.raca.$touch"
                  :caret="true"
                  :canClear="false"
                  :object="true"
                  :canDeselect="false"
                  label="displayName"
                  placeholder="Selecione uma Opção"
                  :options="dataOptions.dataOptions.raca"
                />
                <div v-show="v$.infoTrabalhador.raca.$error" class="error-text">
                  O campo <b>raça</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Sexo*</label>
                <Multiselect
                  v-model="infoTrabalhador.sexo"
                  :classes="{
                    container: v$.infoTrabalhador.sexo.$error
                      ? 'multiselect input-is-invalid'
                      : 'multiselect',
                  }"
                  @close="v$.infoTrabalhador.sexo.$touch"
                  trackBy="value"
                  :canClear="false"
                  :object="true"
                  :closeOnSelect="true"
                  :canDeselect="false"
                  :disabled="workerIsApproved"
                  label="displayName"
                  placeholder="Selecione uma Opção"
                  :options="dataOptions.dataOptions.sexo"
                />
                <div v-show="v$.infoTrabalhador.sexo.$error" class="error-text">
                  O campo <b>sexo</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div>
                <SectionTitle title="Residência" class="mb-6" />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">País*</label>
                <Multiselect
                  :classes="{
                    container: v$.infoTrabalhador.endPais.$error
                      ? 'multiselect input-is-invalid'
                      : 'multiselect',
                  }"
                  @close="v$.infoTrabalhador.endPais.$touch"
                  v-model="infoTrabalhador.endPais"
                  :filterResults="false"
                  :disabled="workerIsApproved"
                  :resolveOnLoad="false"
                  noOptionsText="A Lista esta Vazia"
                  noResultsText="Nenhum resultado encontrado"
                  :searchable="true"
                  :canDeselect="false"
                  :minChars="3"
                  :delay="300"
                  trackBy="value"
                  :object="true"
                  label="nome"
                  :canClear="false"
                  :closeOnSelect="true"
                  placeholder="Selecione o País"
                  :options="
                    async (query) => {
                      return await getPaises(query);
                    }
                  "
                />
                <div
                  v-show="v$.infoTrabalhador.endPais.$error"
                  class="error-text"
                >
                  O campo <b>País</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">{{
                  infoTrabalhador.endPais.value === 105 ? "CEP*" : "ZIP*"
                }}</label>
                <input
                  :class="
                    v$.infoTrabalhador.endCep.$error
                      ? 'form-control is-invalid'
                      : 'form-control'
                  "
                  type="text"
                  :disabled="workerIsApproved"
                  :data-maska="showMaskCep"
                  v-maska
                  :placeholder="
                    infoTrabalhador.endPais.value === 105
                      ? 'Informe o CEP'
                      : 'Informe o Código ZIP'
                  "
                  @blur="v$.infoTrabalhador.endCep.$touch"
                  v-model="infoTrabalhador.endCep"
                />
                <div
                  v-show="v$.infoTrabalhador.endCep.$error"
                  class="error-text"
                >
                  O Campo
                  <b>{{
                    infoTrabalhador.endPais.value === 105 ? "CEP" : "ZIP"
                  }}</b>
                  é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">UF*</label>
                <Multiselect
                  :classes="{
                    container: v$.infoTrabalhador.endUf.$error
                      ? 'multiselect input-is-invalid'
                      : 'multiselect',
                  }"
                  @close="v$.infoTrabalhador.endUf.$touch"
                  v-model="infoTrabalhador.endUf"
                  trackBy="value"
                  :object="true"
                  :closeOnSelect="true"
                  :disabled="workerIsApproved"
                  :canDeselect="false"
                  :searchable="true"
                  placeholder="Selecione um Estado"
                  :canClear="false"
                  label="displayName"
                  :options="dataOptions.dataOptions.unidadeFederativa"
                />

                <div
                  v-show="v$.infoTrabalhador.endUf.$error"
                  class="error-text"
                >
                  O campo <b>UF</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Logradouro*</label>
                <input
                  :class="
                    v$.infoTrabalhador.endLogradouro.$error
                      ? 'form-control is-invalid'
                      : 'form-control'
                  "
                  type="text"
                  placeholder="Logradouro"
                  :disabled="workerIsApproved"
                  v-model="infoTrabalhador.endLogradouro"
                  @blur="v$.infoTrabalhador.endLogradouro.$touch"
                />
                <div
                  v-show="v$.infoTrabalhador.endLogradouro.$error"
                  class="error-text"
                >
                  O Campo <b>Logradouro</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Número*</label>
                <input
                  :class="
                    v$.infoTrabalhador.endNumero.$error
                      ? 'form-control is-invalid'
                      : 'form-control'
                  "
                  @blur="v$.infoTrabalhador.endNumero.$touch"
                  type="text"
                  :disabled="workerIsApproved"
                  placeholder="Informe o Número"
                  v-model="infoTrabalhador.endNumero"
                />
                <div
                  v-show="v$.infoTrabalhador.endNumero.$error"
                  class="error-text"
                >
                  O Campo <b>Número</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Complemento</label>
                <input
                  class="form-control"
                  type="text"
                  :disabled="workerIsApproved"
                  placeholder="Informe o Complemento"
                  v-model="infoTrabalhador.endComplemento"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Bairro*</label>
                <input
                  :class="
                    v$.infoTrabalhador.endBairro.$error
                      ? 'form-control is-invalid'
                      : 'form-control'
                  "
                  @blur="v$.infoTrabalhador.endBairro.$touch"
                  type="text"
                  placeholder="Informe o seu Bairro"
                  :disabled="workerIsApproved"
                  v-model="infoTrabalhador.endBairro"
                />
                <div
                  v-show="v$.infoTrabalhador.endBairro.$error"
                  class="error-text"
                >
                  O Campo <b>Bairro</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Nome do Município*</label>
                <input
                  :class="
                    v$.infoTrabalhador.endMunicipioNome.$error
                      ? 'form-control is-invalid'
                      : 'form-control'
                  "
                  @blur="v$.infoTrabalhador.endMunicipioNome.$touch"
                  class="form-control"
                  :disabled="workerIsApproved"
                  type="text"
                  :placeholder="
                    workerIsApproved
                      ? 'Não informado'
                      : 'Informe o Nome do Município'
                  "
                  v-model="infoTrabalhador.endMunicipioNome"
                />
                <div
                  v-show="v$.infoTrabalhador.endMunicipioNome.$error"
                  class="error-text"
                >
                  O Campo <b>Nome do Município</b> é de preenchimento
                  obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Código do Município*</label>
                <input
                  :class="
                    v$.infoTrabalhador.endMunicipio.$error
                      ? 'form-control is-invalid'
                      : 'form-control'
                  "
                  @blur="v$.infoTrabalhador.endMunicipio.$touch"
                  type="text"
                  :disabled="workerIsApproved"
                  placeholder="Informe o Código do Município"
                  v-model="infoTrabalhador.endMunicipio"
                />
                <div
                  v-show="v$.infoTrabalhador.endMunicipio.$error"
                  class="error-text"
                >
                  O Campo <b>Código do Município</b> é de preenchimento
                  obrigatório.
                </div>
              </div>
              <div>
                <SectionTitle title="Atribuições" class="mb-6" />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Estado Civil*</label>
                <Multiselect
                  :class="
                    v$.infoTrabalhador.estadoCivil.$error
                      ? 'form-control is-invalid'
                      : 'form-control'
                  "
                  v-model="infoTrabalhador.estadoCivil"
                  :closeOnSelect="true"
                  trackBy="value"
                  :caret="true"
                  :object="true"
                  :disabled="workerIsApproved"
                  :canDeselect="false"
                  :canClear="false"
                  label="displayName"
                  placeholder="Selecione uma Opção"
                  @close="v$.infoTrabalhador.estadoCivil.$touch"
                  :options="dataOptions.dataOptions.estadoCivil"
                />
                <div
                  v-show="v$.infoTrabalhador.estadoCivil.$error"
                  class="error-text"
                >
                  O Campo <b>Estado Civil</b> é de preenchimento obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label for="example-text-input" class="form-label"
                  >Nome Social</label
                >
                <input
                  class="form-control"
                  type="text"
                  :disabled="workerIsApproved"
                  placeholder="Informe o Nome Social"
                  v-model="infoTrabalhador.nomeSocial"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Grau de Instrução*</label>
                <Multiselect
                  :class="
                    v$.infoTrabalhador.grauInstrucao.$error
                      ? 'form-control is-invalid'
                      : 'form-control'
                  "
                  v-model="infoTrabalhador.grauInstrucao"
                  trackBy="value"
                  :disabled="workerIsApproved"
                  :closeOnSelect="true"
                  :canDeselect="false"
                  :canClear="false"
                  :object="true"
                  label="displayName"
                  placeholder="Selecione uma Opção"
                  @close="v$.infoTrabalhador.grauInstrucao.$touch"
                  :options="dataOptions.dataOptions.grauInstrucao"
                />
                <div
                  v-show="v$.infoTrabalhador.grauInstrucao.$error"
                  class="error-text"
                >
                  O Campo <b>Grau de Instrução</b> é de preenchimento
                  obrigatório.
                </div>
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label for="example-text-input" class="form-label"
                  >Carteira CNH</label
                >
                <input
                  class="form-control"
                  :disabled="workerIsApproved"
                  type="text"
                  :placeholder="
                    workerIsApproved ? 'Não informado' : 'Número CNH'
                  "
                  v-model="infoTrabalhador.cnh"
                />
              </div>
              <div class="mb-6 col-6 col-md-3 col-xxl-2">
                <label for="example-text-input" class="form-label"
                  >Categoria CNH</label
                >
                <input
                  class="form-control"
                  :disabled="workerIsApproved"
                  type="text"
                  :placeholder="
                    workerIsApproved ? 'Não informado' : 'Categoria CNH'
                  "
                  v-model="infoTrabalhador.cnhCategoria"
                />
              </div>
              <div class="mb-6 col-6 col-md-3 col-xxl-2">
                <label for="example-text-input" class="form-label"
                  >Venc. CNH</label
                >
                <Datepicker
                  v-model="infoTrabalhador.cnhVencimento"
                  :autoApply="true"
                  :textInput="true"
                  :enableTimePicker="false"
                  format="dd/MM/yyyy"
                  :disabled="workerIsApproved"
                  locale="pt-BR"
                  :placeholder="
                    workerIsApproved ? 'Não informado' : 'Vencimento CNH'
                  "
                  :format-locale="ptBR"
                />
              </div>
              <div class="mb-6 col-6 col-md-6 col-xxl-4">
                <label class="form-label">CNH UF</label>
                <Multiselect
                  v-model="infoTrabalhador.cnhUf"
                  trackBy="value"
                  :disabled="workerIsApproved"
                  :searchable="true"
                  :object="true"
                  placeholder="Selecione um Estado"
                  :canClear="false"
                  label="displayName"
                  :options="dataOptions.dataOptions.unidadeFederativa"
                />
              </div>
              <div>
                <SectionTitle title="Possui Alguma Deficiência?" class="mb-6" />
              </div>
              <div class="row">
                <div class="mb-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="defFisica"
                      v-model="infoTrabalhador.defFisica"
                      :disabled="workerIsApproved"
                    />
                    <label class="form-check-label" for="defFisica">
                      Física
                    </label>
                  </div>
                </div>

                <div class="mb-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :disabled="workerIsApproved"
                      id="defVisual"
                      v-model="infoTrabalhador.defVisual"
                    />
                    <label class="form-check-label" for="defVisual">
                      Visual
                    </label>
                  </div>
                </div>

                <div class="mb-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :disabled="workerIsApproved"
                      id="defMental"
                      v-model="infoTrabalhador.defMental"
                    />
                    <label class="form-check-label" for="defMental">
                      Mental
                    </label>
                  </div>
                </div>

                <div class="mb-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :disabled="workerIsApproved"
                      id="defAuditiva"
                      v-model="infoTrabalhador.defAuditiva"
                    />
                    <label class="form-check-label" for="defAuditiva">
                      Auditiva
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :disabled="workerIsApproved"
                      id="defIntelectual"
                      v-model="infoTrabalhador.defIntelectual"
                    />
                    <label class="form-check-label" for="defIntelectual">
                      Intelectual
                    </label>
                  </div>
                </div>
                <div class="mb-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      :disabled="workerIsApproved"
                      type="checkbox"
                      id="defReabilitado"
                      v-model="infoTrabalhador.defReabilitado"
                    />
                    <label class="form-check-label" for="defReabilitado">
                      Reabilitado
                    </label>
                  </div>
                </div>
                <div class="mb-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="defCota"
                      :disabled="workerIsApproved"
                      v-model="infoTrabalhador.defCota"
                    />
                    <label class="form-check-label" for="defCota"> Cota </label>
                  </div>
                </div>
              </div>
              <div>
                <SectionTitle title="Dependentes" class="mb-6" />
              </div>
              <div class="row">
                <div v-if="infoTrabalhador.dependentes.length > 0">
                  <div
                    v-for="(dep, index) in infoTrabalhador.dependentes"
                    :key="index"
                    class="row item-dependente text-center text-md-start rounded mb-4 pt-3 pb-3 pt-md-2 pb-md-2"
                  >
                    <div class="col-5 mb-4 mb-md-0">
                      <h5
                        class="text-uppercase font-size-10 text-dark"
                        style="text-overflow: ellipsis"
                        v-if="
                          dep.tipoDependente || dep.tipoDependente.displayName
                        "
                      >
                        {{
                          workerIsApproved
                            ? getDisplayName(dep.tipoDependente.value)
                            : getDisplayName(dep.tipoDependente)
                        }}
                      </h5>
                      <p class="mb-0">
                        {{ dep.nome }}
                      </p>
                    </div>
                    <div class="col-3 mb-4 mb-md-0">
                      <h5 class="text-uppercase font-size-10 text-dark">
                        Idade
                      </h5>
                      <p class="mb-0">
                        {{ currentAge(dep.dtNascimento) }} anos
                      </p>
                    </div>
                    <div class="col-2 mb-4 mb-md-0">
                      <h5 class="text-uppercase font-size-10 text-dark">
                        Sexo
                      </h5>
                      <p class="mb-0">
                        {{ workerIsApproved ? dep.sexo.displayName : dep.sexo }}
                      </p>
                    </div>
                    <div class="col-2 text-md-end">
                      <button
                        @click="openModalDeleteDependente(index)"
                        :disabled="workerIsApproved"
                        class="btn btn-exclude-dependente font-size-14"
                      >
                        <i class="bx bx-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else-if="workerIsApproved" class="mb-6">
                  Não possui dependentes
                </div>
                <div v-if="!workerIsApproved" class="col-md-3 mb-6">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-with-icon waves-effect waves-light"
                    data-bs-toggle="modal"
                    data-bs-target="#modalCreateDependente"
                    :disabled="workerIsApproved"
                  >
                    <i class="bx bx-plus font-size-16 align-middle me-2"></i>
                    Dependente
                  </button>
                </div>
                <!-- sample modal content -->
                <div class="grid-col-6">
                  <div
                    id="modalCreateDependente"
                    class="modal fade"
                    tabindex="-1"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                    data-bs-scroll="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="myModalLabel">
                            Novo Dependente
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            @click="closeModalNewDependente"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <p>
                            Todos os dados dos dependentes poderão ser
                            visualizados posteriormente no perfil do
                            trabalhador.
                            <!-- TODO -->
                            <a
                              href="https://google.com.br"
                              class="ct-link"
                              target="_blank"
                              >Saiba mais!</a
                            >
                          </p>
                          <div class="mb-3">
                            <label class="form-label"
                              >Tipo de Dependente*</label
                            >
                            <Multiselect
                              v-model="dependente.tipoDependente"
                              trackBy="value"
                              placeholder="Selecione uma Opção"
                              :canClear="false"
                              label="displayName"
                              :options="dataOptions.dataOptions.tipoDependente"
                            />
                          </div>

                          <div class="mb-3">
                            <label class="form-label">Nome*</label>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Informe o Nome do Dependente"
                              v-model="dependente.nome"
                            />
                          </div>

                          <div class="mb-3">
                            <label class="form-label"
                              >Data de Nascimento*</label
                            >
                            <!-- type="text" -->
                            <!-- id="datepicker-basic" -->
                            <Datepicker
                              v-model="dependente.dtNascimento"
                              :autoApply="true"
                              menuClassName="mt-calendar"
                              :textInput="true"
                              :enableTimePicker="false"
                              format="dd/MM/yyyy"
                              locale="pt-BR"
                              placeholder="Data de Nascimento"
                              :format-locale="ptBR"
                            />
                          </div>

                          <div class="mb-3">
                            <label class="form-label">Sexo*</label>
                            <Multiselect
                              v-model="dependente.sexo"
                              trackBy="value"
                              :canClear="false"
                              :closeOnSelect="true"
                              :canDeselect="false"
                              :disabled="workerIsApproved"
                              label="displayName"
                              placeholder="Selecione uma Opção"
                              :options="dataOptions.dataOptions.sexo"
                            />
                          </div>

                          <div class="mb-3">
                            <label class="form-label">CPF</label>
                            <input
                              v-maska
                              data-maska="###.###.###-##"
                              class="form-control"
                              type="text"
                              placeholder="Informe o CPF do Dependente"
                              v-model="dependente.cpf"
                            />
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary waves-effect"
                            @click="closeModalNewDependente"
                          >
                            Cancelar
                          </button>
                          <button
                            type="button"
                            :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor}; color: ${getTextMenuColor};`"
                            class="btn btn-primary waves-effect waves-light"
                            @click="saveAndResetDependente"
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                      <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                  </div>
                  <!-- /.modal -->

                  <div
                    id="modalDeleteDependente"
                    class="modal fade"
                    tabindex="-1"
                    aria-hidden="true"
                    data-bs-scroll="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">Excluir Dependente</h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          Esta ação não pode ser desfeita. Isso excluirá
                          permanentemente o dependente que você criou.
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary waves-effect"
                            data-bs-dismiss="modal"
                          >
                            Cancelar
                          </button>
                          <button
                            type="button"
                            :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor}; color: ${getTextMenuColor};`"
                            class="btn btn-primary waves-effect waves-light"
                            @click="deleteDependente"
                          >
                            Excluir
                          </button>
                        </div>
                      </div>
                      <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                  </div>
                </div>
              </div>
              <div>
                <SectionTitle title="Outras Informações" class="mb-6" />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Nome da Mãe</label>
                <input
                  class="form-control"
                  type="text"
                  :placeholder="
                    workerIsApproved
                      ? 'Não informado'
                      : 'Informe o Nome da Sua Mãe'
                  "
                  :disabled="workerIsApproved"
                  v-model="infoTrabalhador.nomeMae"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">NIS</label>
                <input
                  class="form-control"
                  type="text"
                  :placeholder="
                    workerIsApproved ? 'Não informado' : 'Informe o NIS'
                  "
                  :disabled="workerIsApproved"
                  v-model="infoTrabalhador.nis"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Número de Reservista</label>
                <input
                  class="form-control"
                  type="text"
                  :placeholder="
                    workerIsApproved
                      ? 'Não informado'
                      : 'Informe o Número de Reservista'
                  "
                  :disabled="workerIsApproved"
                  v-model="infoTrabalhador.numeroReservista"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">Título de Eleitor</label>
                <input
                  class="form-control"
                  type="text"
                  :placeholder="
                    workerIsApproved
                      ? 'Não informado'
                      : 'Informe o Título de Eleitor'
                  "
                  :disabled="workerIsApproved"
                  v-model="infoTrabalhador.tituloEleitor"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">CTPS Número</label>
                <input
                  class="form-control"
                  type="text"
                  :placeholder="
                    workerIsApproved
                      ? 'Não informado'
                      : 'Informe a o Número CTPS'
                  "
                  :disabled="workerIsApproved"
                  v-model="infoTrabalhador.ctpsNumero"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">CTPS Série</label>
                <input
                  class="form-control"
                  type="text"
                  :placeholder="
                    workerIsApproved
                      ? 'Não informado'
                      : 'Informe a Série da CTPS'
                  "
                  :disabled="workerIsApproved"
                  v-model="infoTrabalhador.ctpsSerie"
                />
              </div>
              <div class="mb-6 col-12 col-md-6 col-xxl-4">
                <label class="form-label">CTPS UF</label>
                <Multiselect
                  v-model="infoTrabalhador.ctpsUf"
                  trackBy="value"
                  :object="true"
                  :placeholder="
                    workerIsApproved ? 'Não informado' : 'Selecione uma Opção'
                  "
                  :canClear="false"
                  label="displayName"
                  :disabled="workerIsApproved"
                  :options="dataOptions.dataOptions.unidadeFederativa"
                />
              </div>
              <div
                v-if="!workerIsApproved"
                class="col-12 d-flex justify-content-end"
              >
                <button
                  type="button"
                  class="btn btn-outline-primary waves-effect waves-light"
                  @click="onOpenModalResposta"
                  :disabled="workerIsApproved"
                >
                  Enviar Dados
                </button>
              </div>
              <div class="grid-col-6">
                <div
                  id="modalRespondeSolicitcao"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  data-bs-scroll="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="myModalLabel">
                          Responder Solicitação
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <ContentModalRespondeSolicitacao
                          :id-solicitacao="solicitacaoId"
                          :info-pessoal="infoTrabalhador"
                          @onRefetchSolicitacao="onRefetchSolicitacao"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </CtExpanded>
    </div>
    <SkeletonAccordionSolicitacao v-else />
  </div>
</template>
<script>
import { ref, reactive, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import CtExpanded from "@/components/CtExpanded/CtExpanded.vue";
import AccordionMensagens from "./AccordionMensagens.vue";
import AccordionAnexos from "./AccordionAnexos.vue";
import SectionTitle from "@/components/Title/SectionTitle.vue";
import { required, requiredIf } from "@vuelidate/validators";
import Multiselect from "@vueform/multiselect";
import Datepicker from "@vuepic/vue-datepicker";
import SkeletonAccordionSolicitacao from "../SkeletonAccordionSolicitacao.vue";
import { formatDayPtBr } from "@/composables/useFormatDate";
import "@vuepic/vue-datepicker/dist/main.css";
/*
import {
  getDatasFerias,
  getPeriodosAquisitivos,
} from "@/src/state/graphql/ferias/tempoRealFerias";
*/
import { getSolicitacao } from "@/state/graphql/solicitacoes";
import { cpf } from "cpf-cnpj-validator";
import useVuelidate from "@vuelidate/core";
import { getDataOptions, getOptionsPaises } from "@/state/graphql/trabalhador";
import { apolloClient } from "@/state/client";
import { ptBR } from "date-fns/locale";
import { format, differenceInYears } from "date-fns";
import Toast from "@/components/Toast/Toast.vue";
import { TYPE, useToast } from "vue-toastification";
import { vMaska } from "maska";
import {
  getBGMenuColor,
  getTextMenuColor,
} from "@/state/modules/themeColor.store";
import ContentModalRespondeSolicitacao from "../ContentModalRespondeSolicitacao.vue";
export default {
  components: {
    AccordionMensagens,
    AccordionAnexos,
    CtExpanded,
    SectionTitle,
    Multiselect,
    Datepicker,
    ContentModalRespondeSolicitacao,
    SkeletonAccordionSolicitacao,
  },
  directives: { maska: vMaska },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    solicitacaoId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const mensagens = ref([]);
    const anexos = ref([]);
    const workerIsApproved = ref(false);

    const showConcuirSolicitacao = ref(false);
    const tipoConclusao = ref("APROVAR");
    const status = ref({
      displayName: "Reprovada",
      value: "REPROVADA",
    });

    const hasDataTrabalhador = ref(false);
    const dependente = reactive({
      tipoDependente: "CONJUGE",
      nome: null,
      dtNascimento: null,
      sexo: null,
      cpf: null,
    });

    const infoTrabalhador = reactive({
      nomeSocial: null,
      cnh: null,
      cnhCategoria: null,
      endLogradouro: null,
      endNumero: null,
      endComplemento: null,
      endBairro: null,
      endMunicipio: null,
      endMunicipioNome: null,
      defFisica: false,
      defVisual: false,
      defMental: false,
      defAuditiva: false,
      defIntelectual: false,
      defReabilitado: false,
      defCota: false,
      nomeMae: null,
      nis: null,
      numeroReservista: null,
      tituloEleitor: null,
      ctpsNumero: null,
      ctpsSerie: null,
      dependentes: [],
      codImigrante: null,
      cnhVencimento: null,
      paisNascimento: {
        value: 105,
        nome: "Brasil",
      },
      nacionalidade: {
        value: 105,
        nome: "Brasil",
      },
      indImigrante: {
        displayName: "Não é imigrante",
        value: "NAO_IMIGRANTE",
      },
      sexo: null,
      // displayName: 'Masculino',
      // value: 'MASCULINO'
      // displayName: 'Branco',
      // value: 'BRANCA'
      raca: null,
      estadoCivil: null,
      grauInstrucao: null,
      endCep: null,
      endUf: null,
      ctpsUf: null,
      cnhUf: null,
      endPais: {
        value: 105,
        nome: "Brasil",
      },
    });

    const accordionContent = ref(!!props.modelValue);
    const accordionMensagens = ref(!!props.modelValue);
    const accordionAnexos = ref(!!props.modelValue);

    const indexDependente = ref(0);

    const toast = useToast();

    const {
      loading: loadingSolicitacao,
      onResult: onResultSolicitacao,
      refetch: refetchSolicitacao,
    } = useQuery(
      getSolicitacao,
      () => ({
        solicitacaoId: props.solicitacaoId,
      }),
      { fetchPolicy: "network-only" }
    );

    onResultSolicitacao((result) => {
      if (!result.loading && result.data) {
        if (result.data.solicitacao) {
          if (result.data.solicitacao?.objeto?.infoPessoal) {
            hasDataTrabalhador.value = true;
            Object.assign(infoTrabalhador, {
              ...result.data.solicitacao.objeto.infoPessoal,
              paisNascimento: {
                value:
                  result.data.solicitacao.objeto.infoPessoal.paisNascimento
                    .codigo,
                nome: result.data.solicitacao.objeto.infoPessoal.paisNascimento
                  .nome,
              },
              endPais: {
                value:
                  result.data.solicitacao.objeto.infoPessoal.endPais.codigo,
                nome: result.data.solicitacao.objeto.infoPessoal.endPais.nome,
              },
              nacionalidade: {
                value:
                  result.data.solicitacao.objeto.infoPessoal.nacionalidade
                    .codigo,
                nome: result.data.solicitacao.objeto.infoPessoal.nacionalidade
                  .nome,
              },
            });
            workerIsApproved.value = true;
          }
          status.value = result.data.solicitacao.status;
          emit("setStatusSolicitacao", props.solicitacaoId, status.value);
          mensagens.value = result.data.solicitacao.mensagens;
          anexos.value = result.data.solicitacao.anexos.map((item) => ({
            name: item,
            isLoading: false,
          }));
        }
      }
    });

    const getPaises = async (searchParam) => {
      try {
        const resp = await apolloClient.query({
          query: getOptionsPaises,
          variables: { search: searchParam ? searchParam : "Brasil" },
          context: { clientName: "endpoint1" },
          fetchPolicy: "no-cache",
        });
        if (resp.data.paises) {
          return resp.data.paises.map((pais) => {
            return {
              value: pais.codigo,
              nome: pais.nome,
            };
          });
        } else {
          return [
            {
              value: 105,
              nome: "Brasil",
            },
          ];
        }
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const {
      result: resultTrabalhadorDataOptions,
      loading: loadingDataOptions,
    } = useQuery(getDataOptions, null, {
      context: { clientName: "endpoint1" },
      fetchPolicy: "network-only",
    });

    const dataOptions = computed(
      () => resultTrabalhadorDataOptions.value ?? []
    );

    const codImigrantes = computed(() => {
      if (infoTrabalhador.indImigrante === "POR_PRAZO_INDETERMINADO") {
        // form.codImigrante = null;
        return resultTrabalhadorDataOptions.value.dataOptions.condicaoImigrante.filter(
          (codImigra) => {
            return (
              codImigra.value !== "REFUGIADO" &&
              codImigra.value !== "DEPENDENTE_CONSULAR"
            );
          }
        );
      }
      return (
        resultTrabalhadorDataOptions.value.dataOptions?.condicaoImigrante ?? []
      );
    });

    const showMaskCep = computed(() => {
      return infoTrabalhador.endPais.value === 105
        ? "#####-###"
        : "#######################";
    });

    function clearEndereco() {
      Object.assign(infoTrabalhador, {
        ...infoTrabalhador,
        endNumero: null,
        // endUf: null,
        endMunicipioNome: null,
        endLogradouro: null,
        endMunicipio: null,
        endComplemento: null,
        endBairro: null,
      });
    }

    function preencherEndereco(endereco) {
      infoTrabalhador.endBairro = endereco.bairro;
      infoTrabalhador.endLogradouro = endereco.logradouro;
      infoTrabalhador.endMunicipioNome = endereco.localidade
        ? endereco.localidade
        : null;
      infoTrabalhador.endMunicipio = endereco.ibge;
      infoTrabalhador.endComplemento = endereco.complemento
        ? endereco.complemento
        : infoTrabalhador.endComplemento;
      const currentUf = dataOptions.value.dataOptions.unidadeFederativa.filter(
        (uf) => uf.value === endereco.uf
      );
      infoTrabalhador.endUf = currentUf[0].value;
    }

    async function fetchCep(cepValue) {
      if (cepValue.length === 9 && infoTrabalhador.endPais.value === 105) {
        let search = cepValue.replace("-", "");
        const url = `https://viacep.com.br/ws/${search}/json/`;
        fetch(url);
        const dados = await fetch(url);
        const endereco = await dados.json();
        // eslint-disable-next-line no-prototype-builtins
        if (endereco.hasOwnProperty("erro")) {
          console.error("erro");
        } else {
          preencherEndereco(endereco);
        }
      }
    }

    const openModalConluirSolicitacao = (newTipo) => {
      showConcuirSolicitacao.value = true;
      tipoConclusao.value = newTipo;
    };

    const currentAge = (date) => {
      return differenceInYears(new Date(), new Date(date));
    };

    const formatDay = (day) => {
      if (day) {
        return format(new Date(day), "yyyy-MM-dd");
      }
      return null;
    };

    const setLoadingAnexos = (indexAnexo, newValue) => {
      anexos.value[indexAnexo].isLoading = newValue;
    };

    const getDisplayName = (tipoDepValue) => {
      let findDependente = dataOptions.value.dataOptions.tipoDependente.find(
        (depedente) => depedente.value === tipoDepValue
      );
      if (findDependente?.displayName) {
        return findDependente.displayName;
      }
    };

    return {
      formatDayPtBr,
      loadingSolicitacao,
      mensagens,
      showConcuirSolicitacao,
      tipoConclusao,
      status,
      refetchSolicitacao,
      anexos,
      accordionContent,
      openModalConluirSolicitacao,
      workerIsApproved,
      infoTrabalhador,
      v$: useVuelidate(),
      getPaises,
      loadingDataOptions,
      dataOptions,
      codImigrantes,
      clearEndereco,
      fetchCep,
      showMaskCep,
      ptBR,
      dependente,
      currentAge,
      indexDependente,
      formatDay,
      toast,
      getBGMenuColor,
      getTextMenuColor,
      hasDataTrabalhador,
      setLoadingAnexos,
      getDisplayName,
      accordionMensagens,
      accordionAnexos,
    };
  },
  mounted() {
    this.$watch("infoTrabalhador.endCep", function (value) {
      if (value && value.length === 9 && !this.hasDataTrabalhador) {
        this.fetchCep(value);
      }
      if (value && value.length === 8) {
        this.clearEndereco();
      }
    });
  },
  methods: {
    onRefetchSolicitacao() {
      this.refetchSolicitacao();
      $("#modalRespondeSolicitcao").modal("hide");
    },
    resetDependenteForm() {
      this.dependente.nome = null;
      this.dependente.tipoDependente = {
        displayName: "Cônjuge",
        value: "CONJUGE",
      };
      this.dependente.dtNascimento = null;
      this.dependente.sexo = null;
      this.dependente.cpf = null;
      this.v$.dependente.$reset();
    },
    saveAndResetDependente() {
      this.v$.dependente.$touch();
      if (this.v$.dependente.$invalid) {
        this.errorToast();
      } else {
        const dependente = {
          nome: this.dependente.nome,
          dtNascimento: this.formatDay(this.dependente.dtNascimento),
          tipoDependente: this.dependente.tipoDependente,
          sexo: this.dependente.sexo,
          cpf: this.dependente.cpf,
        };
        this.infoTrabalhador.dependentes.push(dependente);
        $("#modalCreateDependente").modal("hide");
        this.resetDependenteForm();
        this.successToast(
          "Solicitação Concluída",
          "Dependente salvo com sucesso!"
        );
      }
    },
    deleteDependente() {
      this.infoTrabalhador.dependentes.splice(this.indexDependente, 1);
      $("#modalDeleteDependente").modal("hide");
      this.successToast(
        "Solicitação Concluída",
        "Dependente excluído com sucesso!"
      );
    },
    openModalDeleteDependente(index) {
      $("#modalDeleteDependente").modal("show");
      this.indexDependente = index;
    },
    async onOpenModalResposta() {
      let validateForm = await this.v$.infoTrabalhador.$validate();
      if (validateForm) {
        $("#modalRespondeSolicitcao").modal("show");
      } else {
        this.errorToast();
      }
    },
    errorToast(msg) {
      this.toast(
        {
          component: Toast,
          props: {
            title: `Não foi possível salvar!`,
            text: msg
              ? msg
              : "Por favor verifique se você preencheu os campos corretamente!",
          },
        },
        { type: TYPE.ERROR }
      );
    },
    successToast(title, msg) {
      this.toast(
        {
          component: Toast,
          props: {
            title: title ? title : "Campo alterado com sucesso!",
            text: msg ? msg : "O campo foi alterado com sucesso!",
          },
        },
        { type: TYPE.SUCCESS }
      );
    },
    closeModalNewDependente() {
      $("#modalCreateDependente").modal("hide");
      this.resetDependenteForm();
    },
  },
  validations() {
    return {
      dependente: {
        tipoDependente: {
          required,
        },
        dtNascimento: {
          required,
        },
        sexo: {
          required,
        },
        nome: {
          required,
        },
        cpf: {
          cpf: function (value) {
            if (value !== null && value !== "") {
              return cpf.isValid(value);
            }
            return true;
          },
        },
      },
      infoTrabalhador: {
        paisNascimento: { required },
        endPais: { required },
        nacionalidade: { required },
        indImigrante: {
          requiredIndImigrante: requiredIf(() => {
            return (
              this.infoTrabalhador.nacionalidade.value !== 105 ||
              this.infoTrabalhador.paisNascimento.value !== 105
            );
          }),
        },
        raca: { required },
        grauInstrucao: { required },
        sexo: { required },
        // nomeMae: { required },
        estadoCivil: { required },
        endCep: { required },
        endLogradouro: { required },
        endNumero: { required },
        endBairro: { required },
        endMunicipioNome: { required },
        endMunicipio: { required },
        endUf: { required },
      },
    };
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/custom/plugins/datepicker/main.scss";
</style>
