<template>
  <div
    id="modalConfirmDelete"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    data-bs-scroll="true"
  >
    <div class="modal-dialog" style="max-width: 400px">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="refUpload.clear"
          ></button>
        </div>
        <div class="modal-body">
          <p class="fs-5">{{ description }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary waves-effect"
            data-bs-dismiss="modal"
            @click.prevent="() => {} /*refUpload.clear*/"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-danger waves-effect waves-light"
            @click="mutationDelete"
          >
            <i
              class="bx bx-loader bx-spin font-size-16 align-middle me-2"
              v-if="loading"
            ></i>
            Excluir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Deletar Informação",
    },
    description: {
      type: String,
      default: "",
    },
    mutationDelete: {
      type: Function,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
