<template>
  <div>
    <div v-if="!loadingSolicitacao">
      <accordion-mensagens
        v-model="accordionMensagens"
        :mensagens="mensagens"
      />
      <accordion-anexos
        v-model="accordionAnexos"
        :anexos="anexos"
        :solicitacao-id="solicitacaoId"
        @setLoadingAnexos="setLoadingAnexos"
      />
      <CtExpanded v-model="accordionContent" title="Conteúdo da solicitação">
        <template #content>
          <div>
            <div class="row">
              <div class="mt-5 mt-md-0 mt-ml-0 col-12 col-md-4">
                <ct-label text="Quantidade Dias" />
                <div class="lg:mt-1">
                  {{ ferias.qntDias }}
                </div>
              </div>
              <div class="mt-5 mt-md-0 mt-ml-0 col-12 col-md-4">
                <ct-label text="Abono" />
                <div class="lg:mt-1">
                  {{ ferias.diasAbono }}
                </div>
              </div>
              <div class="mt-5 mt-md-0 mt-ml-0 col-12 col-md-4">
                <ct-label text="Data Saída" />
                <div class="lg:mt-1">
                  {{ formatDayPtBr(ferias.dtSaida) }}
                </div>
              </div>
              <!--
              <div class="mt-5 mt-ml-0 col-12 col-md-4">
                <ct-label text="Data Pagamento" />
                <div class="lg:mt-1">
                  {{ formatDayPtBr(ferias.dtPagamento) }}
                </div>
              </div>
              <div class="mt-5 mt-ml-0 col-12 col-md-4">
                <ct-label text="Retorno Previsto" />
                <div class="lg:mt-1">
                  {{ formatDayPtBr(ferias.dtRetorno) }}
                </div>
              </div>
            --></div>
          </div>
        </template>
      </CtExpanded>
    </div>
    <SkeletonAccordionSolicitacao v-else />
  </div>
</template>
<script>
import CtLabel from "@/components/fields/input/CtLabel";
import { ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import CtExpanded from "@/components/CtExpanded/CtExpanded.vue";
import AccordionMensagens from "./AccordionMensagens.vue";
import AccordionAnexos from "./AccordionAnexos.vue";
import SkeletonAccordionSolicitacao from "../SkeletonAccordionSolicitacao.vue";
import { formatDayPtBr } from "@/composables/useFormatDate";
/*
import {
  getDatasFerias,
  getPeriodosAquisitivos,
} from "@/src/state/graphql/ferias/tempoRealFerias";
*/
import { getSolicitacao } from "@/state/graphql/solicitacoes";
export default {
  components: {
    CtLabel,
    AccordionMensagens,
    AccordionAnexos,
    CtExpanded,
    SkeletonAccordionSolicitacao,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    solicitacaoId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const ferias = ref({
      idTrabalhador: "2aae9fcc-dd4b-4dca-977a-ef8e8c3caecc",
      qntDias: 10,
      diasAbono: 0,
      dtSaida: "2024-01-08",
      dtPagamento: "2024-01-07",
      dtRetorno: "2024-01-18",
    });

    const currentPeriodoAquisitivo = ref(null);
    const mensagens = ref([]);
    const anexos = ref([]);

    const accordionContent = ref(!!props.modelValue);
    const accordionMensagens = ref(!!props.modelValue);
    const accordionAnexos = ref(!!props.modelValue);

    const showConcuirSolicitacao = ref(false);
    const tipoConclusao = ref("APROVAR");
    const status = ref({
      displayName: "Reprovada",
      value: "REPROVADA",
    });
    /*
    const { load: loadDatas, onResult: onResultDatas } = useLazyQuery(
      getDatasFerias,
      () => ({
        idEmpregador: empregador.value.id,
        dtSaida: ferias.value.dtSaida,
        qntDias: ferias.value.qntDias,
      }),
      { fetchPolicy: "network-only" }
    );
    onResultDatas((result) => {
      if (result.data) {
        ferias.value.dtPagamento = result.data.datasFerias.dtPagamento;
        ferias.value.dtRetorno = result.data.datasFerias.dtRetorno;
      }
    });
    */

    const {
      loading: loadingSolicitacao,
      onResult: onResultSolicitacao,
      refetch: onRefetchSolicitacao,
    } = useQuery(
      getSolicitacao,
      () => ({
        solicitacaoId: props.solicitacaoId,
      }),
      { fetchPolicy: "network-only" }
    );

    onResultSolicitacao((result) => {
      if (!result.loading && result.data) {
        if (result.data.solicitacao?.objeto?.ferias) {
          const apiSolictacaoFerias = result.data.solicitacao.objeto.ferias;
          ferias.value.dtSaida = apiSolictacaoFerias.dtSaida;
          ferias.value.qntDias = apiSolictacaoFerias.quantidadeDias;
          ferias.value.diasAbono = apiSolictacaoFerias.quantidadeDiasAbono;
          mensagens.value = result.data.solicitacao.mensagens;
          status.value = result.data.solicitacao.status;
          anexos.value = result.data.solicitacao.anexos.map((item) => ({
            name: item,
            isLoading: false,
          }));
          // loadDatas();
        }
      }
    });

    const setLoadingAnexos = (indexAnexo, newValue) => {
      anexos.value[indexAnexo].isLoading = newValue;
    };

    /*

    const {
      onResult: onResultPeriodoAqusitivo,
      loading: loadingPeriodoAqusitivo,
    } = useQuery(
      getPeriodosAquisitivos,
      () => ({
        idEmpregador: empregador.value.id,
        idTrabalhador: props.trabalhador.id,
        dtAdmissao: props.trabalhador.dtAdmissao,
      }),
      { fetchPolicy: "network-only" }
    );

    onResultPeriodoAqusitivo((result) => {
      let indexPeriodoAtual = result.data.periodosAquisitivos.findIndex(
        (periodAqui) => {
          return formatToDateApi(periodAqui.dtFim) < new Date();
        }
      );
      if (indexPeriodoAtual !== -1) {
        currentPeriodoAquisitivo.value = {
          ...result.data.periodosAquisitivos[indexPeriodoAtual],
        };
        // form.qntDias = result.data.periodosAquisitivos[indexPeriodoAtual].diasPendentes
      } else {
        currentPeriodoAquisitivo.value = {
          ...result.data.periodosAquisitivos[0],
        };
        // form.qntDias = result.data.periodosAquisitivos[0].diasPendentes
      }
    });
    */

    return {
      formatDayPtBr,
      ferias,
      // loadingPeriodoAqusitivo,
      currentPeriodoAquisitivo,
      loadingSolicitacao,
      mensagens,
      showConcuirSolicitacao,
      tipoConclusao,
      status,
      onRefetchSolicitacao,
      anexos,
      accordionContent,
      setLoadingAnexos,
      accordionMensagens,
      accordionAnexos,
    };
  },
};
</script>
