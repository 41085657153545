<template>
  <div>
    <!--
    <PageTitle title="Demonstrativos" />
    -->

    <!-- https://vue3datepicker.com/api/props/#multicalendars -->

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <ul
              class="nav justify-content-start nav-tabs-custom rounded card-header-tabs"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#demos-folha"
                  role="tab"
                >
                  <span class="d-block d-sm-none">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.63 14.88L15 22.51L11.25 23.26L12 19.51L19.631 11.88C20.457 11.0541 21.796 11.0541 22.622 11.88L22.631 11.889C23.4566 12.7152 23.4562 14.0543 22.63 14.88Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.24902 10.51H14.249"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.24902 14.26H11.249"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.24902 18.01H8.24902"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.24902 23.26H2.24902C1.4206 23.26 0.749023 22.5884 0.749023 21.76V6.01001C0.749023 5.18158 1.4206 4.51001 2.24902 4.51001H6.00002C6.00002 2.43894 7.67896 0.76001 9.75002 0.76001C11.8211 0.76001 13.5 2.43894 13.5 4.51001H17.25C18.0785 4.51001 18.75 5.18158 18.75 6.01001V8.26001"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.74902 3.76001C9.95613 3.76001 10.124 3.9279 10.124 4.13501C10.124 4.34212 9.95613 4.51001 9.74902 4.51001C9.54192 4.51001 9.37402 4.34212 9.37402 4.13501C9.37376 4.03547 9.41318 3.93994 9.48357 3.86955C9.55395 3.79917 9.64949 3.75974 9.74902 3.76001"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <!-- <i class="fas fa-home"></i> -->
                  </span>
                  <span class="d-none d-sm-block">Folha Mensal</span>
                </a>
              </li>
              <li class="nav-item" @click="openTab(1)">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#demos-adiantamento"
                  role="tab"
                >
                  <span class="d-block d-sm-none">
                    <!-- <i class="far fa-id-card"></i> -->
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6.75H10"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 11.25H7.75"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 15.75H7.75"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.5 6.75H16"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.892 16.564C12.3312 17.1417 13.0249 17.4684 13.75 17.439C14.889 17.439 15.812 16.746 15.812 15.892C15.812 15.038 14.889 14.345 13.75 14.345C12.611 14.345 11.687 13.652 11.687 12.797C11.687 11.942 12.611 11.25 13.75 11.25C14.475 11.221 15.1686 11.5476 15.608 12.125"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.75 17.439V18.469"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.75 10.22V11.25"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19 0.75V23.25H1V0.75H19Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span class="d-none d-sm-block">Adiantamento</span>
                </a>
              </li>
              <li class="nav-item" @click="openTab(2)">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#demos-ferias"
                  role="tab"
                >
                  <span class="d-block d-sm-none">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.5 24.5L13.386 19.165C12.4651 18.2444 12.2367 16.8377 12.819 15.673V15.673C13.2541 14.8032 14.0808 14.1955 15.0409 14.0398C16.0009 13.884 16.9773 14.1993 17.665 14.887L18.5 15.722L19.335 14.887C20.0228 14.1993 20.9991 13.884 21.9592 14.0398C22.9192 14.1955 23.7459 14.8032 24.181 15.673V15.673C24.7633 16.8377 24.5349 18.2444 23.614 19.165L18.5 24.5Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 19.5H2.5C1.94772 19.5 1.5 19.0523 1.5 18.5V4.5C1.5 3.94772 1.94772 3.5 2.5 3.5H20.5C21.0523 3.5 21.5 3.94772 21.5 4.5V11"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.5 1.50101V6.50101"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.5 1.50101V6.50101"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.5 8.50101H21.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span class="d-none d-sm-block">Férias</span>
                </a>
              </li>
              <li class="nav-item" @click="openTab(3)">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#demos-decimo-terceiro"
                  role="tab"
                >
                  <span class="d-block d-sm-none">
                    <svg
                      aria-hidden="true"
                      style="
                        width: 18px;
                        height: 18px;
                        fill: transparent;
                        stroke: currentcolor;
                      "
                    >
                      <path
                        d="M13.5 10.5h-1.355a.895.895 0 00-.334 1.725l1.376.55a.895.895 0 01-.333 1.725H11.5m1-4V10m0 5v-.5m-9-9h7m-7 3h4m-4 3H6m0 3H2.5a1 1 0 01-1-1v-11a1 1 0 011-1h7.086a1 1 0 01.707.293l1.914 1.914a1 1 0 01.293.707V6.5m0 10a4 4 0 100-8 4 4 0 000 8z"
                      />
                    </svg>
                  </span>
                  <span class="d-none d-sm-block">13° Salário</span>
                </a>
              </li>
              <li class="nav-item" @click="openTab(4)">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#demos-rescisao"
                  role="tab"
                >
                  <span class="d-block d-sm-none">
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 24 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15 9.5C16.4497 9.5 17.625 8.32475 17.625 6.875C17.625 5.42525 16.4497 4.25 15 4.25C13.5503 4.25 12.375 5.42525 12.375 6.875C12.375 8.32475 13.5503 9.5 15 9.5Z"
                        stroke="currentColor"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 14.75C10.5 12.2647 12.5147 10.25 15 10.25C17.4853 10.25 19.5 12.2647 19.5 14.75"
                        stroke="currentColor"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.5 2.75C1.5 1.92157 2.17157 1.25 3 1.25H21C21.8284 1.25 22.5 1.92157 22.5 2.75V16.25C22.5 17.0784 21.8284 17.75 21 17.75H3C2.17157 17.75 1.5 17.0784 1.5 16.25V2.75Z"
                        stroke="currentColor"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 17.75V20.75"
                        stroke="currentColor"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.5 20.75H19.5"
                        stroke="currentColor"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.5 4.25H9V8.75H4.5V4.25Z"
                        stroke="currentColor"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span class="d-none d-sm-block">Rescisão</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <!--
            <Datepicker
              v-model="month"
              style="max-width: 300px"
              :autoApply="true"
              :enableTimePicker="false"
              locale="pt-BR"
              placeholder="Selecione o Mês"
              :monthPicker="true"
              :format-locale="ptBR"
              :clearable="false"
            />
            -->
            <!-- format="MM/yyyy" -->
            <div class="tab-content text-muted">
              <div class="tab-pane active" id="demos-folha" role="tabpanel">
                <FolhaDisplayView v-model="statusTabs[0]" />
              </div>
              <div class="tab-pane" id="demos-adiantamento" role="tabpanel">
                <AdiantamentoDisplayView v-model="statusTabs[1]" />
              </div>
              <div class="tab-pane" id="demos-ferias" role="tabpanel">
                <FeriasDisplayView v-model="statusTabs[2]" />
              </div>
              <div class="tab-pane" id="demos-decimo-terceiro" role="tabpanel">
                <DecimoTerceiroDisplayView v-model="statusTabs[3]" />
              </div>
              <div class="tab-pane" id="demos-rescisao" role="tabpanel">
                <RescisaoDisplayView v-model="statusTabs[4]" />
              </div>
            </div>
            <!-- end accordion -->
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
  </div>
</template>
<script>
// import Datepicker from "@vuepic/vue-datepicker";
import { ptBR } from "date-fns/locale";
import { computed, ref } from "vue";
import { formatMonthAndYearPtBr } from "@/composables/useFormatDate";
import { capitalizeFirstLetter } from "@/composables/useCapitalizeFirstLetter";
import FolhaDisplayView from "./FolhaDisplayView";
import AdiantamentoDisplayView from "./AdiantamentoDisplayView";
import FeriasDisplayView from "./FeriasDisplayView";
import DecimoTerceiroDisplayView from "./DecimoTerceiroDisplayView";
import RescisaoDisplayView from "./RescisaoDisplayView";

export default {
  name: "DemonstrativosView",
  components: {
    FolhaDisplayView,
    AdiantamentoDisplayView,
    FeriasDisplayView,
    DecimoTerceiroDisplayView,
    RescisaoDisplayView,
  },
  setup() {
    const statusTabs = ref([true, false, false, false, false]);
    const month = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });
    const textDate = computed(() =>
      capitalizeFirstLetter(
        formatMonthAndYearPtBr(
          Number(
            month.value.year + (month.value.month + 1).toString().padStart(2, 0)
          )
        )
      ).replace(" - ", "/")
    );

    const openTab = (indexTab) => {
      statusTabs.value[indexTab] = true;
    };

    return {
      ptBR,
      textDate,
      month,
      openTab,
      statusTabs,
    };
  },
};
</script>

<style lang="postcss">
.accordion-min-width {
  min-width: 850px;
}
.blue-color {
  color: #0d6efd;
}
.ct-scrollbar-thin-horizontal {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 #f8f8f8;
  margin-right: 3px;
  border-style: none;
  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cbd5e0;
    border: 2px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
  }
}
</style>
