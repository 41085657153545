import { useToast, TYPE } from "vue-toastification";
import Toast from "@/components/Toast/Toast.vue";

const toast = useToast();

// const toast = useToast();
export const successToast = (title, msg) => {
  toast(
    {
      component: Toast,
      props: {
        title: title ? title : "Solicitação criada com sucesso!",
        text: msg
          ? msg
          : "O Solicitação foi enviada para o seu Gestor, acompanhe o status na listagem de solicitações.",
      },
    },
    { type: TYPE.SUCCESS }
  );
};

export const errorToast = (title, text) => {
  toast(
    {
      component: Toast,
      props: {
        title: title,
        text: text,
      },
    },
    { type: TYPE.ERROR }
  );
};
