<template>
  <div>
    <div class="placeholder-glow mt-4">
      <div class="mb-5">
        <i class="fas fa-chevron-right me-3"></i>
        <span class="placeholder col-2"></span>
      </div>
      <div class="mb-5">
        <i class="fas fa-chevron-right me-3"></i>
        <span class="placeholder col-2"></span>
      </div>
      <div>
        <i class="fas fa-chevron-right me-3"></i>
        <span class="placeholder col-2"></span>
      </div>
    </div>
  </div>
</template>
