<template>
  <CtExpanded v-model="accordion" title="Mensagens">
    <template #content>
      <div class="row">
        <div v-if="mensagens.length > 0" class="pb-0 col-6 col-md-12 col-xxl-6">
          <div
            v-for="(mensagem, index) in mensagens"
            :key="index"
            class="d-flex flex-column"
          >
            <div
              v-if="
                mensagem.origem.value === 'GESTOR' ||
                mensagem.origem.value === 'EMPREGADOR'
              "
              class="w-full d-flex justify-content-start"
            >
              <div class="d-flex align-items-start mb-3 me-10">
                <div
                  class="d-flex flex-column p-3 bg-gray-light rounded-msn-trabalhador"
                >
                  <div class="d-flex align-items-center">
                    <span class="text-sm text-gray-900"><b>Gestor</b></span>
                    <span class="ps-1 text-sm font-normal text-gray-600">{{
                      formatDateWithHours(mensagem.data)
                    }}</span>
                  </div>
                  <span class="text-sm font-normal text-gray-900">
                    <i
                      v-if="
                        mensagem.mensagem === '' || mensagem.mensagem === null
                      "
                      >Sem Mensagem</i
                    >
                    <span v-else>{{ mensagem.mensagem }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="w-full d-flex justify-content-end">
              <div class="d-flex align-items-start ms-10 mb-5">
                <div
                  class="d-flex flex-column leading-1.5 p-4 border-gray-200 bg-primary rounded-msn-gestor"
                >
                  <div class="d-flex align-items-center">
                    <span class="text-sm text-white"><b>Trabalhador</b></span>
                    <span
                      class="ps-1 text-sm font-normal text-white opacity-75"
                      >{{ formatDateWithHours(mensagem.data) }}</span
                    >
                  </div>
                  <span class="text-sm font-normal text-white">
                    <i
                      v-if="
                        mensagem.mensagem === '' || mensagem.mensagem === null
                      "
                      >Sem Mensagem</i
                    >
                    <span v-else>{{ mensagem.mensagem }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>Nenhuma mensagem disponível</div>
      </div>
    </template>
  </CtExpanded>
</template>
<script>
import { ref } from "vue";
import CtExpanded from "@/components/CtExpanded/CtExpanded.vue";
import { formatDateWithHours } from "@/composables/useFormatDate";

export default {
  components: {
    CtExpanded,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    mensagens: {
      type: Array,
      default: () => [],
    },
  },
  setup(props /*, { emit }*/) {
    const accordion = ref(!!props.modelValue);

    return {
      accordion,
      formatDateWithHours,
    };
  },
};
</script>
<style lang="scss">
.rounded-msn-gestor {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.rounded-msn-trabalhador {
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>
