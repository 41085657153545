<template>
  <div>
    <!--
    <PageTitle title="Dashboard" />

    -->
  </div>
</template>
<script>
// import { useQuery } from "@vue/apollo-composable";
// import { getInfoPessoal } from "@/state/graphql/trabalhador";

export default {
  name: "Dashboard",
  setup() {
    // const { result: resultInfoPessoal } = useQuery(getInfoPessoal);

    return {};
  },
  mounted() {},
};
</script>

<style lang="postcss"></style>
