<template>
  <div>
    <div class="my-5 pt-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mb-5 pt-5">
              <div style="margin-bottom: 5rem">
                <div class="navbar-brand-box" v-if="getUrlLogoEmpregador">
                  <router-link :to="{ name: 'dashboard' }" class="logo">
                    <span class="logo-sm">
                      <img :src="getUrlLogoEmpregador" alt="" />
                    </span>
                    <span class="logo-lg">
                      <img :src="getUrlLogoEmpregador" alt="" />
                    </span>
                  </router-link>
                </div>
                <div v-else>
                  <b class="flex" :style="`color: ${getTextMenuColor};`">{{
                    getNomeEmpregador
                  }}</b>
                </div>
              </div>

              <h1
                :style="`
                background: repeating-linear-gradient(45deg,rgba(${getColorPrimaria},0.4),rgba(${getColorPrimaria},0.4) 20px, ${getBGMenuColor} 40px, ${getBGMenuColor} 10px);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;`"
                class="error-title mt-5"
              >
                <span>404!</span>
              </h1>
              <h4 class="text-uppercase mt-5">Página não encontrada!</h4>
              <p class="font-size-15 mx-auto text-muted w-50 mt-4">
                A página que você está procurando não foi encontrada. Volte para
                a página anterior ou visite nossa Central de Ajuda para mais
                informações
              </p>
              <div class="mt-5 text-center">
                <button
                  @click="goBack"
                  :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor}; color: ${getTextMenuColor};`"
                  class="btn btn-primary waves-effect waves-light"
                >
                  Voltar para o Início
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getBGMenuColor,
  getTextMenuColor,
  getUrlLogoEmpregador,
  getColorSecondary,
  getColorPrimaria,
  getNomeEmpregador,
} from "@/state/modules/themeColor.store";

export default {
  setup() {
    return {
      getBGMenuColor,
      getTextMenuColor,
      getUrlLogoEmpregador,
      getColorPrimaria,
      getColorSecondary,
      getNomeEmpregador,
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style>
.error-title {
  text-transform: uppercase;

  font-size: 12rem;
  line-height: 0.8;
  position: relative;
}
</style>
