import { computed, reactive } from "vue";

export const state = reactive({
  bgMenuColor: window.localStorage.getItem("bgMenuColor")
    ? window.localStorage.getItem("bgMenuColor")
    : "#005cff",
  textMenuColor: window.localStorage.getItem("textMenuColor")
    ? window.localStorage.getItem("textMenuColor")
    : "#FFF",
  colorPrimaria: window.localStorage.getItem("colorPrimaria")
    ? window.localStorage.getItem("colorPrimaria")
    : "0, 92, 255", // 0, 92, 255
  colorSecondary: window.localStorage.getItem("colorSecondary")
    ? window.localStorage.getItem("colorSecondary")
    : "255, 255, 255", // #FFF
  urlLogoEmpregador: window.localStorage.getItem("urlLogoEmpregador")
    ? window.localStorage.getItem("urlLogoEmpregador")
    : null,
  nomeEmpregador: window.localStorage.getItem("nomeEmpregador")
    ? window.localStorage.getItem("nomeEmpregador")
    : "",
});

// export const themeColor = ref(
//   localStorage.getItem("themeColor")
//     ? localStorage.getItem("themeColor")
//     : "#000"
// );

export function removeLocalStorage() {
  window.localStorage.removeItem("urlLogoEmpregador");
  window.localStorage.removeItem("nomeEmpregador");
  window.localStorage.removeItem("bgMenuColor");
  window.localStorage.removeItem("textMenuColor");
  window.localStorage.removeItem("colorPrimaria");
  window.localStorage.removeItem("colorSecondary");
}

export function setUrlLogoEmpregador(urlLogo) {
  state.urlLogoEmpregador = urlLogo;
  window.localStorage.setItem("urlLogoEmpregador", urlLogo);
}
export function setNomeEmpregador(nomeEmpregador) {
  state.nomeEmpregador = nomeEmpregador;
  window.localStorage.setItem("nomeEmpregador", nomeEmpregador);
}

export function setBGMenuColor(color) {
  state.bgMenuColor = color;
  window.localStorage.setItem("bgMenuColor", color);
}
export function setTextMenuColor(color) {
  state.textMenuColor = color;
  window.localStorage.setItem("textMenuColor", color);
}
export function setColorPrimaria(color) {
  state.colorPrimaria = color;
  localStorage.setItem("colorPrimaria", color);
}

export function setColorSecondary(color) {
  state.colorSecondary = color;
  window.localStorage.setItem("colorSecondary", color);
}

export const getBGMenuColor = computed(() => state.bgMenuColor);
export const getTextMenuColor = computed(() => state.textMenuColor);
export const getColorPrimaria = computed(() => state.colorPrimaria);
export const getColorSecondary = computed(() => state.colorSecondary);
export const getUrlLogoEmpregador = computed(() => state.urlLogoEmpregador);
export const getNomeEmpregador = computed(() => state.nomeEmpregador);
