<template>
  <label class="ct-label inline-block text-gray-800 font-semibold text-md">
    {{ text }}
    <span v-if="requiredField" class="text-gray-600" style="margin-left: -2px"
      >*</span
    >
  </label>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    requiredField: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
