<template>
  <div>
    <div class="mb-6">
      <label class="form-label">Anexos</label>
      <div class="row">
        <div class="col-12">
          <div class="upload-anexos d-flex">
            <div class="text-center w-100 d-flex">
              <div class="drop-content">
                <div class="mb-3">
                  <i class="mdi mdi-cloud-download-outline mdi-48px"></i>
                </div>
                <h5 class="fw-normal">Solte os arquivos aqui para enviar ou</h5>
                <file-upload
                  class="cursor-pointer"
                  :extensions="extensions"
                  :accept="accept"
                  :multiple="true"
                  :size="size || 0"
                  :drop="drop"
                  :drop-directory="true"
                  :add-index="false"
                  v-model="files"
                  @update:model-value="onChange"
                  @input-filter="inputFilter"
                  ref="upload"
                >
                  <h5 class="fw-bold link">Selecione os Arquivos</h5>
                </file-upload>

                <p class="text-md text-gray-600 mt-2">Tamanho Máximo: 10MB</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 overflow-auto">
          <!-- <button
                type="submit"
                class="btn btn-primary waves-effect waves-light"
              >
                <i
                  class="bx bx-loader bx-spin font-size-16 align-middle me-2"
                ></i>
                Salvar
              </button> -->
          <div
            v-if="files.length"
            class="table-responsive border border-secondary rounded-1"
            style="height: 200px"
          >
            <table class="table mb-0 align-middle">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Tamanho</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(file, indexFile) in files"
                  :key="`anexo_${indexFile}`"
                >
                  <th scope="row">{{ file.name }}</th>
                  <td>{{ formatBytes(file.size) }}</td>
                  <td>
                    <div class="d-flex flex-nowrap">
                      <button
                        @click="removeFile(indexFile)"
                        type="button"
                        title="Excluir Arquivo"
                        class="btn btn-primary waves-effect waves-light"
                      >
                        <i
                          class="mdi mdi-trash-can font-size-16 align-middle"
                        ></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { errorMsg } from "@/state/modules/error.store";
import FileUpload from "vue-upload-component";
import { errorToast } from "@/composables/useToast";
// import {  } from "@/state/graphql/solicitacoes";

export default {
  components: {
    FileUpload,
  },
  setup(_, { emit }) {
    const files = ref([]);
    const upload = ref("");
    const extensions = "pdf,rar,csv,xlsx,txt,gif,jpg,jpeg,png";
    const accept =
      "image/png, image/jpg, image/jpeg, image/png, text/html, text/plain, text/csv, application/pdf, application/zip, application/x-7z-compressed, application/x-rar-compressed, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/msword";
    const filesPermitted = ref(
      /\.(gif|jpg|jpeg|png|pdf|txt|csv|zip|rar|xlsx|docx|doc)$/i
    );
    const drop = ref(true);
    const size = ref(1024 * 1024 * 10);
    // const toast = useToast();

    const inputFilter = (newFile, oldFile, prevent) => {
      if (newFile && !oldFile) {
        if (newFile.size >= 10000000) {
          errorToast(
            "Não foi possível salvar",
            "O tamanho máximo permitido é de 10 MB."
          );
          return prevent();
        }
        if (!filesPermitted.value.test(newFile.name)) {
          errorToast(
            "Não foi possível salvar",
            "Essa extensão de arquivo não é permitido."
          );
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    };

    const onChange = () => {
      emit("receiveFile", files.value);
    };

    const removeFile = (currentIndex) => {
      files.value.splice(currentIndex, 1);
      emit("receiveFile", files.value);

      // root.$ctoast.success({ title: 'Solicitação Concluída', message: 'Anexo Removido' })
    };

    return {
      files,
      onChange,
      errorMsg,
      upload,
      extensions,
      accept,
      filesPermitted,
      drop,
      removeFile,
      inputFilter,
      size,
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
};
</script>
