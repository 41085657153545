import { reactive, computed } from "vue";
import { getCookie, saveCookie, removeCookie } from "@/utils/cookies";

const state = reactive({
  currentProcessamentos: getCookie("processamento_demonstrativos")
    ? JSON.parse(getCookie("processamento_demonstrativos"))
    : [],
});

export const getters = {
  currentProcessamentos: computed(() => state.currentProcessamentos),
};

export const mutations = {
  setCurrentProcessamento: (newCurrentProcessamento) => {
    state.currentProcessamentos = [
      ...state.currentProcessamentos,
      newCurrentProcessamento,
    ];
    saveCookie(
      "processamento_demonstrativos",
      JSON.stringify(state.currentProcessamentos)
    );
  },
  removeCurrentProcessamento: (currentProcessamentoId) => {
    if (state.currentProcessamentos.length === 1) {
      state.currentProcessamentos = [];
      removeCookie("processamento_demonstrativos");
    } else if (state.currentProcessamentos.length > 1) {
      state.currentProcessamentos = [
        ...state.currentProcessamentos.filter(
          (item) => item.id !== currentProcessamentoId
        ),
      ];
    }
  },
};

export default {
  state,
  getters,
  mutations,
};
