<template>
  <div v-if="formData">
    <div class="mb-6 col-lg-4 relative">
      <label for="example-text-input" class="form-label ferias-dt"
        >Data de Saída Férias</label
      >
      <Datepicker
        v-if="ferias"
        v-model="ferias.dtSaida"
        menuClassName="mt-8"
        :autoApply="true"
        :auto-position="false"
        position="left"
        :textInput="true"
        :alt-position="() => ({ top: 0, left: 0 })"
        :teleport="false"
        :enableTimePicker="false"
        format="dd/MM/yyyy"
        :disabled="false"
        locale="pt-BR"
        placeholder="Data de Saída"
        :format-locale="ptBR"
        @closed="v$.ferias.dtSaida.$touch"
      />
      <div v-show="v$.ferias.dtSaida.$error" class="error-text">
        O Campo <b>Data de Saída</b> é de preenchimento obrigatório.
      </div>
    </div>
    <div class="mb-6 col-lg-4">
      <label class="form-label d-flex">
        <span> Quantidade de Dias* </span>
        <VDropdown
          :distance="5"
          :skidding="0"
          placement="top"
          popperClass="ct-popper"
        >
          <button
            tabindex="-1"
            style="
              border: 0;
              background-color: transparent;
              padding: 0;
              margin-left: 0.4rem;
            "
          >
            <i class="bx bx-help-circle"></i>
          </button>

          <template #popper>
            <div>
              <p style="margin-bottom: 0">Dias a serem gozados.</p>
            </div>
          </template>
        </VDropdown>
      </label>
      <input
        :class="
          v$.ferias.quantidadeDias.$error
            ? 'form-control is-invalid'
            : 'form-control'
        "
        tabindex="0"
        type="number"
        placeholder="Informe a Quantidade de Dias"
        v-model="ferias.quantidadeDias"
        @blur="v$.ferias.quantidadeDias.$touch"
      />
      <div v-show="v$.ferias.quantidadeDias.$error" class="error-text">
        O Campo <b>Quantidade de Dias</b> é de preenchimento obrigatório.
      </div>
      <div
        v-show="v$.ferias.quantidadeDias.validPositiveValue.$invalid"
        class="error-text"
      >
        O Campo <b>Quantidade de Dias</b> deve ser maior que 0
      </div>
    </div>
    <div class="mb-6 col-lg-4">
      <label class="form-label d-flex">
        <span> Dias de Abono </span>
        <VDropdown
          :distance="5"
          :skidding="0"
          placement="top"
          popperClass="ct-popper"
        >
          <button
            tabindex="-1"
            style="
              border: 0;
              background-color: transparent;
              padding: 0;
              margin-left: 0.4rem;
            "
          >
            <i class="bx bx-help-circle"></i>
          </button>

          <template #popper>
            <div>
              <p style="margin-bottom: 0">
                Deseja vender alguns dias das férias?
              </p>
            </div>
          </template>
        </VDropdown>
      </label>
      <input
        class="form-control"
        type="number"
        tabindex="0"
        placeholder="Informe a Quantidade de Dias Abonados"
        v-model="ferias.quantidadeDiasAbono"
      />
    </div>
    <!-- <div>
      <input type="text" placeholder="Apenas Testing" v-model="ferias.testing" />
    </div> -->
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { ptBR } from "date-fns/locale";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// import { formatDay } from "@/composables/useFormatDate";
import { toRefs } from "vue";
import { objectFerias } from "@/state/modules/solicitacao.store";
import "@vuepic/vue-datepicker/dist/main.css";
// import { Dropdown, } from "floating-vue";
// import "floating-vue/dist/style.css";

export default {
  components: {
    Datepicker,
    // Dropdown,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({ ...objectFerias }),
    },
  },

  setup(props) {
    const { formData: ferias } = toRefs(props);

    return {
      ptBR,
      ferias,
      v$: useVuelidate(),
    };
  },

  validations() {
    return {
      ferias: {
        dtSaida: {
          required,
        },
        quantidadeDias: {
          required,
          validPositiveValue: function (value) {
            return Number(value) > 0;
          },
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/custom/plugins/datepicker/main.scss";
</style>
