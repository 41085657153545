<template>
  <div>
    <div v-if="isLoading"></div>

    <div class="callback-page" v-else>
      <header
        id="page-topbar"
        :style="`background-color: #005cff; border-color: #005cff;`"
      >
        <div class="navbar-header">
          <div class="d-flex">
            <!-- LOGO -->
            <!-- <div class="text-white" style="width: 35px; height: 35px">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 90 90"
              >
                <g clip-path="url(#a)">
                  <path
                    fill="currentColor"
                    d="M90 0v90H0V55.054h55.053V0H90Z"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="currentColor" d="M0 0h90v90H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div> -->
          </div>
          <div class="d-flex">
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="header-item"
                title="Sair da Conta"
                :style="`color: ${getTextMenuColor};`"
                @click="openModalLogout"
              >
                <i class="mdi mdi-24px mdi-account-arrow-right"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <router-link class="dropdown-item" to="/trabalhador">
                  <i
                    class="mdi mdi-face-profile mdi-24px align-middle me-1"
                  ></i>
                  Perfil
                </router-link>

                <div class="dropdown-divider"></div>

                <button class="dropdown-item" @click="onLogout">
                  <i class="mdi mdi-logout font-size-16 align-middle me-1"></i>
                  Sair
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="container">
        <div class="row">
          <div class="header mt-8">
            <!-- <img
            class="mt-10"
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTYiIGhlaWdodD0iOTYiIHZpZXdCb3g9IjAgMCA5NiA5NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9Ijk2IiBoZWlnaHQ9Ijk2IiByeD0iNDgiIGZpbGw9IiMwMDVDRkYiLz4KPHBhdGggZD0iTTc3LjQxOTQgMTguNTgwN1Y3Ny40MTk0SDE4LjU4MDdWNTQuNTczMkg1NC41NzI3VjE4LjU4MDdINzcuNDE5NFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo="
          /> -->
            <h2
              class="text-center mt-8"
              v-if="
                query.error_description ===
                'Por favor confirme a criação da sua conta através do e-mail que enviamos para sua conta'
              "
            >
              Email de verificação enviado!
            </h2>

            <div class="col-6 mt-8 mb-5">
              <p>
                {{ query.error_description }}
              </p>
            </div>

            <router-link
              v-if="
                query.error_description ===
                'Por favor confirme a criação da sua conta através do e-mail que enviamos para sua conta'
              "
              class="btn mt-2"
              to="/"
              style="
                color: #fff;
                background-color: #005cff;
                border-color: #005cff;
              "
            >
              Já Confirmei o E-mail
            </router-link>

            <button
              :style="`background-color: #005cff; border-color: #005cff; color: #ffffff;`"
              class="btn btn-primary waves-effect waves-light mt-2"
              @click="onLogout"
            >
              Ir para Tela de Login
            </button>
          </div>
        </div>
      </div>

      <div
        id="modalLogout"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
        data-bs-scroll="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Sair da Conta</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">Você deseja sair desta conta?</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary waves-effect"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor}; color: ${getTextMenuColor};`"
                class="btn btn-primary waves-effect waves-light"
                @click="onLogout"
              >
                Sair
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import {
  getBGMenuColor,
  getTextMenuColor,
  removeLocalStorage,
} from "@/state/modules/themeColor.store";
import { removeCookie } from "@/utils/cookies";
// import { useAuth0 } from "@auth0/auth0-vue";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  setup() {
    const route = useRoute();

    return { query: route.query, getBGMenuColor, getTextMenuColor };
  },
  methods: {
    openModalLogout() {
      $("#modalLogout").modal("show");
    },
    onLogout() {
      removeLocalStorage();
      removeCookie("authToken");
      this.$auth0.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 950);
  },
};
</script>

<style lang="scss">
.callback-page {
  background-color: #f4f5f8;
}
.callback-page .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-bottom: 50px;
  padding-top: 50px;
}
.callback-page .header img {
  width: 96px;
}
.callback-page .header p {
  font-size: 1.1rem;
}
</style>
