<template>
  <div
    id="modalUploadFotoTrabalhador"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    data-bs-scroll="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel">Subir Imagem</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="refUpload.clear"
          ></button>
        </div>
        <div class="modal-body">
          <div class="upload-logo">
            <div v-show="!edit" class="avatar-upload">
              <div class="text-center p-2">
                <label for="avatar">
                  <!-- <img
                  v-if="files.length"
                  :src="files.length ? files[0].url : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"
                > -->
                  <div
                    class="drop-content"
                    :class="{
                      'drop-active': refUpload && refUpload.dropActive,
                    }"
                  >
                    <h5 class="fw-normal">Solte o arquivo para o upload</h5>
                  </div>
                </label>
              </div>

              <div class="text-center p-2">
                <file-upload
                  ref="refUpload"
                  v-model="files"
                  extensions="gif,jpg,jpeg,png,webp"
                  accept="image/png,image/gif,image/jpeg,image/webp"
                  name="Logo Empregador"
                  :drop="!edit"
                  @input-filter="inputFilter"
                  @input-file="inputFile"
                  :multiple="false"
                >
                  <h5 class="fw-normal">ou</h5>
                  <h5 class="fw-normal link">Selecione</h5>
                </file-upload>
              </div>
            </div>
          </div>
          <div v-if="files.length && edit" class="avatar-edit">
            <div
              class="d-flex col-reverse md:row items-center md:items-start justify-between"
            >
              <div
                v-if="files.length"
                class="avatar-edit-image position-relative"
              >
                <img ref="editImage" :src="files[0].url" />
                <div
                  class="actions-avatar-upload d-flex align-items-center justify-content-center position-absolute w-100 rounded-default mt-2 px-2 bottom-0 left-0"
                >
                  <a title="Resetar Recorte" class="p-2 m-1" @click="reset">
                    <i class="fas fa-sync-alt text-white"></i>
                    <!--<ct-icon name="refresh" class="text-white" size="18" />-->
                  </a>
                  <a title="Zoom In" class="p-2 m-1" @click="zoomIn">
                    <i class="fas fa-search-plus text-white"></i>
                    <!--
                    <ct-icon name="zoom-in" class="text-white" size="18" />
                    -->
                  </a>
                  <a title="Zoom Out" class="p-2 m-1" @click="zoomOut">
                    <i class="fas fa-search-minus text-white"></i>
                    <!--
                    <ct-icon
                      name="zoom-out"
                      class="text-white"
                      size="18"
                      fill="transparent"
                      stroke="currentColor"
                    />
                    -->
                  </a>
                  <a
                    title="Rotacionar Para Esquerda"
                    class="p-2 m-1"
                    @click="rotateLeft"
                  >
                    <i class="fas fa-redo text-white"></i>
                    <!--
                    <ct-icon name="rotate-left" class="text-white" size="18" />
                    -->
                  </a>
                  <a
                    title="Rotacionar Para Direita"
                    class="p-2 m-1"
                    @click="rotateRight"
                  >
                    <i class="fas fa-undo text-white"></i>
                    <!--
                    <ct-icon name="rotate-right" class="text-white" size="18" />
                    -->
                  </a>
                </div>
              </div>
              <section class="preview-area mb-5 md:mb-0">
                <div class="preview-avatar" />
                <p class="text-gray-700">Pré Visualização</p>
              </section>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary waves-effect"
            data-bs-dismiss="modal"
            @click.prevent="refUpload.clear"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            @click="editSave"
          >
            <i
              class="bx bx-loader bx-spin font-size-16 align-middle me-2"
              v-if="isWorking"
            ></i>
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import { ref } from "vue";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import FileUpload from "vue-upload-component";
import Compressor from "compressorjs";
import { useToast, TYPE } from "vue-toastification";
import Toast from "@/components/Toast/Toast.vue";
import { getUrlFotoUpload } from "@/state/graphql/trabalhador";
import { apolloClient } from "@/state/client";
//import { useMutation } from "@vue/apollo-composable";
//import { uploadFotoTrabalhador } from "@/state/graphql/trabalhador";
import { errorMsg } from "@/state/modules/error.store";

export default {
  components: {
    FileUpload,
  },
  props: {
    idTrabalhador: {
      type: String,
    },
    onRefetchTrabalhador: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const edit = ref(false);
    const files = ref([]);
    const cropper = ref(false);
    const isWorking = ref(false);
    const refUpload = ref(null);
    const currentUrlUpload = ref(null);

    const toast = useToast();

    const successToast = () => {
      toast(
        {
          component: Toast,
          props: {
            title: "Solicitação Concluída",
            text: "A sua foto foi salva com sucesso!",
          },
        },
        { type: TYPE.SUCCESS }
      );
    };

    const errorToast = () => {
      toast(
        {
          component: Toast,
          props: {
            title: "Não foi possível salvar",
            text: errorMsg.value,
          },
        },
        { type: TYPE.ERROR }
      );
    };

    const handleImageType = (typeImg) => {
      const regex = /image[^\w\s]/i;
      return typeImg.replace(regex, "");
    };

    async function getTrabalhadorFotoUrl(imageType) {
      await apolloClient
        .query({
          query: getUrlFotoUpload,
          variables: {
            imageType: handleImageType(imageType),
          },
          fetchPolicy: "network-only",
        })
        .then((resp) => {
          currentUrlUpload.value = resp.data.trabalhadorFotoUploadUrl;
        })
        .catch(() => {
          errorToast();
        });
    }

    async function putToS3(fileObject, presignedUrl) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": fileObject.type,
        },
        body: fileObject,
      };
      const response = await fetch(presignedUrl, requestOptions);
      successToast();
      return await response;
    }

    const uploadPhoto = async (file) => {
      isWorking.value = true;
      try {
        await getTrabalhadorFotoUrl(file.type);
        if (currentUrlUpload.value) {
          await putToS3(file, currentUrlUpload.value);
        }
        //props.refetchTrabalhador()
        //refetchTrabalhadores()
        props.onRefetchTrabalhador();
      } finally {
        isWorking.value = false;
      }
    };

    const compressFile = async (file) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: 0.8,
        width: 180,
        height: 180,
        async success(result) {
          await uploadPhoto(result);
          $("#modalUploadFotoTrabalhador").modal("hide");
        },
        error(err) {
          console.error(err);
        },
      });
    };

    return {
      edit,
      files,
      cropper,
      isWorking,
      refUpload,
      toast,
      compressFile,
    };
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return;
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1,
            viewMode: 0,
            preview: ".preview-avatar",
          });
          this.cropper = cropper;
        });
      } else {
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    },
  },
  methods: {
    async editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(
        this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      this.$refs.refUpload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true,
      });
      await this.compressFile(file);
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          alert("Sua escolha não é uma imagem");
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    reset() {
      this.cropper.reset();
    },
    zoomIn() {
      this.cropper.zoom(0.1);
    },
    zoomOut() {
      this.cropper.zoom(-0.1);
    },
    rotateLeft() {
      this.cropper.rotate(90);
    },
    rotateRight() {
      this.cropper.rotate(-90);
    },
  },
};
</script>
<style lang="scss">
.avatar-edit-image {
  width: 300px;
  height: 350px;
  max-width: 100%;
}
.upload-logo .drop-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  margin: auto;
  width: 200px;
  height: 200px;
  color: #4a5568;
}
.upload-logo .drop-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-width: 2px;
  border-style: dashed;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  border-image: initial;
  border-color: #4a5568;
  transition: border-color 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.upload-logo .drop-active {
  z-index: 50;
  &::after {
    animation: 8s linear 0s infinite normal none running rotation;
  }
}
.upload-logo .link {
  color: #0d6efd;
}
.preview-area {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preview-avatar {
  border-width: 1px;
  display: inline-block;
  border-radius: 9999px;
  overflow: hidden;
  width: 95px;
  height: 95px;
  padding: 0;
  img {
    object-fit: cover;
    object-position: 50% 50%;
  }
}
.actions-avatar-upload {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
