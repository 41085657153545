<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-6 col-lg-4">
              <label class="form-label">Tipo de Solicitação</label>
              <Multiselect
                v-model="form.tipo"
                trackBy="value"
                :classes="{
                  container: v$.form.tipo.$error
                    ? 'multiselect input-is-invalid'
                    : 'multiselect',
                }"
                @close="v$.form.tipo.$touch"
                @select="onSelectTipoSolicitacao"
                :searchable="false"
                placeholder="Selecione o Tipo de Solicitação"
                :canClear="false"
                label="displayName"
                :options="tiposSolicitacoes"
              />
              <div v-show="v$.form.tipo.$error" class="error-text">
                O campo <b>Tipo de Solicitação</b> é de preenchimento
                obrigatório.
              </div>
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">Mensagem*</label>
              <textarea
                :class="
                  v$.form.mensagem.$error
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                placeholder="Mensagem"
                v-model="form.mensagem"
                @blur="v$.form.mensagem.$touch"
              >
              </textarea>
              <div v-show="v$.form.mensagem.$error" class="error-text">
                O Campo <b>Mensagem</b> é de preenchimento obrigatório.
              </div>
            </div>
            <FormFaltas v-if="form.tipo === 'FALTA'" :formData="formFalta" />
            <FormInfoPessoal
              :formData="formInfoPessoal"
              v-if="
                form.tipo === 'ALTERACAO_INFO_PESSOAL' ||
                form.tipo === 'INFO_PESSOAL'
              "
            />
            <FormFerias v-if="form.tipo === 'FERIAS'" :formData="formFerias" />
            <FormAdiantamentos
              v-if="form.tipo === 'ADIANTAMENTO'"
              :formData="formAdiantamentos"
            />

            <!-- <div v-if="form.tipo === 'OUTRO'">Outros</div> -->
            <div class="col-lg-6">
              <FormAnexos @receiveFile="setAnexos" />
            </div>

            <div class="form-group mt-7">
              <button
                type="submit"
                class="btn btn-primary waves-effect waves-light"
                @click="saveSolicitacao"
              >
                <i
                  class="bx bx-loader bx-spin font-size-16 align-middle me-2"
                  v-if="saving"
                ></i>
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
// import { formatDayPtBr } from "@/composables/useFormatDate";
import Multiselect from "@vueform/multiselect";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import FormFaltas from "./components/forms/FormFaltas.vue";
import { formatDay } from "@/composables/useFormatDate";
import { useMutation } from "@vue/apollo-composable";
import {
  mutationCreateSolicitacao,
  getSolicitacaoAnexoUrl /*
  mutationEditSolicitacao*/,
} from "@/state/graphql/solicitacoes";
import Toast from "@/components/Toast/Toast.vue";
import { useToast, TYPE } from "vue-toastification";
import { errorMsg } from "@/state/modules/error.store";
import FormAnexos from "./components/forms/FormAnexos.vue";
import FormInfoPessoal from "./components/forms/FormInfoPessoal.vue";
import FormFerias from "./components/forms/FormFerias.vue";
import FormAdiantamentos from "./components/forms/FormAdiantamentos.vue";
import { apolloClient } from "@/state/client";
import { useGetMoney } from "@/composables/useFormatMoney";
import { useRoute } from "vue-router";

export default {
  name: "NovaSolicitacao",

  components: {
    Multiselect,
    FormInfoPessoal,
    FormFaltas,
    FormAnexos,
    FormFerias,
    FormAdiantamentos,
  },
  setup() {
    const saving = ref(false);
    const toast = useToast();
    const form = reactive({
      tipo: null,
      mensagem: null,
      anexos: [],
      origem: "TRABALHADOR",
    });

    const formFalta = reactive({
      data: null,
    });
    const formInfoPessoal = reactive({
      paisNascimento: null,
    });
    const formFerias = reactive({
      dtSaida: null,
      quantidadeDias: null,
      quantidadeDiasAbono: null,
    });
    const formAdiantamentos = reactive({
      valor: null,
    });
    const anexos = ref([]);

    const setAnexos = (files) => {
      anexos.value = files;
    };

    let tiposSolicitacoes = [
      // {
      //   displayName: "Informações pessoais",
      //   value: "INFO_PESSOAL",
      // },
      // {
      //   displayName: "Alteração de informações pessoais",
      //   value: "ALTERACAO_INFO_PESSOAL",
      // },
      {
        displayName: "Férias",
        value: "FERIAS",
      },
      {
        displayName: "Adiantamento",
        value: "ADIANTAMENTO",
      },
      {
        displayName: "Falta",
        value: "FALTA",
      },
      {
        displayName: "Outros",
        value: "OUTRO",
      },
    ];

    const errorToast = (title, text) => {
      toast(
        {
          component: Toast,
          props: {
            title: title,
            text: text,
          },
        },
        { type: TYPE.ERROR, timeout: 4000 }
      );
    };
    const successToast = (title, msg) => {
      toast(
        {
          component: Toast,
          props: {
            title: title ? title : "Solicitação criada com sucesso!",
            text: msg
              ? msg
              : "O Solicitação foi enviada para o seu Gestor, acompanhe o status na listagem de solicitações.",
          },
        },
        { type: TYPE.SUCCESS }
      );
    };

    async function putToS3(fileObject, presignedUrl) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": fileObject.type,
        },
        body: fileObject,
      };
      const response = await fetch(presignedUrl, requestOptions);
      return await response;
    }
    const currentUrlUpload = ref(null);
    const isUploading = ref(false);

    /* const { mutate: mutateEditSolitacao } = useMutation(
      mutationEditSolicitacao,
      { context: { clientName: "endpoint1" } }
    ); */

    const uploadAnexo = async (idSolicitacao, file) => {
      isUploading.value = true;
      try {
        const resp = await apolloClient.query({
          query: getSolicitacaoAnexoUrl,
          variables: {
            solicitacaoAnexoUrlId: idSolicitacao,
            nomeArquivo: file.name,
            tipo: "UPLOAD", // UPLOAD | DOWNLOAD
          },
          context: { clientName: "endpoint1" },
          fetchPolicy: "network-only",
        });
        currentUrlUpload.value = resp.data.solicitacaoAnexoUrl;
        const urlApi = `${currentUrlUpload.value}`;
        await putToS3(file, urlApi);
        /* await mutateEditSolitacao({
          editSolicitacaoId: idSolicitacao,
          nomeArquivo: file.name,
        });
        */
      } catch {
        errorToast("Não foi possível enviar o(s) anexo(s).", errorMsg.value);
      } finally {
        isUploading.value = false;
      }
    };

    const { mutate: mutateCreateSolicitacao } = useMutation(
      mutationCreateSolicitacao,
      () => ({
        update: (_, resp) => {
          if (resp.data) {
            if (anexos.value.length) {
              anexos.value.forEach(async (anexo) => {
                await uploadAnexo(resp.data.createSolicitacao.id, anexo.file);
              });
            }
            // successToast(
            //   "Solicitação Criada com sucesso!",
            //   "A Solicitação foi enviada com sucesso para o(s) trabalhador(es)"
            // );
          }
        },
      })
    );
    const route = useRoute();

    onMounted(() => {
      if (route.query.solicitacao === "outros") {
        Object.assign(form, {
          tipo: "OUTRO",
        });
      }
    });

    return {
      form,
      saving,
      toast,
      mutateCreateSolicitacao,
      v$: useVuelidate(),
      successToast,
      setAnexos,
      errorToast,
      errorMsg,
      uploadAnexo,
      formFerias,
      formInfoPessoal,
      formAdiantamentos,
      formFalta,
      anexos,
      tiposSolicitacoes,
    };
  },
  validations() {
    return {
      form: {
        tipo: {
          required,
        },
        mensagem: {
          required,
        },
      },
      formFalta: {
        data: {
          required,
        },
      },
      formAdiantamentos: {
        valor: {
          required,
        },
      },
      formFerias: {
        dtSaida: {
          required,
        },
        quantidadeDias: {
          required,
        },
      },
      formInfoPessoal: {
        valor: {
          required,
        },
      },
    };
  },
  methods: {
    onSelectTipoSolicitacao() {
      if (this.form.tipo === "INFO_PESSOAL") {
        this.form.mensagem = "Solicitação de Informações pessoais";
      }
      if (this.form.tipo === "ALTERACAO_INFO_PESSOAL") {
        this.form.mensagem = "Solicitação de Alteração de informações pessoais";
      }
      if (this.form.tipo === "ADIANTAMENTO") {
        this.form.mensagem = "Solicitação de Adiantamento";
      }
      if (this.form.tipo === "FALTA") {
        this.form.mensagem = "Solicitação de Faltas";
      }
      if (this.form.tipo === "FERIAS") {
        this.form.mensagem = "Solicitação de Férias";
      }
      if (this.form.tipo === "OUTRO") {
        this.form.mensagem = "";
      }
    },

    async saveSolicitacao() {
      try {
        let validateForm = await this.v$.form.$validate();
        if (validateForm) {
          this.saving = true;
          let anexos = [];
          if (this.anexos.length) {
            anexos = this.anexos.map((anexo) => anexo.name);
          }
          let formSolicitacao = {
            mensagem: this.form.mensagem,
            anexos: anexos,
            origem: this.form.origem,
            tipo: this.form.tipo,
          };
          if (this.form.tipo === "FALTA") {
            let validateFalta = await this.v$.formFalta.$validate();
            if (validateFalta) {
              let solicitacaoFalta = {
                ...formSolicitacao,
                objeto: {
                  falta: {
                    data: formatDay(this.formFalta.data),
                  },
                },
              };
              await this.mutateCreateSolicitacao({
                newSolicitacao: solicitacaoFalta,
              });

              this.successToast();
              this.$router.push({ name: "solicitacoes" });
            }
          }
          if (this.form.tipo === "FERIAS") {
            let validateFerias = await this.v$.formFerias.$validate();
            if (validateFerias) {
              let solicitacaoFerias = {
                ...formSolicitacao,
                objeto: {
                  ferias: {
                    dtSaida: formatDay(this.formFerias.dtSaida),
                    quantidadeDias: Number(this.formFerias.quantidadeDias),
                    quantidadeDiasAbono: Number(
                      this.formFerias.quantidadeDiasAbono
                    ),
                  },
                },
              };
              await this.mutateCreateSolicitacao({
                newSolicitacao: solicitacaoFerias,
              });

              this.successToast();
              this.$router.push({ name: "solicitacoes" });
            }
          }
          if (this.form.tipo === "ADIANTAMENTO") {
            let validateFormAdiantamentos =
              await this.v$.formAdiantamentos.$validate();
            if (validateFormAdiantamentos) {
              let solicitacaoAdiantamentos = {
                ...formSolicitacao,
                objeto: {
                  adiantamento: {
                    valor: useGetMoney(this.formAdiantamentos.valor, true),
                  },
                },
              };
              await this.mutateCreateSolicitacao({
                newSolicitacao: solicitacaoAdiantamentos,
              });
              this.successToast();
              this.$router.push({ name: "solicitacoes" });
            }
          }
          if (
            this.form.tipo === "ALTERACAO_INFO_PESSOAL" ||
            this.form.tipo === "INFO_PESSOAL"
          ) {
            let validateInfoPessoal = await this.v$.formInfoPessoal.$validate();
            if (validateInfoPessoal) {
              let solicitacaoInfoPessoal = {
                ...formSolicitacao,
                objeto: {
                  infoPessoal: {
                    ...this.formInfoPessoal,
                    paisNascimento: this.formInfoPessoal.paisNascimento.value,
                    nacionalidade: this.formInfoPessoal.nacionalidade.value,
                    endPais: this.formInfoPessoal.endPais.value,
                    indImigrante:
                      this.formInfoPessoal.formInfoPessoal.indImigrante,
                    cnhUf: this.formInfoPessoal.formInfoPessoal.cnh
                      ? this.formInfoPessoal.formInfoPessoal.cnhUf
                      : null,
                    ctpsUf: this.formInfoPessoal.formInfoPessoal.cnh
                      ? this.formInfoPessoal.formInfoPessoal.ctpsUf
                      : null,
                    cnhVencimento: this.formInfoPessoal.formInfoPessoal.cnh
                      ? this.formInfoPessoal.formatDay(
                          this.formInfoPessoal.formInfoPessoal.cnhVencimento
                        )
                      : null,
                    // dependentes: this.formInfoPessoal.dependentes,
                  },
                },
              };
              await this.mutateCreateSolicitacao({
                newSolicitacao: solicitacaoInfoPessoal,
              });

              this.successToast();
              this.$router.push({ name: "solicitacoes" });
            }
          }
          if (this.form.tipo === "OUTRO") {
            let solicitacao = {
              ...formSolicitacao,
              objeto: null,
            };
            await this.mutateCreateSolicitacao({
              newSolicitacao: solicitacao,
            });
            this.successToast();
            this.$router.push({ name: "solicitacoes" });
          }
        }
      } catch (error) {
        this.errorToast(
          "Não foi possível salvar essa solicitação.",
          this.errorMsg
            ? this.errorMsg
            : "Ocorreu algo inesperado, tente novamente mais tarde!"
        );
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="postcss"></style>
