// import { reactive, computed } from "vue";

export const objectFaltas = {
  falta: {
    data: null,
  },
};
export const objectAdiantamento = {
  adiantamento: {
    valor: null,
  },
};
export const objectFerias = {
  ferias: {
    dtSaida: null,
    quantidadeDias: null,
    quantidadeDiasAbono: null,
  },
};
export const objectInfoPessoal = {
  infoPessoal: {
    nomeSocial: null,
    sexo: null,
    raca: "NAO_INFORMADO",
    indImigrante: "NAO_IMIGRANTE",
    codImigrante: null,
    estadoCivil: "SOLTEIRO",
    grauInstrucao: "MEDIO_COMPLETO",
    cnh: null,
    cnhCategoria: null,
    cnhVencimento: null,
    cnhUf: null,
    endUf: null,
    endCep: null,
    endLogradouro: null,
    endNumero: null,
    endComplemento: null,
    endBairro: null,
    endMunicipio: null,
    endMunicipioNome: null,
    defFisica: false,
    defVisual: false,
    defMental: false,
    defAuditiva: false,
    defIntelectual: false,
    defReabilitado: false,
    defCota: false,
    nomeMae: "",
    nis: null,
    numeroReservista: null,
    tituloEleitor: null,
    ctpsNumero: null,
    ctpsSerie: null,
    ctpsUf: null,
    dependentes: [],
  },
};

export const solicitacaoData = {
  anexos: [],
  dtCriacao: null,
  dtResposta: null,
  dtVisualizacao: null,
  id: null,
  idTrabalhador: null,
  idUsuarioCorrespondente: null,
  mensagens: [],
  objeto: {
    adiantamento: null,
    falta: null,
    ferias: null,
    infoPessoal: null,
  },
  status: null,
  tipo: null,
  origem: null,
};

// export const solicitacaoObject = reactive({
//   ...objectFaltas,
//   ...objectAdiantamento,
//   ...objectFerias,
//   ...objectInfoPessoal,
// });

// export const getSolicitacaoObject = computed(() => solicitacaoObject);

// export const resteStateSolicitacoesObject = () => {
//   Object.assign(solicitacaoObject, {
//     ...objectFaltas,
//     ...objectAdiantamento,
//     ...objectFerias,
//     ...objectInfoPessoal,
//   });
// };
