import { ref, computed } from "vue";
import { getCookie, saveCookie, removeCookie } from "@/utils/cookies";
import { apolloClient } from "@/state/client.js";
import { gql } from "@apollo/client/core";

const currentTrabalhador = ref(
  getCookie("trabalhador") ? JSON.parse(getCookie("trabalhador")) : null
);
const currentInfoStatus = ref("NAO_ENVIADO");
const validatingTrabalhador = ref(false);

export const getters = {
  currentTrabalhador: computed(() => currentTrabalhador.value),
  validatingTrabalhador: computed(() => validatingTrabalhador.value),
  currentInfoStatus: computed(() => currentInfoStatus.value),
};

// export function setCurrentTrabalhador (newCurrentTrabalhador) {
//   state.currentTrabalhador = newCurrentTrabalhador
//   saveCookie('trabalhador', JSON.stringify(newCurrentTrabalhador))
// }

export const mutations = {
  setCurrentTrabalhador: (newCurrentTrabalhador) => {
    saveCookie("trabalhador", JSON.stringify(newCurrentTrabalhador));
    currentTrabalhador.value = newCurrentTrabalhador;
    // Object.assign(state.currentTrabalhador)
  },
  setCurrentStatus: (newStatusInfo) => {
    currentInfoStatus.value = newStatusInfo;
  },
  removeCurrentTrabalhador: () => {
    currentTrabalhador.value = null;
    removeCookie("trabalhador");
    removeCookie("authToken");
  },
  removeValidatingTrabalhador: () => {
    validatingTrabalhador.value = false;
  },
  addValidatingTrabalhador: () => {
    validatingTrabalhador.value = true;
  },
};

export const getCurrentTrabalhador = gql`
  query Trabalhador {
    trabalhador {
      id
      idEmpregador
      nome
      telefone
      urlFoto
      matricula
    }
  }
`;

export const fetchCurrentTrabalhador = async () => {
  try {
    const res = await apolloClient.query({
      query: getCurrentTrabalhador,
      fetchPolicy: "network-only",
    });
    return Promise.resolve(res.data.trabalhador);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getters,
  mutations,
};
