<template>
  <div>
    <div class="mb-6 relative col-lg-4" v-if="formData">
      <label for="example-text-input" class="form-label falta-dt"
        >Data da Falta</label
      >
      <Datepicker
        v-if="falta"
        v-model="falta.data"
        menuClassName="mt-8"
        :autoApply="true"
        :auto-position="false"
        position="left"
        :textInput="true"
        :alt-position="() => ({ top: 0, left: 0 })"
        :teleport="false"
        :enableTimePicker="false"
        format="dd/MM/yyyy"
        :disabled="false"
        locale="pt-BR"
        placeholder="Data da Falta"
        :format-locale="ptBR"
        @closed="v$.falta.data.$touch"
      />
      <div v-show="v$.falta.data.$error" class="error-text">
        O Campo <b>Data</b> é de preenchimento obrigatório.
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { ptBR } from "date-fns/locale";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { toRefs } from "vue";
import { objectFaltas } from "@/state/modules/solicitacao.store";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    Datepicker,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({ ...objectFaltas }),
    },
  },

  setup(props) {
    const { formData: falta } = toRefs(props);

    return {
      ptBR,
      falta,
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      falta: {
        data: {
          required,
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "/src/assets/scss/custom/plugins/datepicker/main.scss";
</style>
