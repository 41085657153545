import { ApolloClient } from "@apollo/client/core";
import { InMemoryCache } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import {
  setDeveloperMessage,
  setError,
  // useRouterCustom,
} from "@/state/modules/error.store";
import { onError } from "@apollo/client/link/error";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { getCookie } from "../utils/cookies";
// import { useAuth0 } from "@auth0/auth0-vue";
// import { useRouter } from "vue-router";

// let router = useRouter();

const authMiddleware = setContext(async (request, { headers }) => {
  let token = getCookie("authToken");
  // const { getAccessTokenSilently } = useAuth0();

  // let token2 = await getAccessTokenSilently();
  // let token =
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2NhbGN1bGF0by5jb20uYnIvaWRjIjoiOTZjMTAyY2YtY2NkZC00NzdjLWFkNDMtMjhiYmU2MmUxOTdlIiwiaHR0cHM6Ly9jYWxjdWxhdG8uY29tLmJyL2lkdSI6ImVkZjJhN2RkLWIwOTEtNDZiOC05MGY0LWIzOThmMjk3YjBmOCIsImh0dHBzOi8vdHJhYmFsaGFkb3IuYXBwL2lkdCI6IjQwMDZiMDBjLWM2Y2YtNDFhNy04NTcwLTc2NTg0YWVhODc4YiIsImh0dHBzOi8vY2FsY3VsYXRvLmNvbS5ici9wZXJtaXNzaW9ucyI6WyJsZXI6Y2FkYXN0cm8iLCJlZGl0YXI6Y2FkYXN0cm8iLCJkZWxldGFyOmNhZGFzdHJvIiwibGVyOmxhbmNhbWVudG8iLCJlZGl0YXI6bGFuY2FtZW50byIsImRlbGV0YXI6bGFuY2FtZW50byIsImxlcjpwcm9jZXNzYW1lbnRvIiwiZWRpdGFyOnByb2Nlc3NhbWVudG8iLCJkZWxldGFyOnByb2Nlc3NhbWVudG8iLCJlZGl0YXI6dXN1YXJpbyIsImRlbGV0YXI6dXN1YXJpbyIsImxlcjplc29jaWFsIiwiZWRpdGFyOmVzb2NpYWwiLCJkZWxldGFyOmVzb2NpYWwiLCJhZG1pbmlzdHJhZG9yIl0sImlzcyI6Imh0dHBzOi8vY2FsY3VsYXRvLXFhLnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJnb29nbGUtb2F1dGgyfDExMTExNTMyMTQ0ODQ5NzQ5Nzc2NyIsImF1ZCI6WyJodHRwczovL2NhbGN1bGF0by5jb20uYnIvcWEtYXBpIl0sImlhdCI6MTY1ODQyODIwOSwiZXhwIjoyNjU4NTE0NjA5LCJhenAiOiJZR3hnQXdWY09wRTE0RTBZUnVBVjVNTFZwS0FYa1lZcyIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.ttc2AXGuucfFdpkifC6VnmYtokKBcABD1V0s1V2PlMc";

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink1 = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
});

const httpLink2 = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP2,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (process.env.NODE_ENV !== "production") {
      console.info(
        "%c" + graphQLErrors[0]?.developerMessage,
        "color:#B72143; font-weight:500; font-size: 1.2rem"
      );
    }
    setDeveloperMessage(graphQLErrors[0]?.developerMessage);
    if (graphQLErrors[0].developerMessage === "invalid signature") {
      // window.location.href = `${process.env.VUE_APP_AUTH0_REDIRECT_URI}`;
      window.location.reload();
      // const { getAccessTokenSilently } = useAuth0();
      // saveCookie("authToken", getAccessTokenSilently());
    }
    if (
      graphQLErrors[0].developerMessage ===
      "Cannot return null for non-nullable field Query.trabalhador."
    ) {
      window.location.href = `${process.env.VUE_APP_AUTH0_REDIRECT_URI}/trabalhador-nao-registrado`;
    }
    if (graphQLErrors[0].userMessage) {
      let error = graphQLErrors[0]?.userMessage;
      setError(error);
    }
  }
  if (networkError) {
    console.info(`[Network error]: ${networkError}`);
  }
});

const endpoint1 = ApolloLink.from([
  errorLink,
  authMiddleware.concat(httpLink1),
]);

const endpoint2 = ApolloLink.from([errorLink, httpLink2]);

const cache = new InMemoryCache({
  addTypename: false,
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "endpoint2",
    endpoint2,
    endpoint1
  ),
  cache,
  connectToDevTools:
    process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "qa",
});
