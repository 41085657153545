<template>
  <div
    class="d-flex justify-center align-items-center"
    @click.stop="onDownload"
  >
    <div class="border border-primary px-1 py-1 rounded">
      <i
        v-if="!isWorking"
        class="mdi mdi-arrow-collapse-down font-size-18 text-primary"
      ></i>

      <div
        v-else
        class="d-flex flex-column w-100 align-items-center justify-center"
      >
        <div
          class="spinner-border spinner-border-sm text-primary m-1"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useLazyQuery, useMutation } from "@vue/apollo-composable";
import {
  getStatusProcessamento,
  mutationCreateReciboAdiantamento,
  mutationCreateReciboDecimoTerceiro,
  mutationCreateReciboFerias,
  mutationCreateReciboFolha,
  mutationCreateReciboRescisao,
} from "@/state/graphql/demonstrativos";
import { computed, nextTick, ref, watch } from "vue";
import {
  getters as processamentoDemonstrativoGetters,
  mutations as processamentoDemonstrativoMutations,
} from "@/state/modules/processamentoDemonstrativos";
import { getters } from "@/state/modules/trabalhador.store";
import Toast from "@/components/Toast/Toast.vue";
import { TYPE, useToast } from "vue-toastification";
import { getCookie } from "@/utils/cookies";
import { errorMsg } from "@/state/modules/error.store";

export default {
  props: {
    periodo: {
      type: [Number, String, Object],
      required: true,
    },
    tipoRecibo: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const trabalhador = computed(() => getters.currentTrabalhador.value);
    const loadingDownload = ref(false);
    const isWorking = ref(false);
    const processamentoDemonstrativoId = computed(() => {
      const currentIndex =
        processamentoDemonstrativoGetters.currentProcessamentos.value.findIndex(
          (item) => item.id === props.id
        );
      if (currentIndex !== -1) {
        return processamentoDemonstrativoGetters.currentProcessamentos.value[
          currentIndex
        ].statusId;
      } else {
        return null;
      }
    });

    const toast = useToast();

    const mutationCreateDemonstrativo = () => {
      if (props.tipoRecibo === "FOLHA") {
        return mutationCreateReciboFolha;
      } else if (props.tipoRecibo === "ADIANTAMENTO") {
        return mutationCreateReciboAdiantamento;
      } else if (props.tipoRecibo === "FERIAS") {
        return mutationCreateReciboFerias;
      } else if (props.tipoRecibo === "DECIMO_TERCEIRO") {
        return mutationCreateReciboDecimoTerceiro;
      } else if (props.tipoRecibo === "RESCISAO") {
        return mutationCreateReciboRescisao;
      }
    };

    const {
      mutate: mutateCreateDemonstrativo /*, onDone: onCreateDemonstrativo */,
    } = useMutation(mutationCreateDemonstrativo, () => ({
      update: (_, resp) => {
        if (resp.data) {
          if (props.tipoRecibo === "FOLHA") {
            processamentoDemonstrativoMutations.setCurrentProcessamento({
              id: props.id,
              statusId: resp.data.createReciboFolha,
            });
          } else if (props.tipoRecibo === "ADIANTAMENTO") {
            processamentoDemonstrativoMutations.setCurrentProcessamento({
              id: props.id,
              statusId: resp.data.createReciboAdiantamento,
            });
          } else if (props.tipoRecibo === "FERIAS") {
            processamentoDemonstrativoMutations.setCurrentProcessamento({
              id: props.id,
              statusId: resp.data.createReciboFerias,
            });
          } else if (props.tipoRecibo === "DECIMO_TERCEIRO") {
            processamentoDemonstrativoMutations.setCurrentProcessamento({
              id: props.id,
              statusId: resp.data.createReciboDecimoTerceiro,
            });
          } else if (props.tipoRecibo === "RESCISAO") {
            processamentoDemonstrativoMutations.setCurrentProcessamento({
              id: props.id,
              statusId: resp.data.createTermoRescisao,
            });
          }
          toast(
            {
              component: Toast,
              props: {
                title: "Solicitação recebida com sucesso!",
                text: "O demonstrativo está sendo processado. Você será notificado assim que estiver concluído, aguarde...",
              },
            },
            { type: TYPE.SUCCESS, timeout: 10000 }
          );
        }
      },
    }));

    const {
      result: resultStatusProcessamento,
      load: loadStatusProcessamento,
      start: startStatusProcessamento,
      stop: stopStatusProcessamento,
    } = useLazyQuery(
      getStatusProcessamento,
      () => ({
        id: processamentoDemonstrativoId.value,
      }),
      { pollInterval: 1500, fetchPolicy: "no-cache" }
    );

    async function onDoneRefresh() {
      await stopStatusProcessamento();
      processamentoDemonstrativoMutations.removeCurrentProcessamento(props.id);
    }

    async function downloadDemonstrativo(demoID) {
      loadingDownload.value = true;
      let token = getCookie("authToken");
      const urlApi = `${process.env.VUE_APP_S3_URL_DOWNLOAD_FILES}/processamentos/${trabalhador.value.idEmpregador}/relatorios/${demoID}`;
      const config = {
        method: "get",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      };
      let filename = null;
      fetch(urlApi, config)
        .then((response) => {
          filename = response.headers.get("content-disposition");
          filename = filename.match(/filename\s*=\s*"(.+)"/i)[1];
          return response.blob();
        })
        .then((data) => {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none;";
          let url = window.URL.createObjectURL(data);
          a.download = filename;
          a.href = url;
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          toast(
            {
              component: Toast,
              props: {
                title: "Não foi possível baixar",
                text: "Desculpe, houve um erro na requisição, por favor tente novamente.",
              },
            },
            { type: TYPE.ERROR }
          );
        })
        .finally(() => {
          loadingDownload.value = false;
        });
    }

    watch(resultStatusProcessamento, () => {
      if (resultStatusProcessamento.value.statusProcessamento) {
        if (
          resultStatusProcessamento.value.statusProcessamento.status ===
          "SUCESSO"
        ) {
          onDoneRefresh();
          toast(
            {
              component: Toast,
              props: {
                title: "Solicitação Concluída!",
                text: "O demonstrativo foi gerado. Você já pode baixá-lo em pdf.",
                textButton: "Clique Aqui",
                showButton: true,
                functionButton: () => {
                  downloadDemonstrativo(
                    resultStatusProcessamento.value.statusProcessamento
                      .referencia
                  );
                },
              },
            },
            { type: TYPE.SUCCESS, timeout: 10000 }
          );
        } else if (
          resultStatusProcessamento.value.statusProcessamento.status === "ERRO"
        ) {
          onDoneRefresh();
          toast(
            {
              component: Toast,
              props: {
                title: "Falha na Solicitação",
                text: errorMsg.value,
              },
            },
            { type: TYPE.ERROR }
          );
        }
      } else {
        onDoneRefresh();
      }
    });

    watch(
      processamentoDemonstrativoId,
      () => {
        if (processamentoDemonstrativoId.value) {
          nextTick(() => {
            startStatusProcessamento();
            loadStatusProcessamento();
          });
        } else {
          stopStatusProcessamento();
        }
      },
      { immediate: true }
    );

    return {
      mutateCreateDemonstrativo,
      trabalhador,
      toast,
      isWorking,
    };
  },
  methods: {
    async onDownload() {
      try {
        this.isWorking = true;
        let valuesMutation = {};
        if (this.tipoRecibo === "DECIMO_TERCEIRO") {
          valuesMutation = {
            idEmpregador: this.trabalhador.idEmpregador,
            ano: this.periodo.ano,
            parcela: this.periodo.parcela,
          };
        } else {
          valuesMutation = {
            idEmpregador: this.trabalhador.idEmpregador,
            periodo: this.periodo,
          };
        }
        await this.mutateCreateDemonstrativo({
          ...valuesMutation,
        });
      } catch {
        this.toast(
          {
            component: Toast,
            props: {
              title: "Não foi possível salvar!",
              text: this.errorMsg,
            },
          },
          { type: TYPE.ERROR }
        );
      } finally {
        this.isWorking = false;
      }
    },
  },
};
</script>
