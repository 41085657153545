import { gql } from "@apollo/client/core";

export const getSolicitacoesDisplay = gql`
  query solicitacoesDisplay($cursor: ID) {
    solicitacoesDisplay(cursor: $cursor) {
      dtCriacao
      dtVisualizacao
      origem {
        value
        displayName
      }
      id
      status {
        value
        displayName
      }
      tipo {
        value
        displayName
      }
    }
  }
`;

export const getSolicitacao = gql`
  query solicitacao($solicitacaoId: ID!) {
    solicitacao(id: $solicitacaoId) {
      anexos
      dtCriacao
      dtResposta
      dtVisualizacao
      id
      idTrabalhador
      idUsuarioCorrespondente
      mensagens {
        origem {
          value
          displayName
        }
        data
        mensagem
      }
      objeto {
        adiantamento {
          valor
        }
        falta {
          data
        }
        ferias {
          dtSaida
          quantidadeDias
          quantidadeDiasAbono
        }
        infoPessoal {
          cnh
          cnhCategoria
          cnhUf {
            value
            displayName
          }
          cnhVencimento
          codImigrante {
            value
            displayName
          }
          ctpsNumero
          ctpsSerie
          ctpsUf {
            value
            displayName
          }
          defAuditiva
          defCota
          defFisica
          defIntelectual
          defMental
          defReabilitado
          defVisual
          dependentes {
            tipoDependente {
              value
              displayName
            }
            sexo {
              displayName
              value
            }
            nome
            dtNascimento
            cpf
          }
          endBairro
          endCep
          endComplemento
          endLogradouro
          endMunicipio
          endMunicipioNome
          endNumero
          tituloEleitor
          sexo {
            value
            displayName
          }
          raca {
            value
            displayName
          }
          paisNascimento {
            nome
            codigo
          }
          numeroReservista
          nomeSocial
          nomeMae
          nis
          nacionalidade {
            nome
            codigo
          }
          indImigrante {
            value
            displayName
          }
          grauInstrucao {
            value
            displayName
          }
          estadoCivil {
            value
            displayName
          }
          endUf {
            value
            displayName
          }
          endPais {
            nome
            codigo
          }
        }
      }
      status {
        value
        displayName
      }
      tipo {
        value
        displayName
      }
      origem {
        displayName
        value
      }
    }
  }
`;

export const mutationCreateSolicitacao = gql`
  mutation createSolicitacao($newSolicitacao: NewSolicitacao!) {
    createSolicitacao(newSolicitacao: $newSolicitacao) {
      id
      idTrabalhador
      idUsuarioCorrespondente
    }
  }
`;

export const mutationRespondeSolicitacao = gql`
  mutation respondeSolicitacao(
    $newRespostaSolicitacao: NewRespostaSolicitacao!
    $respondeSolicitacaoId: ID!
  ) {
    respondeSolicitacao(
      newRespostaSolicitacao: $newRespostaSolicitacao
      id: $respondeSolicitacaoId
    ) {
      id
    }
  }
`;

export const getSolicitacaoAnexoUrl = gql`
  query getSolicitacaoAnexoUrl(
    $solicitacaoAnexoUrlId: ID!
    $nomeArquivo: String!
    $tipo: TipoAnexoUrl!
  ) {
    solicitacaoAnexoUrl(
      id: $solicitacaoAnexoUrlId
      nomeArquivo: $nomeArquivo
      tipo: $tipo
    )
  }
`;

export const mutationEditSolicitacao = gql`
  mutation EditSolicitacao($editSolicitacaoId: ID!, $nomeArquivo: String!) {
    editSolicitacao(id: $editSolicitacaoId, nomeArquivo: $nomeArquivo)
  }
`;

export const getExisteSolicitacaoPendente = gql`
  query existeSolicitacaoPendente {
    existeSolicitacaoPendente
  }
`;

export const mutationRespostaSolicitacao = gql`
  mutation RespondeSolicitacao(
    $respondeSolicitacaoId: ID!
    $newRespostaSolicitacao: NewRespostaSolicitacao!
  ) {
    respondeSolicitacao(
      id: $respondeSolicitacaoId
      newRespostaSolicitacao: $newRespostaSolicitacao
    ) {
      id
      idTrabalhador
      origem {
        displayName
        value
      }
      tipo {
        displayName
        value
      }
      status {
        displayName
        value
      }
      mensagens {
        mensagem
        data
      }
      objeto {
        infoPessoal {
          sexo {
            displayName
            value
          }
          raca {
            displayName
            value
          }
          estadoCivil {
            displayName
            value
          }
          grauInstrucao {
            displayName
            value
          }
          nomeSocial
          nomeMae
          cnhUf {
            displayName
            value
          }
          nis
          numeroReservista
          tituloEleitor
          ctpsNumero
          ctpsSerie
          ctpsUf {
            displayName
            value
          }
          cnh
          cnhVencimento
          cnhCategoria
          paisNascimento {
            codigo
            nome
          }
          nacionalidade {
            codigo
            nome
          }
          indImigrante {
            displayName
            value
          }
          codImigrante {
            displayName
            value
          }
          defFisica
          defVisual
          defAuditiva
          defMental
          defIntelectual
          defReabilitado
          defCota
          endPais {
            codigo
            nome
          }
          endCep
          endLogradouro
          endNumero
          endComplemento
          endBairro
          endMunicipio
          endMunicipioNome
          endUf {
            displayName
            value
          }
          dependentes {
            tipoDependente {
              displayName
              value
            }
            nome
            dtNascimento
            cpf
          }
        }
        ferias {
          dtSaida
          quantidadeDias
          quantidadeDiasAbono
        }
        adiantamento {
          valor
        }
        falta {
          data
        }
      }
      anexos
      dtCriacao
      dtVisualizacao
      dtResposta
      idUsuarioCorrespondente
    }
  }
`;
