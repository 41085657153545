<template>
  <div class="rescisao-content" v-if="demonstrativosDisplay">
    <div>
      <p class="fs-5 text-dark fw-bold d-md-none">Rescisão</p>
      <div class="overflow-auto ct-scrollbar-thin-horizontal">
        <div class="accordion-min-width w-100">
          <CtAccordion
            v-if="demonstrativosDisplay.infoRescisaoOriginal"
            v-model="demonstrativosDisplay.visibleOriginal"
          >
            <template #trigger>
              <div
                v-if="!demonstrativosDisplay.visibleOriginal"
                class="flex flex-column w-100"
              >
                <div class="d-flex flex-row w-100 mb-3">
                  <div class="d-flex flex-column col-3 me-8">
                    <span href="#" class="fs-6 text-secondary"> Data </span>
                    <span href="#" class="fs-5 fw-normal text-dark">
                      {{
                        formatDayPtBr(
                          demonstrativosDisplay.infoRescisaoOriginal.dtPagamento
                        )
                      }}
                    </span>
                  </div>
                  <div class="d-flex flex-column col-3 me-8">
                    <span href="#" class="fs-6 text-secondary">
                      Líquido a Receber
                    </span>
                    <span href="#" class="fs-5 fw-normal text-dark">
                      {{
                        formatMoney(
                          demonstrativosDisplay.infoRescisaoOriginal.liquido
                        )
                      }}
                    </span>
                  </div>
                  <div class="d-flex flex-row w-100 justify-content-end pe-5">
                    <DownloadDemonstrativo
                      :id="demonstrativosDisplay.infoRescisaoOriginal.id"
                      :periodo="
                        demonstrativosDisplay.infoRescisaoOriginal.dtRescisao
                      "
                      tipo-recibo="RESCISAO"
                    />
                  </div>
                </div>
                <div class="border-top border-2 pt-4">
                  <p class="fs-5 text-dark pb-1">Resumo da Rescisão</p>
                  <div class="d-flex flex-row w-100 mb-3 flex-wrap">
                    <div class="d-flex flex-column col-5 me-4 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1"> Causa </span>
                      <span href="#" class="fw-normal">
                        {{
                          demonstrativosDisplay.infoRescisaoOriginal.motivo
                            .displayName
                        }}
                      </span>
                    </div>
                    <div class="d-flex flex-column col-2 me-3 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1">
                        Num.Processo
                      </span>
                      <span href="#" class="fw-normal">
                        {{
                          demonstrativosDisplay.infoRescisaoOriginal
                            .nrProcessoTrabalhista !== null
                            ? demonstrativosDisplay.infoRescisaoOriginal
                                .nrProcessoTrabalhista
                            : "Sem Informação"
                        }}
                      </span>
                    </div>
                    <div class="d-flex flex-column col-2 me-3 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1">
                        Qnt. Dias Aviso Idenizado
                      </span>
                      <span href="#" class="fw-normal">
                        {{
                          demonstrativosDisplay.infoRescisaoOriginal
                            .qntDiasAvisoIndenizado
                        }}
                      </span>
                    </div>
                    <div class="d-flex flex-column col-2 me-5 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1">
                        Data da Rescisão
                      </span>
                      <span href="#" class="fw-normal">
                        {{
                          formatDayPtBr(
                            demonstrativosDisplay.infoRescisaoOriginal
                              .dtRescisao
                          )
                        }}
                      </span>
                    </div>
                    <div class="d-flex flex-column col-2 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1">
                        Data Aviso
                      </span>
                      <span href="#" class="fw-normal">
                        {{
                          formatDayPtBr(
                            demonstrativosDisplay.infoRescisaoOriginal.dtAviso
                          )
                        }}
                      </span>
                    </div>
                    <div class="d-flex flex-column col-3 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1">
                        Data de Pagamento
                      </span>
                      <span href="#" class="fw-normal">
                        {{
                          formatDayPtBr(
                            demonstrativosDisplay.infoRescisaoOriginal
                              .dtPagamento
                          )
                        }}
                      </span>
                    </div>
                    <div class="d-flex flex-column col-2 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1">
                        Tipo Aviso
                      </span>
                      <span href="#" class="fw-normal">
                        {{
                          demonstrativosDisplay.infoRescisaoOriginal.tipoAviso
                            .displayName
                        }}
                      </span>
                    </div>
                    <div class="d-flex flex-column col-2 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1">
                        Saldo FGTS
                      </span>
                      <span href="#" class="fw-normal">
                        {{
                          formatMoney(
                            demonstrativosDisplay.infoRescisaoOriginal.saldoFgts
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <!--
                <div
                  v-if="demonstrativosDisplay.resumoInfoRescisaoComplementar"
                >
                  <p class="fs-5 text-dark pb-1">Rescisão Complementar</p>
                  <div class="d-flex flex-column col-3 mb-5">
                    <span href="#" class="fs-6 text-dark mb-1">
                      Data de Pagamento
                    </span>
                    <span href="#" class="fw-normal">
                      {{
                        formatDayPtBr(
                          demonstrativosDisplay.resumoInfoRescisaoComplementar
                            .dtPagamento
                        )
                      }}
                    </span>
                  </div>
                </div>
                --></div>
              </div>
              <div v-else class="d-flex flex-row w-100">
                <div class="d-flex flex-column col-3 me-8">
                  <span href="#" class="fs-6 text-secondary"> Data </span>
                  <span href="#" class="fs-5 fw-normal text-dark">
                    {{
                      formatDayPtBr(
                        demonstrativosDisplay.infoRescisaoOriginal.dtPagamento
                      )
                    }}
                  </span>
                </div>
                <div class="d-flex flex-column col-3 me-8">
                  <span href="#" class="fs-6 text-secondary">
                    Líquido a Receber
                  </span>
                  <span href="#" class="fs-5 fw-normal text-dark">
                    {{
                      formatMoney(
                        demonstrativosDisplay.infoRescisaoOriginal.liquido
                      )
                    }}
                  </span>
                </div>
              </div>
            </template>
            <template #content>
              <ViewRescisaoCompleta
                v-model="demonstrativosDisplay.visibleOriginal"
                :id-rescisao="demonstrativosDisplay.infoRescisaoOriginal.id"
              />
            </template>
          </CtAccordion>
          <CtAccordion
            v-if="demonstrativosDisplay.resumoInfoRescisaoComplementar"
            v-model="demonstrativosDisplay.visibleComplementar"
          >
            <template #trigger>
              <div
                v-if="!demonstrativosDisplay.visibleComplementar"
                class="flex flex-column w-100"
              >
                <div class="d-flex flex-row w-100 mb-3">
                  <div class="d-flex flex-column col-3 me-8">
                    <span href="#" class="fs-6 text-secondary"> Data </span>
                    <span href="#" class="fs-5 fw-normal text-dark">
                      {{
                        formatDayPtBr(
                          demonstrativosDisplay.resumoInfoRescisaoComplementar
                            .dtPagamento
                        )
                      }}
                    </span>
                  </div>
                  <div class="d-flex flex-column col-3 me-8">
                    <span href="#" class="fs-6 text-secondary">
                      Líquido a Receber
                    </span>
                    <span href="#" class="fs-5 fw-normal text-dark">
                      Sem Campo<!--{{ formatMoney(demonstrativosDisplay.resumoInfoRescisaoComplementar.liquido) }} -->
                    </span>
                  </div>
                  <div class="d-flex flex-row w-100 justify-content-end pe-5">
                    <DownloadDemonstrativo
                      :id="
                        demonstrativosDisplay.resumoInfoRescisaoComplementar
                          .idInfoRescisao
                      "
                      :periodo="
                        demonstrativosDisplay.resumoInfoRescisaoComplementar
                          .dtPagamento
                      "
                      tipo-recibo="RESCISAO"
                    />
                  </div>
                </div>
                <div class="border-top border-2 pt-4">
                  <p class="fs-5 text-dark pb-1">Rescisão Complementar</p>
                  <div class="d-flex flex-row w-100 mb-3 flex-wrap">
                    <div class="d-flex flex-column col-5 me-4 mb-5">
                      <span href="#" class="fs-6 text-dark mb-1">
                        Data de Pagamento
                      </span>
                      <span href="#" class="fw-normal">
                        {{
                          formatDayPtBr(
                            demonstrativosDisplay.resumoInfoRescisaoComplementar
                              .dtPagamento
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="d-flex flex-row w-100">
                <div class="d-flex flex-column col-3 me-8">
                  <span href="#" class="fs-6 text-secondary"> Data </span>
                  <span href="#" class="fs-5 fw-normal text-dark">
                    {{
                      formatDayPtBr(
                        demonstrativosDisplay.resumoInfoRescisaoComplementar
                          .dtPagamento
                      )
                    }}
                  </span>
                </div>
                <div class="d-flex flex-column col-3 me-8">
                  <span href="#" class="fs-6 text-secondary">
                    Líquido a Receber
                  </span>
                  <span href="#" class="fs-5 fw-normal text-dark">
                    Sem Campo
                    <!-- {{
                      formatMoney(
                        demonstrativosDisplay.resumoInfoRescisaoComplementar.liquido
                      )
                    }} -->
                  </span>
                </div>
              </div>
            </template>
            <template #content>
              <ViewRescisaoCompleta
                v-model="demonstrativosDisplay.visibleOriginal"
                :id-rescisao="
                  demonstrativosDisplay.resumoInfoRescisaoComplementar
                    .idInfoRescisao
                "
              />
            </template>
          </CtAccordion>
        </div>
      </div>
    </div>
  </div>

  <NoDataList
    v-if="!demonstrativosDisplay && !loadingDemonstrativos"
    text="Não possui demonstrativo de rescisão."
  />

  <div v-if="!demonstrativosDisplay && loadingDemonstrativos">
    <div class="d-flex flex-column w-100 align-items-center justify-center">
      <div class="spinner-border text-primary font-size-10 m-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import CtAccordion from "@/components/CtAccordion/CtAccordion.vue";
import { formatDayPtBr } from "@/composables/useFormatDate";
import { formatMoney } from "@/composables/useFormatMoney";
import { getDemosRescisaoDisplay } from "@/state/graphql/demonstrativos";
import { useLazyQuery } from "@vue/apollo-composable";
import ViewRescisaoCompleta from "./components/ViewRescisaoCompleta";
import DownloadDemonstrativo from "./components/DownloadDemonstrativo";
import NoDataList from "./components/NoDataList.vue";

export default {
  name: "RescisaoDisplayView",
  components: {
    CtAccordion,
    ViewRescisaoCompleta,
    DownloadDemonstrativo,
    NoDataList,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    /*
    const demonstrativosDisplay = ref({
      infoRescisaoOriginal: {
        dtRescisao: "2022-11-28",
        dtAviso: "2022-11-28",
        dtPagamento: "2022-11-28",
        tipoAviso: {
          displayName: "Indenizado",
          Value: "Teste",
        },
        saldoFgts: 888,
        liquido: 999,
        motivo: {
          displayName: "Rescisão sem justa causa, por iniciativa do empregador",
          Value: "Teste",
        },
        nrProcessoTrabalhista: null,
        qntDiasAvisoIndenizado: 25,
      },
      resumoInfoRescisaoComplementar: {
        dtPagamento: "2022-11-28",
        idInfoRescisao: "abc-123",
      },
      visibleOriginal: false,
      visibleComplementar: false,
    });
    */
    const demonstrativosDisplay = ref(null);

    /* Queries */
    const {
      onResult: onResultDemonstrativos,
      loading: loadingDemonstrativos,
      load: loadDemonstrativos,
      // error: errorOnDemonstrativos,
      //refetch: refetchDemonstrativos,
    } = useLazyQuery(getDemosRescisaoDisplay, () => ({}), {
      fetchPolicy: "network-only",
    });

    onResultDemonstrativos((result) => {
      let apiDemonstrativos = result.data.resumoRescisaoDisplay;
      if (result.data.resumoRescisaoDisplay) {
        demonstrativosDisplay.value = {
          ...apiDemonstrativos,
          visibleOriginal: false,
          visibleComplementar: false,
        };
      }
    });

    return {
      demonstrativosDisplay,
      formatDayPtBr,
      formatMoney,
      loadingDemonstrativos,
      loadDemonstrativos,
    };
  },
  watch: {
    modelValue(nextValue) {
      this.$emit("update:modelValue", nextValue);
      if (nextValue && !this.demonstrativosDisplay) this.loadDemonstrativos();
    },
  },
};
</script>
<style lang="postcss">
.rescisao-content .accordion-button {
  align-items: start;
}

.rescisao-content .accordion-button::after {
  margin-top: 10px;
}
</style>
