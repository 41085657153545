import { createRouter as createVueRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/Dashboard/DashboardView.vue";
import PerfilTrabalhador from "../views/Perfil/PerfilTrabalhador.vue";
import DemonstrativosView from "../views/Demonstrativos/DemonstrativosView.vue";
import Solicitacoes from "../views/Solicitacoes/Solicitacoes.vue";
import NovaSolicitacao from "../views/Solicitacoes/NovaSolicitacao.vue";
import LayoutHorizontal from "../layouts/LayoutHorizontal.vue";
import CallBack from "../views/CallBack/CallBack.vue";
import NotFoundTrabalhador from "../views/NotFoundTrabalhador/NotFoundTrabalhador.vue";
import { createAuthGuard } from "@auth0/auth0-vue";

const Documentos = () => import("../views/Documentos/Documentos.vue");

export function createRouter(app) {
  return createVueRouter({
    routes: [
      {
        path: "/callback",
        name: "callback",
        component: CallBack,
      },
      {
        path: "/trabalhador-nao-registrado",
        name: "trabalhador-not-found",
        component: NotFoundTrabalhador,
      },
      {
        path: "/:catchAll(.*)",
        name: "Not Found",
        component: require("../views/404/404.vue").default,
        props: true,
      },
      {
        beforeEnter: createAuthGuard(app),
        path: "",
        component: LayoutHorizontal,
        children: [
          {
            path: "",
            name: "dashboard",
            component: DashboardView,
            redirect: "/demonstrativos",
          },
          {
            path: "/trabalhador",
            name: "trabalhador",
            component: PerfilTrabalhador,
          },
          {
            path: "/demonstrativos",
            name: "demonstrativos",
            component: DemonstrativosView,
          },
          {
            path: "/documentos",
            name: "documentos",
            component: Documentos,
          },
          {
            path: "/solicitacoes",
            name: "solicitacoes",
            component: Solicitacoes,
          },
          {
            path: "/nova-solicitacao",
            name: "nova-solicitacao",
            component: NovaSolicitacao,
          },
        ],
      },
    ],
    history: createWebHistory(),
  });
}
