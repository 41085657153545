<template>
  <!-- pace-inactive -->
  <!-- <div class="pace" v-if="true">
    {{ $auth0.isLoading }}
    <div
      class="pace-progress"
      data-progress-text="100%"
      data-progress="99"
      style="transform: translate3d(100%, 0px, 0px)"
    >
      <div class="pace-progress-inner"></div>
    </div>
    <div class="pace-activity"></div>
  </div> -->
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/app.scss";
@import "@/assets/scss/bootstrap.scss";
@import "@/assets/scss/icons.scss";
@import "@/assets/scss/preloader.scss";
</style>
