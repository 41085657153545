<template>
  <div>
    <h1 class="mb-2 font-size-18">
      {{ title }}
    </h1>
    <span class="font-size-13">{{ text }}</span>
    <div v-if="showButton" class="d-flex">
      <button
        type="submit"
        class="w-100 mt-2 btn btn-primary waves-effect waves-light"
        @click.once="functionButton"
      >
        {{ textButton }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Solicitação Concluída",
    },
    text: {
      type: String,
      default: "",
    },
    textButton: {
      type: String,
      default: null,
    },
    functionButton: {
      type: Function,
      default: () => {},
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style></style>
