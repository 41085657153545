<template>
  <div v-if="formData">
    <div class="mb-6 col-lg-4">
      <label class="form-label">Valor do Adiantamento*</label>
      <input
        :class="
          v$.adiantamento.valor.$error
            ? 'form-control is-invalid'
            : 'form-control'
        "
        type="text"
        placeholder="Informe  o Valor do Adiantamento"
        v-model="adiantamento.valor"
        @blur="onBlurValorAdiantamento"
      />
      <div v-show="v$.adiantamento.valor.$error" class="error-text">
        O Campo <b>Valor do Adiantamento</b> é de preenchimento obrigatório.
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { toRefs } from "vue";
import { objectAdiantamento } from "@/state/modules/solicitacao.store";
import { formatInputPrice } from "@/composables/useFormatMoney";

export default {
  props: {
    formData: {
      type: Object,
      default: () => ({ ...objectAdiantamento }),
    },
  },

  setup(props) {
    const { formData: adiantamento } = toRefs(props);

    return {
      adiantamento,
      v$: useVuelidate(),
    };
  },
  methods: {
    onBlurValorAdiantamento() {
      this.adiantamento.valor = formatInputPrice(this.adiantamento.valor);
      this.v$.adiantamento.valor.$touch();
    },
  },
  validations() {
    return {
      adiantamento: {
        valor: {
          required,
        },
      },
    };
  },
};
</script>
