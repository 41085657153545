<template>
  <h5
    class="font-size-14 text-black mb-4 uppercase border-bottom border-1 pb-1"
  >
    {{ title }}
  </h5>
</template>

<script>
import { getBGMenuColor } from "@/state/modules/themeColor.store";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      getBGMenuColor,
    };
  },
};
</script>
<style lang="scss">
h5.uppercase {
  text-transform: uppercase;
}
</style>
